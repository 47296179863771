import {serializr,
        objectAssign} from '@mecatran/serializr-extensions';
// import serializr from 'serializr';

// console.log(serializr)

var {   createModelSchema, 
        createSimpleSchema, 
        primitive, 
        reference, 
        date, raw, list, object, 
        identifier, map, mapAsArray , 
        custom, 
        getDefaultModelSchema, 
		setDefaultModelSchema,
        serialize, 
        deserialize } = serializr;
export {serializr};

import * as model from "../model/index.mjs";
export var urbiplanApiConfigModel = model;

function factory(clazz ){
    return function(){
        return new clazz();
    }
}


var schemaUrbiplanApiKeyStore = {
    factory : factory(model.UrbiplanApiKeyStore),
    props : {
        prod: primitive(),
        preprod: primitive(),
        dev: primitive()
    }
}

var schemaUrbiplanApiClientConfig = {
    factory : factory(model.UrbiplanApiClientConfig),
    props : {
        instance: primitive(),
        env: primitive(),
    }
}

/*
var t = serializr.deserialize(schemaUrbiplanApiKeyStore, { prod: 'paaarod', preprod: 'bb'})
console.log(t)
console.log('t.prod',t.prod);
console.log('t.preprod',t.preprod)
*/


var schemaUrbiplanApiAgencies = {
    factory: factory(model.UrbiplanApiAgencies),
    props:{
        includeRoutes : raw()
    }
}

var schemaUrbiplanApiRoutes = {
    factory : factory(model.UrbiplanApiRoutes),
    props : {
        includePatterns : raw(),
        routeLookAheadDays: raw(),
        codeQuery: raw(),
        nameQuery: raw(),
        latitude :raw(),
        longitude : raw(),
        radiusMeter : raw()     
    }
}
var schemaUrbiplanApiStops = {
    factory : factory(model.UrbiplanApiStops),
    props : {
        includeStops : raw(),
        includeStations: raw(),
        includeRoutes : raw(),
        routeLookAheadDays : raw(),
        codeQuery: raw(),
        nameQuery : raw(),		
        latitude : raw(),
        longitude : raw(),
        radiusMeter : raw()
    }
}
var schemaUrbiplanApiRealtimeStop = {
    factory : factory(model.UrbiplanApiRealtimeStop),
    props : {
        stopId : raw(),
        viaStopId : raw(),
        includeServedStops : raw(),
        date : date(),
        includeAlerts : raw(),
        lookAheadSec : raw(),
        preferredLang : raw()
    }
}
var schemaUrbiplanApiActiveAlerts = {
    factory : factory(model.UrbiplanApiActiveAlerts),
    props : {
        preferredLang : raw(),
        sortStops : raw()
    }
}
var schemaUrbiplanApiLayerKml = {
    factory : factory(model.UrbiplanApiLayerKml),
    props : {
        includeStops : raw(),
        includeRoute  : raw(),
        routeId  : raw(),
        aggregateByStation  : raw(),
        ignoreUndefinedGeometry  : raw(),
        pruneRedundant  : raw(),
        usagePruneThreshold  : raw(),
        from  : raw(),
        to  : raw(),
        includeUsage  : raw()        
    }
}
var schemaUrbiplanApiConfig = {
    factory : factory(model.UrbiplanApiConfig ),
    props : {
        /*
        instance : primitive(),
        env : primitive(),
        */
        keys : objectAssign(schemaUrbiplanApiKeyStore),
        client : objectAssign(schemaUrbiplanApiClientConfig),
        agencies : objectAssign(schemaUrbiplanApiAgencies),
        routes : objectAssign(schemaUrbiplanApiRoutes ),
        stops : objectAssign(schemaUrbiplanApiStops),
        realtimeStop : objectAssign(schemaUrbiplanApiRealtimeStop),
        activeAlerts : objectAssign(schemaUrbiplanApiActiveAlerts),
        layerKml : objectAssign(schemaUrbiplanApiLayerKml)
    }
}

setDefaultModelSchema(model.UrbiplanApiKeyStore, schemaUrbiplanApiKeyStore);
setDefaultModelSchema(model.UrbiplanApiClientConfig, schemaUrbiplanApiClientConfig);
setDefaultModelSchema(model.UrbiplanApiAgencies, schemaUrbiplanApiAgencies);
setDefaultModelSchema(model.UrbiplanApiRoutes, schemaUrbiplanApiRoutes);
setDefaultModelSchema(model.UrbiplanApiStops, schemaUrbiplanApiStops);
setDefaultModelSchema(model.UrbiplanApiRealtimeStop, schemaUrbiplanApiRealtimeStop);
setDefaultModelSchema(model.UrbiplanApiActiveAlerts, schemaUrbiplanApiActiveAlerts);
setDefaultModelSchema(model.UrbiplanApiLayerKml, schemaUrbiplanApiLayerKml);
setDefaultModelSchema(model.UrbiplanApiConfig, schemaUrbiplanApiConfig);


