

/**
requestmanager
DESC:
- request data from cache if available, otherwise request data from api 
CONFIG:
- cacheservices
NOTES:
- only one request manager for all endpoints or one per endpoint ?
	if one for all, then requestmanager has to manage several cacheservices 
	currently one request manager pe endpoint
TODO:
- rename routing into another name (logic?)
- place the initial cacheservices.request in the routing function (and call routing)
- replace "provider" by "path"
- use promises instead of callbacks
- tests: don't forget to provide a chacheservices 
- handle cacheservices = null ?
*/


import {loggerFactory} from "./loggerfactory.mjs";
var logger = loggerFactory.createLogger('REQM','info',true);

import apiServices from "./api.service.mjs"



export default (function(){
	var _cacheServices = null;
    
    /* internal */
    function JsonToObject(data){
        return JSON.parse(data);
    }
    
    /* api */ 
    
	var config = (function(){
		return { setCacheServices : setCacheServices };
		
        /* TODELETE 
		function cache(conf){
			/* 0. config cacheServices 
			if (conf.nocache || cache.size === 0 ) conf = {size : 0};
			_cacheServices.config.cache(conf);
		}
        */
        
        function setCacheServices(cacheServices){
            _cacheServices = cacheServices;
        }
	})();
	
    return { config : config, request : request }

	function request(url, response){
	   logger.debug('url:',url);
		function cacheResponse(data){
			routing(data, 'cache');
		}
		
		function apiResponse(error,data){
			routing(data,'api');
		}
		
		function routing(data, provider ){
			var error = null;
            /* init */
            if (!data && !provider) {
                if (_cacheServices) _cacheServices.api.request(url, cacheResponse );
                else  cacheResponse(null, 'cache');              
            }
			/* 2. if response is null request api  */
			if (provider === 'cache') {
				if (!data){
					logger.info(`${url} no data from cache, requesting api...`)
					apiServices.request(url, apiResponse );		
				} else {
					logger.info(`${url} data from cache, returning data`)
					response(error,data);
				}
			}
			if (provider === 'api'){
				if (data){
                    //data = JsonToObject(data);
					/*  update the cache and return the response*/
					var timestamp = Date.now();
                    logger.info(`data from API `,(!data) ? "null": data.substr(0,30)+'...');
					if (_cacheServices) {
                        logger.info('updating cache...');
                        _cacheServices.api.update(url,timestamp,data);	
                    }
				}
				response(error,data);
			}
		}
		
		/* with promises 
		_cacheServices.request(url).then(response).catch(f(reason){apiServices.request(url)})
		*/
        /* ---- */
        routing();
    
		
	}
})();

/*
function print(data){
	console.log('print',data);
}
var RequestManager = require('./requestManager'),
	requestManager = RequestManager();
requestManager.config.cache({size:1})
requestManager.request('http://app.mecatran.com/utw/ws/gtfs/stops/rochefort',print)
	
*/