
import LoggerFactory from "../common/loggerfactory.mjs"; 

// this is used when importing a .mjs (and not a .js) 
import * as ConsoleFormattedStream from "@browser-bunyan/console-formatted-stream/lib/index.m.js"; // this is an hack. Normally webpack should pick the "module" file alone 

import {default  as bunyan } from "browser-bunyan"
//import * as bunyan from "browser-bunyan";


/*
//  (this used when importing a js instead of a mjs)
import ConsoleFormattedStream from "@browser-bunyan/console-formatted-stream";
import bunyan from "browser-bunyan";
*/


export var loggerFactory = LoggerFactory(bunyan, ConsoleFormattedStream.ConsoleFormattedStream);
/*
import LoggerFactory from "../common/loggerfactory.mjs"; 
console.log(LoggerFactory);
import {ConsoleFormattedStream} from "@browser-bunyan/console-formatted-stream";
import * as bunyan from "browser-bunyan";
console.log('bunyan',bunyan)
export var loggerFactory = LoggerFactory(bunyan, ConsoleFormattedStream);
*/

/*
console.log('-----> browser'); // tmp 
var LoggerFactory = require('../common/loggerfactory'); 
var ConsoleFormattedStream  = require('@browser-bunyan/console-formatted-stream').ConsoleFormattedStream;	
var bunyan = require('browser-bunyan');
*/
/*
module.exports = LoggerFactory(bunyan,ConsoleFormattedStream);

import LoggerFactory from "../common/loggerfactory.mjs"; 
import {NodeFormattedStream} from "./nodeformattedstream";
import bunyan from "bunyan";

export var loggerFactory = LoggerFactory(bunyan, NodeFormattedStream);
*/