


export class UrbiplanApiActiveAlert{
	constructor(){
		var  _id, _level, _lastUpdated, _description, _title, _url, _activeFrom, _activeTo, _publishActiveRange, _resourceMimeType, _resourceUrl, _splitActiveRange, _formattedActiveRange, _agencies, _routes, _stops, _cause, _effect, _apiPublication;

		
		Object.defineProperty(this,'id',{
			set : function(v){
				_id = v;
			},
			get : function(){
				return _id;
			},
			enumerable : true
		});
		
		Object.defineProperty(this,'level',{
			set : function(v){
				_level = v;
			},
			get : function(){
				return _level;
			},
			enumerable : true
		});
		
		Object.defineProperty(this,'lastUpdated',{
			set : function(v){
				_lastUpdated = v;
			},
			get : function(){
				return _lastUpdated;
			},
			enumerable : true
		});
		
		Object.defineProperty(this,'description',{
			set : function(v){
				_description = v;
			},
			get : function(){
				return _description;
			},
			enumerable : true
		});
		
		Object.defineProperty(this,'title',{
			set : function(v){
				_title = v;
			},
			get : function(){
				return _title;
			},
			enumerable : true
		});
		
		Object.defineProperty(this,'url',{
			set : function(v){
				_url = v;
			},
			get : function(){
				return _url;
			},
			enumerable : true
		});
		
		Object.defineProperty(this,'activeFrom',{
			set : function(v){
				_activeFrom = v;
			},
			get : function(){
				return _activeFrom;
			},
			enumerable : true
		});
		
		Object.defineProperty(this,'activeTo',{
			set : function(v){
				_activeTo = v;
			},
			get : function(){
				return _activeTo;
			},
			enumerable : true
		});
		
		Object.defineProperty(this,'publishActiveRange',{
			set : function(v){
				_publishActiveRange = v;
			},
			get : function(){
				return _publishActiveRange;
			},
			enumerable : true
		});
		
		Object.defineProperty(this,'resourceMimeType',{
			set : function(v){
				_resourceMimeType = v;
			},
			get : function(){
				return _resourceMimeType;
			},
			enumerable : true
		});
		
		Object.defineProperty(this,'resourceUrl',{
			set : function(v){
				_resourceUrl = v;
			},
			get : function(){
				return _resourceUrl;
			},
			enumerable : true
		});
		
		Object.defineProperty(this,'splitActiveRange',{
			set : function(v){
				_splitActiveRange = v;
			},
			get : function(){
				return _splitActiveRange;
			},
			enumerable : true
		});
		
		Object.defineProperty(this,'formattedActiveRange',{
			set : function(v){
				_formattedActiveRange = v;
			},
			get : function(){
				return _formattedActiveRange;
			},
			enumerable : true
		});
	
		Object.defineProperty(this,'agencies',{
			set : function(a){
				_agencies = a;
			},
			get : function(){
				return _agencies;
			},
			enumerable : true
		});
	
		Object.defineProperty(this,'routes',{
			set : function(v){
				_routes = v;
			},
			get : function(){
				return _routes;
			},
			enumerable : true
		});
		
		Object.defineProperty(this,'stops',{
			set : function(v){
				_stops = v;
			},
			get : function(){
				return _stops;
			},
			enumerable : true
		});
		
		Object.defineProperty(this,'cause',{
			set : function(v){
				_cause = v;
			},
			get : function(){
				return _cause;
			},
			enumerable : true
		});
		
		Object.defineProperty(this,'effect',{
			set : function(v){
				_effect = v;
			},
			get : function(){
				return _effect;
			},
			enumerable : true
		});
		
		Object.defineProperty(this,'apiPublication',{
			set : function(v){
				_apiPublication = v;
			},
			get : function(){
				return _apiPublication;
			},
			enumerable : true
		});
		
	}
	
	getConstructor(){ return this.constructor }
	
	getClass(){ return UrbiplanApiActiveAlert }
	
	getId(){ return this.id }
	
	getLevel(){ return this.level }
	
	getDescription(){ return this.description }
	
	getRoutes(){ return this.routes } 
	
	getAgencies(){ return this.agencies }
	
	isGeneralAlert(){ 
		return (this.getAgencies().length);
	}
	
	getTitle(){ return this.title }
	
	getUrl(){ return this.url }
	
	getResourceMimeType(){ return this.resourceMimeType }
	
	getResourceUrl(){ return this.resourceUrl }
	
	
	getLastPublicationDate(){ return this.apiPublication.dateTime }
	
	getFormattedActiveRange(){ return this.formattedActiveRange }
	
	
	static filterByAgencyIds(alerts,agencyIds){
		var agencyIdsSet = new Set(agencyIds);
		function filter(alert){
			if (!agencyIdsSet.size) return true;
			var agencies = alert.getAgencies();
			var found = false;
			if (agencies.length){
				found = agencies.some((agency)=>{
					var agencyId = agency.getId();
					return agencyIdsSet.has(agencyId);
				});
				return found;
			}
			
			var routes = alert.getRoutes();
			if (routes.length){
				found = routes.some((route)=>{
					var agencyId = route.getAgency().getId();  
					return agencyIdsSet.has(agencyId);
				});
				return found; 
			}
			return false;
		}
		return alerts.filter(filter);
	}
	
	static filterByRouteIds(alerts,routeIds){
		var routeIdsSet = new Set(routeIds);
		function filter(alert){
			if (!routeIdsSet.size) return true;
			var routes = alert.getRoutes();
			var found = routes.some((route)=>{
				var routeId = route.getId();  
				return routeIdsSet.has(routeId);
			});
			return found; 
		}
		return alerts.filter(filter);
	}
	
	static sortByLastPublicationDate(alerts, ascending = true){
		var ascending = ascending; 
		function sorter(alert1,alert2){
			var date1 = alert1.getLastPublicationDate();
			var date2 = alert2.getLastPublicationDate();
			if (date1 === date2)  return 0;
			if (date1 < date2) return (ascending) ? -1 : 1;
			if (date1 > date2) return (ascending) ? 1 : -1;
		}
		
		return alerts.sort(sorter);
	}
	
	static sortByLevel(alerts, ascending  = true ){
		function sorter(alert1,alert2){
			var level1 = alert1.getLevel();
			var level2 = alert2.getLevel();
			if (level1 === level2) return 0;
			if (level1 < level2) return (ascending) ? -1 : 1;
			if (level1 > level2) return (ascending) ? 1 : -1;
		}
		
		return alerts.sort(sorter);
	}
	
	
}



