import {main1ViewProvider} from "../view/main1.view.mjs";  // TEST 


export function main1View5Config(staticConfig, alertId, agencyIds){
    
    var hrefConfig = staticConfig.getHrefConfig();
    var options = staticConfig.getOptions();
    
    var mainView = main1ViewProvider(hrefConfig, options);
    mainView.setNoBlockHeader();
    
    var alertListService = mainView.getAlertListView().getAlertListService();
    alertListService.setAlertMacroName('displayAlertStyle2');
    alertListService.setRouteMacroName('displayRoutes2');
    alertListService.setHrefConfig(hrefConfig);
    //alertListService.setAgencyIds(agencyIds);
    //alertListService.setRouteIds(['410'])


    if (alertId){        
        alertListService.setFilterProperties('id',alertId);
    }

    return mainView;
}