import {DataSourceInterface} from "./datasource.interface.mjs";


export class MultipleDataSource extends DataSourceInterface{
    constructor(){
        super();
        var _dataSources = [];

        Object.defineProperty(this,'dataSources',{
            get : function(){ return _dataSources }
        });


    }
    getDataSources(){ return this.dataSources }

    add(d){
    this.dataSources.push(d);
    }

    sync(){
        var dataSources = this.getDataSources();
        var promisesArray = dataSources.map((dataSource) => dataSource.get() );
        return Promise.all(promisesArray);
    }

    merge(arr){
        // TODO (use a merger, should return one single object) 
        return arr[0];
    }
    
    getTypedObject(){  
        return this.sync().then(this.merge);
    }
}

