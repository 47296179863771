import {loggerFactory} from "./loggerfactory.mjs";
var logger = loggerFactory.createLogger('DUM','info',true);

import EventEmitter from "events";


/* local definitions */
class NotificationEmitter extends EventEmitter {};


/* https://nodejs.org/api/events.html */
export default (function(){
    
	var _notifEmit = new NotificationEmitter(), 
		_feedinfo = null,
		_timestamp = null,
		_autoUpdate = 0,
        _onDataUpdate = null
		;

	
	
	
	/* --- */
	/* internals */ 
	
	
	
	var promiseTimestamp = function(){

		return new Promise(function(resolve,reject){
			_feedinfo(null,update);
			
            function update(error,json){
                if (error) reject(error);
                else {
                    var feedinfo = JSON.parse(json),
                        timestamp = feedinfo.timestamp;
                    resolve(timestamp);
                }
			}
		});
	};
    
    var init = function(){
        return promiseTimestamp().then(updateTimestamp);
    }
    
    var updateTimestamp = function(timestamp){
        _timestamp = timestamp; 
        return timestamp;
    }
    
    var dataUpdated = function(timestamp){
        logger.debug('>>',_timestamp,' ',timestamp)
        return (_timestamp != timestamp) 
    }
    
    var dataUpdateNotification = function(updated){
        if (updated) {
            logger.info('data updated. Send notification ')
            _notifEmit.emit('dataUpdated');
        }
    }
    
    var promiseChain = function(){
        var promise = promiseTimestamp();
        promise.then(dataUpdated).then(dataUpdateNotification);
        promise.then(updateTimestamp);
    }
	

    var autoUpdate = function(){
        logger.debug('autoupdate',_autoUpdate)  // TO_DELETE
        promiseChain();
        setTimeout(autoUpdate,_autoUpdate);
    }


	
	
	/* --- */
	/* api */
	
		
	var config = (function(){
		return { setAutoUpdate, onDataUpdated, inject };
		
		function setAutoUpdate(timeout){
			_autoUpdate = timeout; 
		}
        
        function onDataUpdated(callback){
             logger.debug('setting callback')  // TO_DELETE
            _notifEmit.on('dataUpdated', callback );
        }
        
        function inject(feedinfo){
            _feedinfo = feedinfo; 
        }
        
	})();	
	
	
	function lastUpdated(){
		return _timestamp;	
	}
	
	function upToDate(timestamp){
        if (_timestamp )
		return (timeStamp >= _timestamp);
	}
	
	/* --- */
    
    function start(){
        logger.info('updatenotif start',_autoUpdate); // to_delete
        init().then(autoUpdate).catch(function(e){logger.error('start:catch!!',e)});
    }

	
	var api = { lastUpdated, upToDate };
	
	return { config, start, api};

})();

/**
cd lib; $env:DATAUPDATEMONITORING="debug"


var callback1 = function(){
    console.log('>> callback1');
}
var callback2 = function(){
    console.log('>> callback2');
}

var UrbiplanApiServices = require('./'),
	client = UrbiplanApiServices.createClient({env:'preprod',instance:'rochefort'}),
    feedinfo = client.feedinfo;
    
var dataMonitor = require('./internals/dataupdatemonitoring.service');
	//dataMonitor = DataUpdateMonitoring(feedinfo);
dataMonitor.config.setAutoUpdate('3000');
dataMonitor.config.onDataUpdated(callback1);
dataMonitor.config.onDataUpdated(callback2);
dataMonitor.config.inject(feedinfo);
dataMonitor.start();

console.log(dataMonitor.api.lastUpdated());
*/