

export class UrbiplanApiRoute{
	constructor(){
		var  _id, _agencyId, _shortName, _longName, _color, _textColor, _type, _patterns, _direction0Name, _direction1Name, _fromDate, _toDate, _wheelchairAccessiblePerc, _wheelchairUnaccessiblePerc;
		
		
		Object.defineProperty(this,'id',{
			set : function(v){
				_id = v;
			},
			get : function(){
				return _id;
			},
			enumerable : true
		});
		
		Object.defineProperty(this,'agencyId',{
			set : function(v){
				_agencyId = v;
			},
			get : function(){
				return _agencyId;
			},
			enumerable : true
		});
		
		Object.defineProperty(this,'shortName',{
			set : function(v){
				_shortName = v;
			},
			get : function(){
				return _shortName;
			},
			enumerable : true
		});
		
		Object.defineProperty(this,'longName',{
			set : function(v){
				_longName = v;
			},
			get : function(){
				return _longName;
			},
			enumerable : true
		});
		
		Object.defineProperty(this,'color',{
			set : function(v){
				_color = v;
			},
			get : function(){
				return _color;
			},
			enumerable : true
		});
		
		Object.defineProperty(this,'textColor',{
			set : function(v){
				_textColor = v;
			},
			get : function(){
				return _textColor;
			},
			enumerable : true
		});
		
		Object.defineProperty(this,'type',{
			set : function(v){
				_type = v;
			},
			get : function(){
				return _type;
			},
			enumerable : true
		});
		
		Object.defineProperty(this,'patterns',{
			set : function(v){
				_patterns = v;
			},
			get : function(){
				return _patterns;
			},
			enumerable : true
		});
		
		Object.defineProperty(this,'direction0Name',{
			set : function(v){
				_direction0Name = v;
			},
			get : function(){
				return _direction0Name;
			},
			enumerable : true
		});
		
		Object.defineProperty(this,'direction1Name',{
			set : function(v){
				_direction1Name = v;
			},
			get : function(){
				return _direction1Name;
			},
			enumerable : true
		});
		
		Object.defineProperty(this,'fromDate',{
			set : function(v){
				_fromDate = v;
			},
			get : function(){
				return _fromDate;
			},
			enumerable : true
		});
		
		Object.defineProperty(this,'toDate',{
			set : function(v){
				_toDate = v;
			},
			get : function(){
				return _toDate;
			},
			enumerable : true
		});
		
		Object.defineProperty(this,'wheelchairAccessiblePerc',{
			set : function(v){
				_wheelchairAccessiblePerc = v;
			},
			get : function(){
				return _wheelchairAccessiblePerc;
			},
			enumerable : true
		});
		
		Object.defineProperty(this,'wheelchairUnaccessiblePerc',{
			set : function(v){
				_wheelchairUnaccessiblePerc = v;
			},
			get : function(){
				return _wheelchairUnaccessiblePerc;
			},
			enumerable : true
		});
		
	}
	
	getId(){ return this.id }
	
	getShortName(){ return this.shortName; }
	
	getColor(){ return this.color; }
	
	getTextColor(){ return this.textColor; } 
	
	getAgency(){ return this.agencyId } // TMP !! should be this.agency instead of agencyId (use alias ?)
	
	
	static filterByAgencyIds(routes,agencyIds){
		var agencyIdsSet = new Set(agencyIds);
		function filter(route){
			if (!agencyIdsSet.size) return true;
			return agencyIdsSet.has(route.getAgency().getId());
		}
		return routes.filter(filter);
	}
	
	static sorterByShortName(routes){         
		var sorter = function(route1, route2){  // should be in urbiplanApiRoute
               return route1.getShortName().localeCompare(route2.getShortName(), undefined, {numeric: true, sensitivity: 'base'})
		}
        return routes.sort(sorter);
	}
	
}	
