import prettyjson from "prettyjson";


export class Utils{
    static dowToHashKey(dow){
        return dow.reduce((res,x) => (res << 1) | x)   
    }
     
    static pad(num, size) {
         var s = "000000000" + num;
         return s.substr(s.length-size);
    }
     
    static hashKeyToDow(x){
         return Utils.pad(x.toString(2),8).split('').map(x => x === '1')
    }
    /**
     * 
     * @param weekDays ['Lundi','Mardi','Mercredi','Jeudi','Vendredi','Samedi','Dimanche','Jours fériés']
     * @param dow [1,1,0,1,0,1,0,0]
     */
    static dowToArray(weekDays, dow){
        return weekDays.filter((day,index) => dow[index])     
    }
    static getRandomInt(max) {
        return Math.floor(Math.random() * Math.floor(max));
    }
    static getMapKeyValue(object, key){  // #COMMENT To remove 
        if (key in object) return object[key];
        else throw new Error('getMapKeyValue: key does not exist in object');
    }
    static errorHandler(error){
        console.log("\x1b[31m","Error:",error,"\x1b[0m");
    }

    static removeLinebreaks( str ) { 
        return str.replace( /[\r\n]+/gm, "" ); 
    } 

    static transpose(a) {
        var w = a.length || 0;
        var h = a[0] instanceof Array ? a[0].length : 0;
        if(h === 0 || w === 0) { return []; }
    
        var i, j, t = [];
        for(i=0; i<h; i++) {
          t[i] = [];
          for(j=0; j<w; j++) {
            t[i][j] = a[j][i];
          }
        }
        return t;
      }

    static arrayEqual(arr1,arr2){   // TO DEPRECATE
        var hasSameValues = (value, index) => (arr2[index] === value);
        return arr1.every(hasSameValues);
    }

    static hashCode(s){  // https://gist.github.com/hyamamoto/fd435505d29ebfa3d9716fd2be8d42f0
        var h = 0, l = s.length, i = 0;
        if ( l > 0 )
            while (i < l)
            h = (h << 5) - h + s.charCodeAt(i++) | 0;
        return h;
    }

    static combineHashCodes(...keys){  //https://stackoverflow.com/questions/1646807/quick-and-simple-hash-code-combinations
        var reducer = (accumulator, key) => (accumulator * 31 + Utils.hashCode(key))
        return keys.reduce(reducer,17);
    }

    static compareHashCodes(h1,h2){
        if (h1 === h2) return 0;
        if (h1 < h2 ) return 1;
        if (h1 > h2) return -1; 
    }

    static compareStrings(str1,str2){
        if ((str1 !== undefined) && (str2 !== undefined))
            return str1.localeCompare(str2);
        else return -1;
    }

    /**
     * Remove accents, remove special characters 
     * Check: https://stackoverflow.com/a/37511463/10684825
     * ex: 'École Primaire Jean de la Fontaine (REMOUILLE)' > ''Ecole Primaire Jean de la Fontaine REMOUILLE';
     */
    static normalizeString(str){
        var normStr =  str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        normStr = normStr.replace(/[^0-9a-zA-Z ]/g, " ");
        return normStr;
    }

    static  replaceUrl(text) {
        var urlRegex = /(https?:\/\/[^\s]+)[^\.]/g;
        return text.replace(urlRegex, function(url) {
          return '<a href="' + url + '">' + url + '</a>';
        })
    }

    static replaceEmail(text){
        var emailRegex = /\S+@\S+/g;
        return text.replace(emailRegex, function(email) {
          return '<a href="mailTo:' + email + '">' + email + '</a>';
        })       
    }

    static formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('/');
    }

    static diffDays(date1,date2){
        var diffDays =  (date1.getDate() - date2.getDate());
        return diffDays;
    }

}

