
import {loggerFactory} from "./loggerfactory.mjs";
var logger = loggerFactory.createLogger('CACHESERV','info',true);


import Cache from "./cache.mjs"; 

export default function(){
	var _cache, _keyBuilder = keyBuilder2;
    
    
    /* --- */
    /* internals */ 
    
    
	/*
	function keyBuilder(param){
		var keys = Object.keys(param);
		return keys.join('.');
	}
	*/
	
	function keyBuilder2(url){
		return url;
	}

    /* --- */
    /* api */ 
    
	var config = (function(){
		return { cache, setKeyBuilder }
		
		function cache(conf){
			_cache =  Cache(conf);	
		}
		
		function setKeyBuilder(keyBuilder){
			_keyBuilder = keyBuilder;
		}
	})();
    
    
	function request(url, response){
		logger.info('requesting cache url:',url);
		// buildkey key 
		var key = _keyBuilder(url);
        var value = _cache.getEntry(key);
        logger.info('response from cache:',(!value) ? "null": value.substr(0,30)+'...');
		response(value); 	
	}
    
	
	function update(url,timestamp, data){
        logger.info('cache update');
		var key = _keyBuilder(url);
		_cache.setEntry( key, timestamp, data);
	}
    
    function clear(){
        logger.info('clearing cache');
        _cache.clear();
    }
    
    /* --- */
    var api = { request, update, clear }
	
	return { config, api }	

	
};

/*
var CacheServices = require('./cache.services');
var cacheServices1 = CacheServices();
cacheServices1.config.cache({maxKeys:1});

var url = 'http://app.mecatran.com/utw/ws/gtfs/stops/lila';
cacheServices1.update(url, null, 'xxx');
cacheServices1.request(url, function(item){
	console.log(item);
})



*/