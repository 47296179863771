import {main1ViewProvider} from "../view/main1.view.mjs";  // TEST 


export function main1View2Config(staticConfig, alertId, agencyIds){
    
    var hrefConfig = staticConfig.getHrefConfig();
    var options = staticConfig.getOptions();
    
    var mainView = main1ViewProvider(hrefConfig, options);
    mainView.setBlockHeaderName('displayBlockHeader2');
    var alertListService = mainView.getAlertListView().getAlertListService();
    alertListService.setHrefConfig(hrefConfig);

    return mainView;
}