//var filterObjectsByKV = require('../services/urbiplanapi-extensions/urbiplanapi.filter.js');
var { filterRoutesByAgencyIds, filterAlertsByRouteIds } = require('../services/urbiplanapi-extensions/urbiplanapi.filter.js');
var sorterRoutesByShortName = require('../services/urbiplanapi-extensions/urbiplanapi.sorter.js')
var {printString, formatString, filterObjectsByKV, replaceUrl } = require('./nunjucksextensions.js')

module.exports = function(env){
    env.opts.autoescape = false;
	env.addFilter('filter', filterObjectsByKV);
	env.addFilter('sorterRoutesByShortname', sorterRoutesByShortName);
	env.addFilter('filterRoutesByAgencyIds', filterRoutesByAgencyIds);
	env.addFilter('filterAlertsByRouteIds', filterAlertsByRouteIds);
	env.addFilter('formatString', formatString);
	env.addFilter('printString',printString);
	env.addFilter('replaceEmail', replaceUrl );
    
	return env;
    // env.addExtension(...) etc
}


