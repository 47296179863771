/**
LoggerFactory 

USAGE:
LoggerFactory = require('./loggerfactory');
var logger = LoggerFactory.createLogger('pompom');

LoggerFactory requires:
- an instance of bunyan
- an instance of a formatter:
	- for the browser: @bunyan-browser/console-formatted-stream 
	- for node: NodeFomattedStream 
*/

export default  function(bunyan,FormattedStream){

	class LoggerFactory{
		constructor(){
			var _loggerManager = new LoggerManager();
			// ===============
			// Public accessors
			// ---------------- 			
			Object.defineProperty(this, 'createLogger', {
				value : function(name, level, src){
					var stream = {
							stream : new FormattedStream(),
							type : 'raw'
						},
						options = { 
							name, level, src,
							streams : [ stream ]
						};
					//var logger = bunyan.createLogger(options); 
					var logger = bunyan.createLogger(options); 
					
					_loggerManager.register(name, logger);
						
					return logger;
				},				
				enumerable : true,
			}); 
			
			Object.defineProperty(this,'loggerManager',{
				get : function(){ return _loggerManager } ,
				enumerable : true
			});
			
			
			// =================
			// Private functions
			// -----------------
			
			
		}; 
	}
	
	class LoggerManager{
		constructor(){
			var _loggers = {}
			;

			// ===================
			// Protected functions
			// -------------------
			
			Object.defineProperty(this,'loggers',{
				get : function(){
					return _loggers;
				},
				enumerable : false
			});
						

			// ================
			// Public functions
			// ----------------
			
			Object.defineProperty(this,'register',{
				value : function(loggerName, logger){
					_loggers[loggerName] = logger; 
					Object.defineProperty(this, loggerName, {
						get : function(){
							return _loggers[loggerName];
						},
						enumerable: true,
						configurable: true
					});
				},
				enumerable : true
			});
			
		}
		turnOn(level,loggerName){
			var level = level || 'info';
			if (loggerName){
				if (loggerName in this.loggers){
					this.loggers[loggerName].level(level);
				} else throw new Error('logger:setLevel, loggerName is unknown');
			} else {
				Object.values(this.loggers).forEach(logger => logger.level(level));
			}
		}	
			
		turnOff(){
			Object.values(this.loggers).forEach(logger => logger.level(70));
		}

		src(bool,loggerName){
			var bool = bool || false;
			if ((bool != true) && (bool != false)) throw new Error('logger::src, parameter should be bool,!logger')
			if (loggerName){
				this.loggers[loggerName].src = bool;
			}else{
				Object.values(this.loggers).forEach(logger=>logger.src=bool);
			}
		} 
		srcOn(logger){
			this.src(true,logger);
		}
		srcOff(logger){
			this.src(false,logger);
		}
	}
	
	return new LoggerFactory();
}


