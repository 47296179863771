import util from 'util';
import {Utils} from '../utils';


export var routeSuggestionEngine = function(data, getQueriablesValues){
    var findMatches = (query, callback) => {
        if (!query.trim().length){ callback(data); return }
        var matches = [];
        var normQuery = Utils.normalizeString(query);
        var substrRegex = new RegExp(normQuery,'i');
        var str;
        for (var i = 0; i < data.length; i++) {  
            var strings = getQueriablesValues(data[i])
            var normStrings = strings.map(str => Utils.normalizeString(str));
            normStrings = normStrings.filter(normStr => substrRegex.test(normStr));
            if (normStrings.length) matches.push(data[i]);
        }
        callback(matches)
        //return matches;
    }
    return findMatches;   
}


/*
export var routeSuggestionEngine = function(data, dataProps){
    var findMatches = (query, callback) => {
        var matches = [];
        var normQuery = Utils.normalizeString(query);
        var substrRegex = new RegExp(normQuery,'i');
        var str;
        for (var i = 0; i < data.length; i++) {  
            var strings = Object.entries(data[i]).filter((keyval)=> dataProps.includes(keyval[0])).map(keyval=>keyval[1]);
            var normStrings = strings.map(str => Utils.normalizeString(str));
            normStrings = normStrings.filter(normStr => substrRegex.test(normStr));
            if (normStrings.length) matches.push(data[i]);
        }
        callback(matches)
        //return matches;
    }
    return findMatches;   
}
*/