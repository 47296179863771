var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../customers/herault/widget-alerts/node_modules/@mecatran-projects/widget-alerts/src/nunjucks-extensions/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["./icons.njk"] = require( "./icons.njk" );




var shim = require("A:/dev/workspace-node/bitbucket_repository/projects/customers/herault/widget-alerts/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["../../../widget-alerts/src/templates/macros.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("./icons.njk", false, "../../../widget-alerts/src/templates/macros.njk", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("icons", t_1);
output += "\r\n\r\n";
var macro_t_4 = runtime.makeMacro(
["agencies"], 
[], 
function (l_agencies, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("agencies", l_agencies);
var t_5 = "";t_5 += "\r\n    <div class=\"agency-container\">\r\n        ";
frame = frame.push();
var t_8 = l_agencies;
if(t_8) {t_8 = runtime.fromIterator(t_8);
var t_7 = t_8.length;
for(var t_6=0; t_6 < t_8.length; t_6++) {
var t_9 = t_8[t_6];
frame.set("agency", t_9);
frame.set("loop.index", t_6 + 1);
frame.set("loop.index0", t_6);
frame.set("loop.revindex", t_7 - t_6);
frame.set("loop.revindex0", t_7 - t_6 - 1);
frame.set("loop.first", t_6 === 0);
frame.set("loop.last", t_6 === t_7 - 1);
frame.set("loop.length", t_7);
t_5 += "\r\n            <div class=\"item\" >";
t_5 += runtime.suppressValue((lineno = 5, colno = 48, runtime.callWrap(runtime.memberLookup((t_9),"getName"), "agency[\"getName\"]", context, [])), env.opts.autoescape);
t_5 += "</div>\r\n        ";
;
}
}
frame = frame.pop();
t_5 += "\r\n    </div>\r\n";
;
frame = callerFrame;
return new runtime.SafeString(t_5);
});
context.addExport("displayAgencies");
context.setVariable("displayAgencies", macro_t_4);
output += "\r\n\r\n";
var macro_t_10 = runtime.makeMacro(
["routes"], 
[], 
function (l_routes, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("routes", l_routes);
var t_11 = "";t_11 += "\r\n    <div class=\"route-container\">\r\n        ";
frame = frame.push();
var t_14 = env.getFilter("sorterRoutesByShortname").call(context, l_routes);
if(t_14) {t_14 = runtime.fromIterator(t_14);
var t_13 = t_14.length;
for(var t_12=0; t_12 < t_14.length; t_12++) {
var t_15 = t_14[t_12];
frame.set("route", t_15);
frame.set("loop.index", t_12 + 1);
frame.set("loop.index0", t_12);
frame.set("loop.revindex", t_13 - t_12);
frame.set("loop.revindex0", t_13 - t_12 - 1);
frame.set("loop.first", t_12 === 0);
frame.set("loop.last", t_12 === t_13 - 1);
frame.set("loop.length", t_13);
t_11 += "\r\n            <div class=\"flex-container\">\r\n                <div class=\"item two\" style=\"color: #";
t_11 += runtime.suppressValue((lineno = 14, colno = 74, runtime.callWrap(runtime.memberLookup((t_15),"getTextColor"), "route[\"getTextColor\"]", context, [])), env.opts.autoescape);
t_11 += "; background-color : #";
t_11 += runtime.suppressValue((lineno = 14, colno = 118, runtime.callWrap(runtime.memberLookup((t_15),"getColor"), "route[\"getColor\"]", context, [])), env.opts.autoescape);
t_11 += " ; border: 1px solid #";
t_11 += runtime.suppressValue((lineno = 14, colno = 165, runtime.callWrap(runtime.memberLookup((t_15),"getTextColor"), "route[\"getTextColor\"]", context, [])), env.opts.autoescape);
t_11 += "\">";
t_11 += runtime.suppressValue((lineno = 14, colno = 192, runtime.callWrap(runtime.memberLookup((t_15),"getShortName"), "route[\"getShortName\"]", context, [])), env.opts.autoescape);
t_11 += "</div>\r\n            </div>\r\n        ";
;
}
}
frame = frame.pop();
t_11 += "\r\n    </div>\r\n";
;
frame = callerFrame;
return new runtime.SafeString(t_11);
});
context.addExport("displayRoutes1");
context.setVariable("displayRoutes1", macro_t_10);
output += "\r\n\r\n\r\n";
var macro_t_16 = runtime.makeMacro(
["routes"], 
[], 
function (l_routes, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("routes", l_routes);
var t_17 = "";t_17 += "\r\n    <div class=\"route-container\">\r\n        ";
frame = frame.push();
var t_20 = env.getFilter("sorterRoutesByShortname").call(context, l_routes);
if(t_20) {t_20 = runtime.fromIterator(t_20);
var t_19 = t_20.length;
for(var t_18=0; t_18 < t_20.length; t_18++) {
var t_21 = t_20[t_18];
frame.set("route", t_21);
frame.set("loop.index", t_18 + 1);
frame.set("loop.index0", t_18);
frame.set("loop.revindex", t_19 - t_18);
frame.set("loop.revindex0", t_19 - t_18 - 1);
frame.set("loop.first", t_18 === 0);
frame.set("loop.last", t_18 === t_19 - 1);
frame.set("loop.length", t_19);
t_17 += "\r\n            <div class=\"item\" style=\"color: #";
t_17 += runtime.suppressValue((lineno = 24, colno = 66, runtime.callWrap(runtime.memberLookup((t_21),"getTextColor"), "route[\"getTextColor\"]", context, [])), env.opts.autoescape);
t_17 += " ;background-color : #";
t_17 += runtime.suppressValue((lineno = 24, colno = 110, runtime.callWrap(runtime.memberLookup((t_21),"getColor"), "route[\"getColor\"]", context, [])), env.opts.autoescape);
t_17 += " ; border: 1px solid #";
t_17 += runtime.suppressValue((lineno = 24, colno = 158, runtime.callWrap(runtime.memberLookup((t_21),"getTextColor"), "route[\"getTextColor\"]", context, [])), env.opts.autoescape);
t_17 += "\">";
t_17 += runtime.suppressValue((lineno = 24, colno = 186, runtime.callWrap(runtime.memberLookup((t_21),"getShortName"), "route[\"getShortName\"]", context, [])), env.opts.autoescape);
t_17 += "</div>\r\n        ";
;
}
}
frame = frame.pop();
t_17 += "\r\n    </div>\r\n";
;
frame = callerFrame;
return new runtime.SafeString(t_17);
});
context.addExport("displayRoutes2");
context.setVariable("displayRoutes2", macro_t_16);
output += "\r\n\r\n";
var macro_t_22 = runtime.makeMacro(
["href", "title"], 
[], 
function (l_href, l_title, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("href", l_href);
frame.set("title", l_title);
var t_23 = "";t_23 += "\r\n    <div class=\"block-header block-header1\">\r\n        <a class=\"block-title\" href=\"";
t_23 += runtime.suppressValue(l_href, env.opts.autoescape);
t_23 += "\">";
t_23 += runtime.suppressValue(env.getFilter("upper").call(context, l_title), env.opts.autoescape);
t_23 += "</a>\r\n        <div class=\"switch-type\">\r\n                            <a data-target=\".liste-infos-trafic\" class=\"ico-bus selected\"></a>\r\n                            <a data-target=\".liste-velo\" class=\"ico-velo\"></a>\r\n                            <a data-target=\".liste-voiture\" class=\"ico-voiture\"></a>\r\n                        </div>\r\n    </div>\r\n";
;
frame = callerFrame;
return new runtime.SafeString(t_23);
});
context.addExport("displayBlockHeader1");
context.setVariable("displayBlockHeader1", macro_t_22);
output += "\r\n\r\n";
var macro_t_24 = runtime.makeMacro(
["href", "title"], 
[], 
function (l_href, l_title, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("href", l_href);
frame.set("title", l_title);
var t_25 = "";t_25 += "\r\n    <div class=\"block-header block-header2\">";
t_25 += runtime.suppressValue(env.getFilter("upper").call(context, l_title), env.opts.autoescape);
t_25 += "</div>\r\n";
;
frame = callerFrame;
return new runtime.SafeString(t_25);
});
context.addExport("displayBlockHeader2");
context.setVariable("displayBlockHeader2", macro_t_24);
output += "\r\n\r\n\r\n";
var macro_t_26 = runtime.makeMacro(
["alert", "href", "routeMacroName", "routes", "macros", "alertListService"], 
[], 
function (l_alert, l_href, l_routeMacroName, l_routes, l_macros, l_alertListService, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("alert", l_alert);
frame.set("href", l_href);
frame.set("routeMacroName", l_routeMacroName);
frame.set("routes", l_routes);
frame.set("macros", l_macros);
frame.set("alertListService", l_alertListService);
var t_27 = "";t_27 += "\t  \r\n    <div class=\"alert alert-style1\">\r\n        <div class=\"title\"><a href=\"";
t_27 += runtime.suppressValue(l_href, env.opts.autoescape);
t_27 += "\">";
t_27 += runtime.suppressValue((lineno = 47, colno = 65, runtime.callWrap(runtime.memberLookup((l_alert),"getTitle"), "alert[\"getTitle\"]", context, [])), env.opts.autoescape);
t_27 += "</a></div>\r\n        <div class=\"active-range\">";
t_27 += runtime.suppressValue((lineno = 48, colno = 66, runtime.callWrap(runtime.memberLookup((l_alert),"getFormattedActiveRange"), "alert[\"getFormattedActiveRange\"]", context, [])), env.opts.autoescape);
t_27 += "</div>\r\n        ";
if((lineno = 49, colno = 34, runtime.callWrap(runtime.memberLookup((l_alert),"isGeneralAlert"), "alert[\"isGeneralAlert\"]", context, []))) {
t_27 += runtime.suppressValue((lineno = 49, colno = 64, runtime.callWrap(runtime.memberLookup((l_macros),"displayAgencies"), "macros[\"displayAgencies\"]", context, [(lineno = 49, colno = 82, runtime.callWrap(runtime.memberLookup((l_alert),"getAgencies"), "alert[\"getAgencies\"]", context, []))])), env.opts.autoescape);
;
}
t_27 += "\r\n        ";
t_27 += runtime.suppressValue((lineno = 50, colno = 33, runtime.callWrap(runtime.memberLookup((l_macros),l_routeMacroName), "macros[\"routeMacroName\"]", context, [l_routes])), env.opts.autoescape);
t_27 += "\r\n        ";
var t_28;
t_28 = (lineno = 51, colno = 49, runtime.callWrap(runtime.memberLookup((l_alert),"getDescription"), "alert[\"getDescription\"]", context, []));
frame.set("description", t_28, true);
if(frame.topLevel) {
context.setVariable("description", t_28);
}
if(frame.topLevel) {
context.addExport("description", t_28);
}
t_27 += "\r\n        ";
if(runtime.contextOrFrameLookup(context, frame, "description")) {
t_27 += "<div class=\"description\">";
t_27 += runtime.suppressValue(env.getFilter("formatString").call(context, (lineno = 52, colno = 76, runtime.callWrap(runtime.memberLookup((l_alert),"getDescription"), "alert[\"getDescription\"]", context, []))), env.opts.autoescape);
t_27 += "</div>";
;
}
t_27 += "\r\n        ";
var t_29;
t_29 = (lineno = 53, colno = 33, runtime.callWrap(runtime.memberLookup((l_alert),"getUrl"), "alert[\"getUrl\"]", context, []));
frame.set("url", t_29, true);
if(frame.topLevel) {
context.setVariable("url", t_29);
}
if(frame.topLevel) {
context.addExport("url", t_29);
}
t_27 += "\r\n        <br>\r\n        ";
if(runtime.contextOrFrameLookup(context, frame, "url")) {
t_27 += "<div class=\"url\"><a href=\"";
t_27 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "url"), env.opts.autoescape);
t_27 += "\">";
t_27 += runtime.suppressValue((lineno = 55, colno = 91, runtime.callWrap(runtime.memberLookup((l_alertListService),"getMessageLink"), "alertListService[\"getMessageLink\"]", context, [])), env.opts.autoescape);
t_27 += "</a></div>";
;
}
t_27 += "\r\n    </div>\r\n";
;
frame = callerFrame;
return new runtime.SafeString(t_27);
});
context.addExport("displayAlertStyle1");
context.setVariable("displayAlertStyle1", macro_t_26);
output += "\r\n\r\n\r\n";
var macro_t_30 = runtime.makeMacro(
["alert", "href", "routeMacroName", "routes", "macros", "alertListService"], 
[], 
function (l_alert, l_href, l_routeMacroName, l_routes, l_macros, l_alertListService, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("alert", l_alert);
frame.set("href", l_href);
frame.set("routeMacroName", l_routeMacroName);
frame.set("routes", l_routes);
frame.set("macros", l_macros);
frame.set("alertListService", l_alertListService);
var t_31 = "";t_31 += "\t  \r\n    <div class=\"alert alert-style2\">\r\n        <div class=\"title\"><a href=\"";
t_31 += runtime.suppressValue(l_href, env.opts.autoescape);
t_31 += "\">";
t_31 += runtime.suppressValue((lineno = 62, colno = 65, runtime.callWrap(runtime.memberLookup((l_alert),"getTitle"), "alert[\"getTitle\"]", context, [])), env.opts.autoescape);
t_31 += "</a> </div>\r\n        <div class=\"active-range\">";
t_31 += runtime.suppressValue((lineno = 63, colno = 66, runtime.callWrap(runtime.memberLookup((l_alert),"getFormattedActiveRange"), "alert[\"getFormattedActiveRange\"]", context, [])), env.opts.autoescape);
t_31 += "</div>\r\n        ";
if((lineno = 64, colno = 34, runtime.callWrap(runtime.memberLookup((l_alert),"isGeneralAlert"), "alert[\"isGeneralAlert\"]", context, []))) {
t_31 += runtime.suppressValue((lineno = 64, colno = 64, runtime.callWrap(runtime.memberLookup((l_macros),"displayAgencies"), "macros[\"displayAgencies\"]", context, [(lineno = 64, colno = 82, runtime.callWrap(runtime.memberLookup((l_alert),"getAgencies"), "alert[\"getAgencies\"]", context, []))])), env.opts.autoescape);
;
}
t_31 += "\r\n        ";
t_31 += runtime.suppressValue((lineno = 65, colno = 33, runtime.callWrap(runtime.memberLookup((l_macros),l_routeMacroName), "macros[\"routeMacroName\"]", context, [l_routes])), env.opts.autoescape);
t_31 += "\r\n    </div>\r\n";
;
frame = callerFrame;
return new runtime.SafeString(t_31);
});
context.addExport("displayAlertStyle2");
context.setVariable("displayAlertStyle2", macro_t_30);
output += "\r\n\r\n";
var macro_t_32 = runtime.makeMacro(
["alert", "href", "routeMacroName", "routes", "macros", "alertListService"], 
[], 
function (l_alert, l_href, l_routeMacroName, l_routes, l_macros, l_alertListService, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("alert", l_alert);
frame.set("href", l_href);
frame.set("routeMacroName", l_routeMacroName);
frame.set("routes", l_routes);
frame.set("macros", l_macros);
frame.set("alertListService", l_alertListService);
var t_33 = "";t_33 += "\t  \r\n    <div class=\"alert alert-style2\">\r\n        ";
if((lineno = 71, colno = 34, runtime.callWrap(runtime.memberLookup((l_alert),"isGeneralAlert"), "alert[\"isGeneralAlert\"]", context, []))) {
t_33 += runtime.suppressValue((lineno = 71, colno = 64, runtime.callWrap(runtime.memberLookup((l_macros),"displayAgencies"), "macros[\"displayAgencies\"]", context, [(lineno = 71, colno = 82, runtime.callWrap(runtime.memberLookup((l_alert),"getAgencies"), "alert[\"getAgencies\"]", context, []))])), env.opts.autoescape);
;
}
t_33 += "\r\n        ";
t_33 += runtime.suppressValue((lineno = 72, colno = 33, runtime.callWrap(runtime.memberLookup((l_macros),l_routeMacroName), "macros[\"routeMacroName\"]", context, [l_routes])), env.opts.autoescape);
t_33 += "\r\n        ";
var t_34;
t_34 = (lineno = 73, colno = 56, runtime.callWrap(runtime.memberLookup((l_alertListService),"getLevelIconName"), "alertListService[\"getLevelIconName\"]", context, [(lineno = 73, colno = 71, runtime.callWrap(runtime.memberLookup((l_alert),"getLevel"), "alert[\"getLevel\"]", context, []))]));
frame.set("level", t_34, true);
if(frame.topLevel) {
context.setVariable("level", t_34);
}
if(frame.topLevel) {
context.addExport("level", t_34);
}
t_33 += "\r\n        <div class=\"level ";
t_33 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "level"), env.opts.autoescape);
t_33 += "\">\r\n            ";
t_33 += runtime.suppressValue((lineno = 75, colno = 27, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "icons")),runtime.contextOrFrameLookup(context, frame, "level")), "icons[\"level\"]", context, [])), env.opts.autoescape);
t_33 += "\r\n        </div>\r\n        <!--div class=\"title\"><a href=\"";
t_33 += runtime.suppressValue(l_href, env.opts.autoescape);
t_33 += "\">";
t_33 += runtime.suppressValue((lineno = 77, colno = 68, runtime.callWrap(runtime.memberLookup((l_alert),"getTitle"), "alert[\"getTitle\"]", context, [])), env.opts.autoescape);
t_33 += "</a> </div-->\r\n        <div class=\"title\">";
t_33 += runtime.suppressValue((lineno = 78, colno = 44, runtime.callWrap(runtime.memberLookup((l_alert),"getTitle"), "alert[\"getTitle\"]", context, [])), env.opts.autoescape);
t_33 += " </div><!-- TMP -->\r\n        <div class=\"active-range\">";
t_33 += runtime.suppressValue((lineno = 79, colno = 66, runtime.callWrap(runtime.memberLookup((l_alert),"getFormattedActiveRange"), "alert[\"getFormattedActiveRange\"]", context, [])), env.opts.autoescape);
t_33 += "</div>\r\n        ";
var t_35;
t_35 = (lineno = 80, colno = 49, runtime.callWrap(runtime.memberLookup((l_alert),"getDescription"), "alert[\"getDescription\"]", context, []));
frame.set("description", t_35, true);
if(frame.topLevel) {
context.setVariable("description", t_35);
}
if(frame.topLevel) {
context.addExport("description", t_35);
}
t_33 += "\r\n        ";
if(runtime.contextOrFrameLookup(context, frame, "description")) {
t_33 += "<div class=\"description\">";
t_33 += runtime.suppressValue(env.getFilter("formatString").call(context, env.getFilter("replaceEmail").call(context, (lineno = 81, colno = 76, runtime.callWrap(runtime.memberLookup((l_alert),"getDescription"), "alert[\"getDescription\"]", context, [])))), env.opts.autoescape);
t_33 += "</div>";
;
}
t_33 += "\r\n        ";
var t_36;
t_36 = (lineno = 82, colno = 33, runtime.callWrap(runtime.memberLookup((l_alert),"getUrl"), "alert[\"getUrl\"]", context, []));
frame.set("url", t_36, true);
if(frame.topLevel) {
context.setVariable("url", t_36);
}
if(frame.topLevel) {
context.addExport("url", t_36);
}
t_33 += "\r\n        ";
t_33 += runtime.suppressValue((lineno = 83, colno = 33, runtime.callWrap(runtime.memberLookup((l_macros),"displayResource"), "macros[\"displayResource\"]", context, [(lineno = 83, colno = 59, runtime.callWrap(runtime.memberLookup((l_alert),"getResourceMimeType"), "alert[\"getResourceMimeType\"]", context, [])),(lineno = 83, colno = 83, runtime.callWrap(runtime.memberLookup((l_alert),"getResourceUrl"), "alert[\"getResourceUrl\"]", context, []))])), env.opts.autoescape);
t_33 += "\r\n        ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "url")),"length")) {
t_33 += "<a href=\"";
t_33 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "url"), env.opts.autoescape);
t_33 += "\" target=\"_blank\">Consulter le lien</a>";
;
}
t_33 += "\r\n    </div>\r\n";
;
frame = callerFrame;
return new runtime.SafeString(t_33);
});
context.addExport("displayAlertStyle3");
context.setVariable("displayAlertStyle3", macro_t_32);
output += "\r\n\r\n";
var macro_t_37 = runtime.makeMacro(
["mimeType", "url"], 
[], 
function (l_mimeType, l_url, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("mimeType", l_mimeType);
frame.set("url", l_url);
var t_38 = "";t_38 += "\r\n    ";
if(l_mimeType === "image/png" || l_mimeType === "image/jpeg") {
t_38 += "\r\n            <img src=\"";
t_38 += runtime.suppressValue(l_url, env.opts.autoescape);
t_38 += "\" alt=\"\" style=\"display:block\">\r\n    ";
;
}
t_38 += "\r\n";
;
frame = callerFrame;
return new runtime.SafeString(t_38);
});
context.addExport("displayResource");
context.setVariable("displayResource", macro_t_37);
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["../../../widget-alerts/src/templates/macros.njk"] , dependencies)