import {View} from "./view.mjs";
import selectorTemplate from "../templates/selector.njk";
//import { Autocomplete,Dataset } from "@mecatran/autocomplete-selector-component";
import { Dropdown, Dataset } from "@mecatran/dropdown-selector-component";
import {routeSuggestionEngine} from "../services/suggestionengine/routesuggestionengine.mjs";
import routeSuggestionTemplate from "../templates/route-suggestion.njk";

export class SelectorView extends View{
    constructor(){
        super();
        var _routeComponentId = 'route',
            _routeComponentSelector = `#${_routeComponentId}`,
            _routeAutocomplete = SelectorView.createAutocompleteComponent(_routeComponentId,this.getTrRoutePlaceholder())

        Object.defineProperty(this,'routeAutocomplete',{
            get : function(){ return _routeAutocomplete }
        });

        Object.defineProperty(this,'routeComponentSelector',{
            get : function(){ return _routeComponentSelector }
        })
    }

    // getters 
    // -------
    getConstructor(){ return this.constructor }

    getRouteAutocomplete(){ return this.routeAutocomplete }

    getRouteComponent(){
        //var document = this.getDocument()
        var node = document.querySelector(this.routeComponentSelector); // !!document
        return node; 
    }


    getTrRoutePlaceholder(){ return 'Choisir une ligne'}

    getTrRouteComponentLabel(){ return "Choisissez votre ligne" }  // TMP use translations 


    getRouteDatasetName(){ return SelectorView.datasetName.route }

    // public
    // ------
    hideRouteComponent(){
        // var document = this.getDocument(); 
        document.querySelector('route-selector').style.display = 'none'; 
    }

    showRouteComponent(){
        // var document = this.getDocument();
        document.querySelector('route-selector').style.display = ''; 
    }

    addRoutes(routes){
        var routeDataset = SelectorView.createRouteDataset(routes, SelectorView.datasetName.route );
        this.getRouteAutocomplete().addDataset(routeDataset);
    }


    onSelectEvent(){
        var autocomplete = this.getRouteAutocomplete();
        return {
            addHandler : (datasetName, handler ) => {
                autocomplete.addSelectEventHandler(datasetName, handler);
            }
        }    
    }

    onEmptyInputEvent(){
        var autocomplete = this.getRouteAutocomplete();
        return {
            addHandler : (handler) =>{
                autocomplete.addEmptyInputEventHandler(handler);
            }
        }
    }


    setDocumentListener(){  // this should be done on the active document
        this.getRouteAutocomplete().setDocumentListener();
    }

    // interface 
    // ---------
    initView(){   // should be an interface functions
        this.setDocumentListener();
        // this.hideRouteComponent();
    }

    getContext(){ 
        var context = {};
        context.getTrRouteComponentLabel = this.getTrRouteComponentLabel;
        return context;        
    }

    getTemplate(){
        return selectorTemplate;
    }

    getDocument(){
        var doc = this.getLayoutDoc();  // check #document -> child or childNodes or documentHtml
        var component = this.getRouteAutocomplete().getDOMNode();
        doc = SelectorView.addPluginSelector(component,doc, '.route-selector');
             
        return doc;
    }
    // ------
    // custom
    // ------

    static createAutocompleteComponent(id, placeholder){
        // return new Autocomplete(id, null, placeholder);
        return new Dropdown('route-selector',null,'Je recherche une ligne', 'autocomplete'); // TMP Translate
    }


    static createRouteDataset(items, datasetName, display){
        var dataset = new Dataset(datasetName);
        dataset.setSyncDataSource(items,['shortName','longName'], routeSuggestionEngine );
        dataset.templates.header = `<h2 class="title">Lignes</h2>`; //TMP use translations 
        dataset.templates.suggestion = routeSuggestionTemplate;
        dataset.display = (suggestion)=>{
             return `${suggestion.shortName} - ${ suggestion.longName}`
        };
        return dataset;
    }

    static addPluginSelector(component, document, selector){    // TMP
        var selectorContainer = View.getNode(document,selector);
        selectorContainer.append(component);
        return document;
    }
}

SelectorView.route = 'route'
SelectorView.datasetName = { route : 'route' }