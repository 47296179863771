
import urlConfig from "./api.config.mjs"; 



export  var getUrlBuilder = function(options){
    var options = options || {};
	var _env = options.env || process.env.env, 
		_instance = options.instance || process.env.instance,
        _key = options.key || process.env.URBIPLAN_API_KEY,
		_endPoint, _params, _complement;
	var config = (function(){
		return { setEndPoint, setParams, setEndPointComplement };
		        
		function setEndPoint(endPoint){
			_endPoint = endPoint;
			return config;
		}
        function setEndPointComplement(complement){
            _complement = complement;
            return config; 
        }
		function setParams(params){
			_params = params || {};
			return config;
		}
	})();
	
	return { config , getUrl }
    
    function buildUrlParams(){
        var urlParams, strParams;
        Object.keys(_params).forEach(function(param){
            //strParams = `${param}=${_params[param]}`;
            strParams = param + '=' + _params[param];
            if (!urlParams) urlParams = strParams;
            else urlParams = [urlParams, strParams ].join('&');
        });
        return urlParams;
    }
    
    function apiKeyParam(){
        if (_key) return `apiKey=${_key}`;
    }
	
	function buildUrl(){
		var url, urlParams, keyParam;
        
        url = [urlConfig.protocol, urlConfig[_env].domain, urlConfig[_env].path, _endPoint, _instance ];
        if (_complement) url.push(_complement);
        url = url.join('/');
        
        urlParams = buildUrlParams();
                     
        keyParam = apiKeyParam();             
        if (keyParam) urlParams = [urlParams, keyParam].join('&');
        
        if (urlParams) url = [url, urlParams ].join('?');
        
		return url;
	}
	
	function getUrl(){
		return buildUrl();
	}
}


/*
var UrlBuilder = require('./urlbuilder.services');
var urlBuilder = UrlBuilder({env:'prod',instance:'lila',key:'123'});
urlBuilder.config.setEndPoint('gtfs/stops').setParams({'includeStops':true,'includeStations':false})
urlBuilder.getUrl();
*/