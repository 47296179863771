export class UtilsWeb{
    constructor(){}



    static strToNode(str){     //#COMMENT to deprecate for strNodeList 
        var parser = new DOMParser();
        var node = parser.parseFromString(str,'text/html').childNodes[0]   // 10-05-2020
        return node;
    }
    
    
    static strToNodeList(str){  // rename printWeb, or getNodeList
        var parser = new DOMParser();
        // var node = parser.parseFromString(str,'text/html').childNodes[0]   // 10-05-2020
        var nodeList = parser.parseFromString(str,'text/html').body.childNodes;
        return nodeList;
    }

    static printWeb(str){  
        var nodeList = UtilsWeb.strToNodeList(str);
        return nodeList;
        /*
        if (nodeList.length > 1 ) return nodeList;
        else return nodeList[0];     // TMP not a good idea to return different types 
        */
    }

    static strToDocument(str){ // rename printWeb2 or getDocument
        var parser = new DOMParser();
        var document = parser.parseFromString(str,'text/html');
        return document;
    }

    static getDocumentFragment(str){  
        var fragment = document.createDocumentFragment();
        var nodeList = UtilsWeb.getNodeList(str);
        while(nodeList.length){
            fragment.append(nodeList[0]);
        }
        return fragment;
    }

    static strToHtmlElements(str){ return UtilsWeb.strToNodeList(str) }



}