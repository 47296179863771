

export class UrbiplanApiAgency{
	constructor(){
		var _id, _lang, _name, _phone, _timezone, _url, _routes;
		
		Object.defineProperty(this,'id',{
			set : function(id){ _id = id },
			get : function(){ return _id }
		});
		
		Object.defineProperty(this,'lang',{
			set : function(lang){ _lang = lang },
			get : function(){ return _lang }
		});
		
		Object.defineProperty(this,'name',{
			set : function(name){ _name = name },
			get : function(){ return _name }
		});
		
		Object.defineProperty(this,'phone',{
			set : function(phone){ _phone = phone },
			get : function(){ return _phone }
		});
		
		Object.defineProperty(this,'timezone',{
			set : function(timezone){ _timezone = timezone },
			get : function(){ return _timezone }
		});
		
		Object.defineProperty(this,'url',{
			set : function(url){ _url = url },
			get : function(){ return _url }
		});
		
		Object.defineProperty(this,'routes',{
			set : function(routes){ _routes = routes },
			get : function(){ return _routes }
		});
	}
	
	getName(){ return this.name }
	
	getId(){ return this.id } 
}
