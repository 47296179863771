import {DataSourceInterface} from './datasource.interface.mjs';
import * as lib from "@mecatran/urbiplan-services-lib"

var {UrbiplanApiLib, UrbiplanApiConfig, urbiplanApiModel} = lib;

console.log('lib',lib);
console.log('urbiplanApiModel',urbiplanApiModel)

export function urbiplanApiDataSourceProvider(urbiplanApiConfig){
    var _lib = new UrbiplanApiLib();
    _lib.setConfig(urbiplanApiConfig);
    

    class UrbiplanApiAlertDataSource extends DataSourceInterface{
        constructor(agencyIds){
            var _agencyIds = agencyIds;
            super();

            Object.defineProperty(this,'agencyIds',{
                set : function(ids){ _agencyIds = ids },
                get : function(){ return _agencyIds }
            });


        }
        getTypedObject(){
            console.log('get')
            var agencyIds = this.getAgencyIds();
            return _lib.alerts().then((urbiplanApi)=>urbiplanApi.getAlerts()).then(this.filterAlertByAgencies(agencyIds))  // returns promise on  UrbiplanApi
        }

        filterAlertByAgencies(agencyIds){
            var filterAlertByAgencyIds = urbiplanApiModel.UrbiplanApiActiveAlert.filterByAgencyIds;
            return (alerts)=>{
                return filterAlertByAgencyIds(alerts,agencyIds);
            }
        }

        setAgencyIds(ids){ this.agencyIds = ids }

        getAgencyIds(){ return this.agencyIds }

    }    


    class UrbiplanApiRouteDataSource extends DataSourceInterface{
        constructor(agencyIds){
            var _agencyIds = agencyIds;
            super();

            Object.defineProperty(this,'agencyIds',{
                set : function(ids){ _agencyIds = ids },
                get : function(){ return _agencyIds }
            });


        }
        getTypedObject(){
            console.log('get')
            var agencyIds = this.getAgencyIds();
            return _lib.routes()
                    .then((urbiplanApi)=>urbiplanApi.getRoutes())
                    .then(this.filterRouteByAgencies(agencyIds))    
                    .then(this.sorterByShortName())
        }

        filterRouteByAgencies(agencyIds){
            var filterRouteByAgencyIds = urbiplanApiModel.UrbiplanApiRoute.filterByAgencyIds;
            return (routes)=> filterRouteByAgencyIds(routes,agencyIds);
        }

        sorterByShortName(){
            var sorterByShortName = urbiplanApiModel.UrbiplanApiRoute.sorterByShortName;
            return (routes) => sorterByShortName(routes)
        }

        setAgencyIds(ids){ this.agencyIds = ids }

        getAgencyIds(){ return this.agencyIds }

    } 

    return {  UrbiplanApiAlertDataSource, UrbiplanApiRouteDataSource }

}

