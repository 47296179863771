import {View} from "./view.mjs";
import alertListTemplate from "../templates/alertlist2.njk";
import {alertListServiceProvider} from "../services/alerts/alertlist2.service.mjs";  // TEST 

export function alertListViewProvider(){


    class AlertListView extends View{
        constructor(){
            super();
            var _alertListService = alertListServiceProvider(); 

            Object.defineProperty(this,'alertListService',{
                get : function(){ return _alertListService }
            });

        }

        initView(){
            this.addTitleListener();
        }

        getContext(){
            var context = {
                alertListService : this.alertListService,
            };
            return context; 
        }

        getAlertListService(){ return this.alertListService }

        getTemplate(){ return alertListTemplate; }

        addTitleListener(){
            var document = this.getDocument();
            var node = document.querySelector('.alerts');
            node.addEventListener('click',(e)=>{
                console.log('e',e)
                console.log('e.target',e.target)
                var title = e.target.closest('.title')
                console.log('title',title)
                if (title && e.target.href){
                    window.location.href = e.target.href;
                    window.location.reload();
                }
            })
        }



    }

    return new AlertListView();
}

