
export class UrbiplanApi{
	constructor(){

	}
	
	getAlerts(){ return this.alerts; }
	
	getRoutes(){ return this.routes; }
	
	getStops(){ return this.stops;}
}