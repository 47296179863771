import {UtilsWeb, UtilsPromise} from '../services/utils/index.web.mjs';



export class View{
    constructor(){
        var _context; 
        var _document;

        Object.defineProperty(this,'document',{
            get : function(){
                if (View.isEmptyDocument(_document)) {
                    _document = this.getLayoutDoc();
                    this.initView();
                }
                return _document;
            }
        });

    }
    // functions to override 
    getContext(){  }
    getTemplate(){  }

    // private
    // -------

    getLayoutDoc(){
        var context = this.getContext();
        var template = this.getTemplate(); 
        var str = View.createViewHtmlMarkup(context, template);
        var doc = View.createViewDocument(str);
        return doc;
    }

    initView(){}

    // public
    // ------

    getDocument(){
        // return this.getLayoutDoc();
        return this.document;
        // add also all plugins in the derived class
    }


    displayView(selector = '#view-mecatran-school-schedules', replace = false){
        //this.initView();
        var doc1 = window.document;
        // var doc2 = View.cloneDocument(this.getDocument());
        var doc2 = this.getDocument();
        View.copyDocument(doc1, doc2, selector, replace);
    }

    insertView(doc, selector, replace ){
        //this.initView();
        var doc1 = doc;
        // var doc2 = View.cloneDocument(this.getDocument());
        var doc2 = this.getDocument();
        View.copyDocument(doc1, doc2, selector, replace);
    }


    static isEmptyDocument(document){
        if (!document) return true;
        if (document.childElementCount === 0) return true;
        if (document.body && (document.body.childElementCount === 0)) return true;
        return false;  
    }   



    static createViewHtmlMarkup(context, template){  
        console.log(context)
        var str = template.render(context);
        return str;
    }


    static createViewDocument(str){
        var doc = UtilsWeb.strToDocument(str);
        return doc;
    }
/*
    static displayViewLive(doc, selector, replace = false ){
        var doc1 = document;
        var doc2 = doc;
        View.copyDocument(doc1, doc2, selector, replace)
    }

    static insert(doc, selector, replace ){
        var doc1 = doc;
        var doc2 = this.getDocument();
        View.copyDocument(doc1, doc2, selector, replace )
    }
*/

    static copyDocument(doc1, doc2, selector, replace){
        doc1.head.append(doc2.head)
        var parentNode = doc1.querySelector(selector);
        if (replace){
            while( parentNode.hasChildNodes()){
                parentNode.removeChild(parentNode.lastChild);
            };
        }
        doc2.body.childNodes.forEach(node =>{ parentNode.appendChild(node)  })        
    }

    static cloneDocument(doc){
        return doc.cloneNode(true);
    }

    /*
    static displayDocLiveView(doc, selector, replace = false ){  // TO DELETE ?
        document.head.append(doc.head)
        var parentNode = document.querySelector(selector);
        if (replace){
            while( parentNode.hasChildNodes()){
                parentNode.removeChild(parentNode.lastChild);
            };
        }
        doc.body.childNodes.forEach(node =>{ parentNode.appendChild(node)  })
    }
    */

    static displayViewComponent(nodeList,selector){  // ## use fragments instead of nodelist   // ## not used ? 
        var parentNode = document.querySelector(selector);
        View.clearChildren(parentNode);
        while( nodeList.length){
            parentNode.append(nodeList[0]);
        };
    }

    static getNodes(document, selector){  // ## not used ? 
        var nodeList = document.querySelectorAll(selector);
        console.log('nodelist.length:',nodeList.length)
        return nodeList;
    }

    static getNode(document, selector){   // ## not used ? 
        var node = document.querySelector(selector);
        return node;
    }

    static clearChildren(parent){
        parent.innerHTML = '';
        /*
        while (parent.lastElementChild) {
            parent.removeChild(parent.lastElementChild);
          }
        */
    }
}


