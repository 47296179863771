import {UrbiplanApiConfig, urbiplanApiModel} from "@mecatran/urbiplan-services-lib";

// console.log('UrbiplanApiConfig', UrbiplanApiConfig)
// import {urbiplanApiConfigModel} from "@mecatran/urbiplan-services-lib-config";

// var {UrbiplanApiClientConfig, UrbiplanApiKeyStore} = urbiplanApiConfigModel;
//var {UrbiplanApiConfig} = urbiplanApiConfigModel;

//console.log('UrbiplanApiKeyStore', UrbiplanApiKeyStore)

export class Config{
    constructor(){
        var _alertProviders = [];
        var _routerConfigs = [];
        var _href;
        var _defaultAlertProviderConfigName = '';
        var _options;

		Object.defineProperty(this,'clazz',{
			get : function(){
				return Config;
			},
			enumerable : false
        });

        Object.defineProperty(this,'alertProviders',{
            set : function(a) { _alertProviders = a },
            get : function(){ return _alertProviders },
            enumerable : true
        });

        Object.defineProperty(this,'routerConfigs',{
            set : function(r){ _routerConfigs = r },
            get : function(){ return _routerConfigs }
        });

        Object.defineProperty(this,'href',{
            set : function(h){ _href = h},
            get : function(){ return _href }
        })

        Object.defineProperty(this,'defaultAlertProviderConfigName',{
            set : function(n){ _defaultAlertProviderConfigName = n },
            get : function(){ return _defaultAlertProviderConfigName }
        });

        Object.defineProperty(this,'options',{
            set : function(o){ _options = o },
            get : function(){ return _options }
        })
    }

    getAlertProviderNamedConfig(name){
        var name = (name) ? name : this.defaultAlertProviderConfigName;
        var alertProviders = this.alertProviders;
        var provider;
        var testName = (alertProvider) => {
            if (alertProvider.name  === name) {
                provider = alertProvider;
                return true;
            }
            return false; 
        }
        alertProviders.some(testName);
        if (provider && provider.config) return provider.config;
        return;
    }

    getRouterConfigs(){ 
        return this.routerConfigs;
    }

    getHrefConfig(){
        return this.href;
    }

    getOptions(){
        return this.options;
    }
}


export class AlertProvider{
    constructor(){
        var _provider, _name, _config;

        Object.defineProperty(this,'provider',{
            set : function(p){ _provider = p },
            get : function(){ return _provider },
            enumerable : true 
        });

        Object.defineProperty(this,'config',{
            set : function(c){ _config = c },
            get : function(){ return _config },
            enumerable : true 
        });

        Object.defineProperty(this,'name',{
            set : function(n){ _name = n },
            get : function(){ return _name },
            enumerable : true 
        });

    }

    getConfig(){ return this.config }

}


export class UrbiplanApiAlertConfig{  // TMP To be replaced by a shema in the urbiplanApiConfig 
    constructor(){
        var _urbiplanApiConfig = new UrbiplanApiConfig();
        var _keyStore = _urbiplanApiConfig.getKeyStore();
        var _client = _urbiplanApiConfig.getClient();
        var _alertConfig = _urbiplanApiConfig.getActiveAlertsConfig();

       Object.defineProperty(this,'instance',{
            set : function(i){ 
                _client.instance = i;
            },
            enumerable : true 
        });

        Object.defineProperty(this,'env',{
            set : function(e){ 
                _client.env = e;
             },
            enumerable : true 
        });

        Object.defineProperty(this,'key',{
            set : function(k){ 
                if (!_client.env) throw new error('client.env is empty ');
                console.log('setting the key',k, _client.env)
                _keyStore[_client.env] = k;
            },
            enumerable : true 
        });

        Object.defineProperty(this,'urbiplanApiConfig',{
            get : function(){ return _urbiplanApiConfig },
            enumerable : true 
        });

        Object.defineProperty(this,'preferredLang',{
            set: function(lang){ _alertConfig.setPreferredLang(lang)  },
            enumerable : true 
        })
    }

    getUrbiplanApiConfig(){ return this.urbiplanApiConfig }
}

export class RouterConfig{
    constructor(){
        var _test;
        var _viewConfigName;

        Object.defineProperty(this,'test',{
            set : function(t){ _test = t },
            get : function(){ return _test }
        });

        Object.defineProperty(this,'viewConfigName',{
            set: function(n){ _viewConfigName = n },
            get : function(){ return _viewConfigName }
        })
    }
    getTest(){ return this.test; }

    getViewConfigName(){ return this.viewConfigName }
}

export class HRef{
    constructor(){
        var _computeTitleUrl, _computeAlertTitleUrl;

        Object.defineProperty(this,'computeTitleUrl',{
            set : function(b){ _computeTitleUrl = b },
            get : function(){ return _computeTitleUrl }
        });

        Object.defineProperty(this,'computeAlertTitleUrl',{
            set : function(a){ _computeAlertTitleUrl = a },
            get : function(){ return _computeAlertTitleUrl }
        })
    }
    getComputeTitleUrl(){ return this.computeTitleUrl }

    getComputeAlertTitleUrl(){ return this.computeAlertTitle }
}

export class Options{
    constructor(){
        var _hasSelector = true;
        var _alertSorterType;

        Object.defineProperty(this,'hasSelector',{
            set : function(b){ _hasSelector = b },
            get : function(){ return _hasSelector }
        });

        Object.defineProperty(this,'alertSorterType',{
            set : function(f){ _alertSorterType = f },
            get : function(){ return _alertSorterType }
        });
    }

    getHasSelector(){ return this.hasSelector }

    getAlertSorterType(){ return this.alertSorterType }
}


export class AlertSorterType{
    constructor(type){
        var _typeSet = new Set(['level','publicationdate']);
        var _type = type || Array.from(_typeSet)[0];

        Object.defineProperty(this,'type',{
            set : function(t){
                if (_typeSet.has(t)) _type = t;
            },
            get : function(){ return _type }
        })
    }
    getClass(){
        var type = this.type;
        switch(type){
            case 'level':
                return urbiplanApiModel.UrbiplanApiActiveAlert.sortByLevel;
                break;
            case 'publicationdate':
                return urbiplanApiModel.UrbiplanApiActiveAlert.sortByLastPublicationDate;
                break;
        }
    }
}


/*
var urbiplanApiLib = new UrbiplanApiLib();
var urbiplanApiConfig = new UrbiplanApiConfig();  
urbiplanApiConfig.client.instance = "niort";
urbiplanApiConfig.client.env = "prod";
urbiplanApiConfig.keys.prod = "1b13707e00080a315b060d13190e5d080f14484e";
*/

/**
 * config = {
 *     alertProviders = [
 *          {
 *              provider : 'urbiplan',
 *              name : 'urbiplan-api-prod',
 *              config : {
 *                  instance : 'belfort',
 *                  env : 'prod',
 *                  keys : 'XYZ',
 *              }
 *          }
 *      ] 
 * 
 * 
 * }
 */