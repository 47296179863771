

function filterRoutesByAgencyIds(routes,agencyIds){
    var agencyIdsSet = new Set(agencyIds);
    function filter(route){
        if (!agencyIdsSet.size) return true;
        return agencyIdsSet.has(route.getAgency().getId());
    }
    return routes.filter(filter);
}

function filterAlertsByRouteIds(alerts,routeIds){
    var routeIdsSet = new Set(routeIds);
    function filter(alert){
        if (!routeIdsSet.size) return true;
        var routes = alert.getRoutes();
        var found = routes.some((route)=>{
            var routeId = route.getId();  
            return routeIdsSet.has(routeId);
        });
        return found; 
    }
    return alerts.filter(filter);
}




module.exports = { filterRoutesByAgencyIds, filterAlertsByRouteIds }

