
import {loggerFactory} from "./loggerfactory.mjs";
var logger = loggerFactory.createLogger('CACHE','info',true);

var defaultCacheSize = 3;

export default function(config){
	var size = (config.size === undefined) ? defaultCacheSize : config.size ,
		config = { size  },
		cache = []
        ;
	
	
	
    /* ---- */
    /* internals */
	
	function cacheFull(){
		return (cache.length >= config.size  )
	}
	
	function cacheLookUp(key){
		logger.debug('cachelookup:key: ',key)
		var _entry;
		if (!cache.some(function(entry){
			_entry = entry;
			return (entry.key === key);
		})) _entry = null;
		logger.debug('cachelookup:_entry: ', (!_entry ? "null" : _entry ));
		return _entry;
	}
	
	
	function cacheAddEntry(entry){
		cache.push(entry);
	}
    
    function cacheIndexOverwrite(){
        
    }
    var getNextOverwriteIndex = (function(){
        var _indexOverwrite = -1;
        return function(){
            _indexOverwrite++;
            if (_indexOverwrite >= config.size) _indexOverwrite = 0;
            return (_indexOverwrite);
        }
    })();
    
    function cacheOverwriteEntry(entry){
        var indexOverwrite = getNextOverwriteIndex();
        logger.debug('cacheOverwrite: index : ',indexOverwrite);
        cache[indexOverwrite] = entry; 
    }
	
	function cacheClear(){
		cache.length = 0;
	}
	
	/* ----- */
     /* api */
	
	function setEntry(key, timestamp, data){
		var entry = { key, timestamp, data }; 
		if (!cacheFull()) cacheAddEntry(entry);
        else cacheOverwriteEntry(entry);
	}
	
	function getEntry(key){
		var entry = cacheLookUp(key);
		//console.log('!! entry',entry)
		if (entry) return entry.data;
	}
	
	function clear(){
		cacheClear();
	}
    
    /* ---- */
   
    return { setEntry, getEntry, clear };
	
}
/*
var cache = require('./cache');
var myCache = cache({size : 1 });
myCache.setEntry('a',1000, 'XXX');
myCache.setEntry('b',2000, 'YYY');
myCache.getEntry('a');
myCache.getEntry('b')
*/