/**
api/feedinfo
NOTE:
nocache : true
*/

export default {
    id : 'feedinfo',
	endPoint : 'gtfs/feed-info',
	cache : {size : 0},  // process.env.cache ou --cache ou --nocache
}