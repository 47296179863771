import {getIdentifierProp} from "./utils.mjs";
import serializr from 'serializr'; // import the default 
//import * as serializr from 'serializr';  // import the whole module
console.log('--> serializr', serializr);

var { createModelSchema, createSimpleSchema, primitive, reference, list, object, identifier, map, mapAsArray , custom, getDefaultModelSchema, serialize, deserialize, SKIP } = serializr;
export {serializr};


/**
 * Serialize/Deserialize an object which type is determined by the specified identifier 
 @param:  identifier: used in the deserializer to identify the key that holds the type of the current object
(ex: 'banner')
@param: args: key,value object that holds the propSchema of the object being deserialized



 result serializer:
 { app:  { type: 'banner', propBAN1 : 'aa', propBAN2: 'bb' } }

result deserializer: 
App {
 app: Banner { type: 'banner', propBAN1 : 'aa', propBAN2: 'bb' }
}

the type "banner" is used to identify the Class 
 */

export var selfClassReference = function(identifier, args){
    var serializer = function(value,key,object){
        var ret = {};
        Object.assign(ret,value);
        return ret;
    }

    var deserializer = function(value,context,oldValue,done){
        var modelSchema = context.modelSchema;
        var id = value[identifier];
        var propSchema = args[id];
        propSchema.deserializer(value,done)
    }

    return custom(serializer,deserializer);
}

/**
Serialize/Deserialize a map of objects (synchronous version)
CustomMap (the objects do not need a common property)
No need to specify "type" in the objects, nor in the customProperty

result serrializer: {
  apps: {
    banner: {  propBAN1: 'bb', propBAN2: 'cc' },
    bloc: {  propBL1: 'b', propBL2: 'c' }
  }
}
----
 result deserializer: {
  apps: Map {
    'banner' => Banner {  propBAN1: 'bb', propBAN2: 'cc' },
    'bloc' => Bloc {  propBL1: 'b', propBL2: 'c' }
  }
}

*/

export function customMap(args){
	var serializer = function(value, key, obj){
		if (!(value instanceof Map)) throw new Error('value should be a map'); 
		var ret = {};
		var it = value.keys();
		var res; 
		while (!(res = it.next()).done){
			var key = res.value;
			var object = value.get(key);
			var propSchema = args[key];
			ret[key] = propSchema.serializer(object );   // il faut utilise le serializer de Banner 
		} 
		return ret;
	}
	
	var deserializer = function(value, context, oldvalue, done){	
		var map = new Map();
		var promises = [];
		var keys = Object.keys(value);
		keys.forEach( key => {
			var object = value[key]; // object to deserialize
			var propSchema = args[key]; // schema of the object 
			
			var callback = (error,deserializedValue) => {
				map.set(key,deserializedValue);
			};
			propSchema.deserializer(object, callback);
		});	
		done(null, map);
	}

	return custom(serializer, deserializer);
}


/**
 * deserializes into an object that has been initialized with the value 
 * @param: {clazz|schema} used in the deserializer only. clazz or schema that will be used to generate the object
 * @param {property} key of the object that holds the string that will be output during deserialization
 * 
result serializer:  { prop: 'abc' }

result deserializer : { prop : Clazz('abc') }

 */

 export var classConstructorParam = function(arg,property){
	 var serializer = function(value,key,obj){
		// console.log('value:',value,'obj:',obj,'key:',key)
		return value[property];
	 }
	 
	 var deserializer = function(value, context, oldValue, done){
		 var schema;
		 var error = null;
		if (arg.factory){
			schema = arg; 
		} else schema = getDefaultModelSchema(arg);

		context.value = value;
		var obj = schema.factory(context);
		done(error,obj);
	 }

	 return custom(serializer, deserializer);

 }

 /**
  * like object(), but applied to an object that has properties that are getters only (no setters )
  * @param: {clazz|schema } used in the deserializer only. clazz or schema that will be used to generate the object
  * 
  * result serializer {prop : { a : 1 }}
  * 
  * result deserializer {prop : A {a : 1 }}
  * 
  */

export var objectAssign = function(modelSchema){
	var beforeDeserialize = function (callback, jsonValue, jsonParentValue, propNameOrIndex, context, propDef) {
		context.propName = propNameOrIndex;
		callback(null,jsonValue);
	};
	var afterDeserialize = function(callback, err, newValue, jsonValue, jsonParentValue,
                propNameOrIndex, context, propDef){
		callback(null, SKIP);  // to avoid setting the property (serializr.deserialize@112)
	}
	var serializer = function(value, key, obj){
		return object(modelSchema).serializer(value,key,obj);
	}
	var deserializer = function(value, context, oldValue, done){
		var target = context.target;
		var propName = context.propName;
		
		Object.assign(target[propName], value);
		done(null,oldValue);
	}
	
	return custom(serializer,deserializer, {beforeDeserialize, afterDeserialize});	
}


/**
 * IndirectObject. Like object. Object takes a modelSchema in parameter. IndirectObject takes a lookupfunction. The modelSchema/class to use 
 * for deserialization depends on the value of another property of the current Schema. 
 * @param : lookupFn, lookupfunction. Takes a value in argument that will be used to return a class. 
 * @param: prop : optional. If not provided, it looks for the value of the identifier of the current schema 
 
 result serializer : { x : 'a',  y: { m, n, o }} 
 
 schema : props : { x: true, y : IndirectObject(lookupFunction('x') }   the lookupFunction, looks at the value of 'x' (or the identifier of the schema 
 if not provided), and returns the appropriate schema to use. 
 
 result deserializer: Clazz{ x : 'a', y : A { m,n,o } }
 
*/
export var indirectObject = function(lookupFn, prop){
    var serializer = (value, key, obj) => {
        console.log('value',value,'key',key,'obj',obj);
		// TODO
        return ;
    }
    var deserializer = (value, context, oldValue, callback) => {
        var id = getIdentifierProp(context.modelSchema);
        var val = context.json[prop || id];
        var model = lookupFn(val)
        return object(model).deserializer(value,callback,context);  
       
        
    }
    return custom(serializer,deserializer)
}




/**
 * IndirectReference. Like Reference but the json value provides an object which identifier is used a a reference to another object
 * @param: modelSchema (the modelSchema of the json value holding the reference and of the object that will replace in the deserialize
 * @param: optional lookupFn
 * @param: optional additionaArgs { identifier : xxx } 
 
 result serializer : 'abc' 
 
 result deserializer: Clazz{ id: 'abc', prop: 'kyv' }
*/
export var indirectReference = function (target, lookupFn, additionalArgs){
    if (typeof lookupFn === "object" && additionalArgs === undefined) {
        additionalArgs = lookupFn
        lookupFn = undefined
    }
	var ref = reference(target,lookupFn, additionalArgs);
	
	var serializer = function(value, key, obj){
		var modelSchema = getDefaultModelSchema(target);
		var childIdentifierAttribute = getIdentifierProp(modelSchema);
		var newIdentifier = additionalArgs.identifier;
		var val = value[childIdentifierAttribute];
		// console.log('newidentifier:',newIdentifier,'val:',val);
		var ret = {}; 
		ret[newIdentifier] = val;
		return ret;
	}
	
	var deserializer =  function(value, context, oldValue, callback){
		var modelSchema = getDefaultModelSchema(target);
		var childIdentifierAttribute = getIdentifierProp(modelSchema);
		var val = value[childIdentifierAttribute] || value[additionalArgs.identifier];
		ref.deserializer(val, callback, context);
	}
	
	return custom(serializer, deserializer);
}



/**
 * simplePropSchema. Allows to convert properties into a simpleSchema. 
 * 
 * @param: modelSchema 
 * @param: addionalArgs 

 
 result serializer : 
 {
	 apiPublication : { dateTime : date() }
 }

 result deserializer: 
 { 
	 apiPublication : { dateTime:  2020-12-18T00:00:00.000Z }
 }
*/

export var simplePropSchema = function (props){
	var schema = createSimpleSchema(props);
	var serializer = function(value,key,obj){
		return serialize(schema, value);
	};
	var deserializer =  function(value, context, oldValue, callback){
		var val = deserialize(schema, value);
		callback(null, val);
	}
	
	return custom(serializer, deserializer);
}


/**
 * Serialize/Deserialize a function 

	Ex: 
	function add(x, y, z) { return  x + y + z; }
	
	result serializer 
		{ add: 'function add(x,y,z){ return x + y +z;}' }
	
	result deserializer
		{ add : function add(x,y,z){ return x + y + z } }

 */
export var funct = function(){
	
	var serializer = function(value, key, obj){
		return value.toString();
	}
	
	var deserializer = function(value, context, oldValue, done){
		var deserializedValue = new Function('return ' + value)();
		done(null, deserializedValue);
	}
	
	return custom(serializer, deserializer);
}