import { SelectorComponentInterface } from "@mecatran/selector-component-interface";
import util from 'util';


export class UtilsWeb{
    constructor(){}
    static getNodeList(str){ 
        var parser = new DOMParser();
        var nodeList = parser.parseFromString(str,'text/html').body.childNodes;
        return nodeList;
    }

    static getDocumentFragment(str){
        var fragment = document.createDocumentFragment();
        var nodeList = UtilsWeb.getNodeList(str);
        while(nodeList.length){
            fragment.append(nodeList[0]);
        }
        return fragment;
    }

    static printWeb(str){ 
        var nodeList = UtilsWeb.strToNodeList(str);
        if (nodeList.length > 1 ) return nodeList;
        else return nodeList[0];   
    }

    static getDocument(str){ 
        var parser = new DOMParser();
        var document = parser.parseFromString(str,'text/html');
        return document;
    }

}


/**
 * templates – A hash of templates to be used when rendering the dataset. 
 * Note a precompiled template is a function that takes a JavaScript object as its first argument and returns a HTML string.

notFound – Rendered when 0 suggestions are available for the given query. 
Can be either a HTML string or a precompiled template. If it's a precompiled template, the passed in context will contain query.

pending - Rendered when 0 synchronous suggestions are available but asynchronous suggestions are expected. Can be either a HTML string or a precompiled template. If it's a precompiled template, the passed in context will contain query.

header– Rendered at the top of the dataset when suggestions are present. Can be either a HTML string or a precompiled template. If it's a precompiled template, the passed in context will contain query and suggestions.

footer– Rendered at the bottom of the dataset when suggestions are present. Can be either a HTML string or a precompiled template. If it's a precompiled template, the passed in context will contain query and suggestions.

suggestion – Used to render a single suggestion. 
If set, this has to be a precompiled template. The associated suggestion object will serve as the context. 
Defaults to the value of display wrapped in a div tag i.e. <div>{{value}}</div>.
 */

export class Templates{
    constructor(dataset){
        var _notFound, _pending, _header  = '', _footer = '', 
            _suggestion // = Templates.defaultTemplateSuggestion
        ;

        Object.defineProperty(this,'header',{
            set : function(h){ _header = h },  
            get : function(){ return _header }
        });

        Object.defineProperty(this,'footer',{
            set : function(f){ _footer = f },  
            get : function(){ return _footer }
        });

        Object.defineProperty(this,'suggestion',{
            set : function(s){ _suggestion = s },  
            get : function(){ return _suggestion }
        });
/*
        Object.defineProperty(this,'display',{
            set : function(s){ _display =s },
            get : function(){ return _display}
        })
*/  
    }

    // ==========
    // Tests
    // ==========
    hasHeader(){ return this.header }



    static defaultTemplateSuggestion(query,suggestion, dataset){   // ## TODO remove dependency to dataset
        var display = dataset.display;
        var suggestionStr; 
        if (util.isFunction(display)) suggestionStr = display(suggestion);
        else suggestionStr = suggestion[dataset.display];
        var suggestionItem = document.createElement('div');
        suggestionItem.innerHTML = '<strong>' + suggestionStr.substr(0,query.length) + '</strong>';  // ## Use match instead ! the letters to hightlight are not necessarily the first
        suggestionItem.innerHTML += suggestionStr.substr(query.length);   
        return suggestionItem;
    }
    
}


/**
 * @param source  https://github.com/twitter/typeahead.js/blob/master/doc/jquery_typeahead.md#datasets
 * The backing data source for suggestions. Expected to be a function with the signature (query, syncResults, asyncResults). 
 * syncResults should be called with suggestions computed synchronously and asyncResults should be called with suggestions 
 * computed asynchronously (e.g. suggestions that come for an AJAX request). 
 * source can also be a Bloodhound instance. Required.
 */

export class Dataset{
    constructor(datasetName, limit){
        var _async = false ,
            _sync = false,
            _name = datasetName,
            _limit = (limit && limit>=0) ? limit : undefined,
            _display = (suggestion)=>{
                if (util.isString(suggestion)) return suggestion;
                else return JSON.stringify(suggestion); 
            },
            _templates = new Templates(this),
            _dataArray,
            _getQueriableValues = Dataset.queriableValues(),
            _source 
        ;

/**
The backing data source for suggestions. 
Expected to be:
 - an array or strings or 
 - a function with the signature (query, syncResults, asyncResults) or a syncResults should be called with suggestions computed synchronously and asyncResults should be called with suggestions computed asynchronously (e.g. suggestions that come for an AJAX request). 
source can also be a suggestion engine complying with the tt_Adapter interface. Required.
 */

 /** signature (query, results) */   // NOTE regardless of sync or async (and remove the async flag )
        Object.defineProperty(this,'source',{
            set : function(source){ 
                /*
                if (util.isArray(source)){ 
                    _source = this.setSuggestionEngine();
                    return;
                }
                */
                _source  = source;
             },
            get : function(){ return _source },
            enumerable : true 
        });

        Object.defineProperty(this,'dataArray',{
            set : function(d){
                if (util.isArray(d)){
                    _dataArray = d;
                }
             },
            get : function(){ 
                if (!_dataArray) throw new Error('Dataset: dataArray has not been defined');
                return _dataArray ;
            }
        });

        Object.defineProperty(this,'getQueriableValues',{
            value : function(object){ return _getQueriableValues(object) }
        });

        Object.defineProperty(this,'setQueriableFields',{
            value : function(p){ 
                if (!p) return;
                if (util.isFunction(p)){ _getQueriableValues = p; return }
                if (util.isArray(p)){ 
                    var props = p;
                    if (!Dataset.isArrayOfFlatObjects(_dataArray)) console.log('setQueriableFields can be an Array only for FlatObject dataArray');
                    _getQueriableValues = Dataset.queriableValues(props);
                }
            }
        })

        Object.defineProperty(this,'sync',{
            set : function(b){ 
                if (util.isBoolean(b)) _sync = b;
            },
            get : function(){ return _sync },
            enumerable : false 
        });



        Object.defineProperty(this,'async',{
            set : function(b){ _async = b },
            get : function(){ return _async },
            enumerable : false 
        });

        Object.defineProperty(this,'name',{
            set : function(n){ _name = n },
            get : function(){ return _name },
            enumerable : false 
        });

        Object.defineProperty(this,'limit',{
            set : function(l){ _limit = l },
            get : function(){ return _limit  },
            enumerable : true 
        });
/**
 * For a given suggestion, determines the string representation of it. 
 * This will be used when setting the value of the input control after a suggestion is selected. 
 * Can be either a key string (ex: property "team" of object) or a function that transforms a suggestion object into a string. 
 * Defaults to stringifying the suggestion.
 */
        Object.defineProperty(this,'display',{
            set : function(d){ _display = d; _templates.display = d; },
            get : function(){ return _display },
            enumerable : true 
        });

        Object.defineProperty(this,'templates',{
            get : function(){ return _templates }
        });
  
    

    }

    getName(){ return this.name }
    
    getSource(...params){ return this.source(...params); }  // ex: calls DefaultSuggestionEngine(dataArray)



    getTemplates(){ return this.templates }

    getDataArray(){
        return this.dataArray;
    }

    getDataProps(){
        return this.dataProps;
    }



    getDisplayStr(suggestion){
        if (!suggestion) return " ";
        var str;
        if (util.isFunction(this.display)) str = this.display(suggestion);
        else str = suggestion[this.display];    
        return str;   
    }

    getHeaderFragment(){
        var str = ''; //  = this.getName();
        var headerTemplate = this.templates.header; 
        if (headerTemplate){
            if (headerTemplate.render)  str = this.templates.header.render();
            else str = headerTemplate;
        }
        return UtilsWeb.getDocumentFragment(str);
    }

    getSuggestionFragment(query, suggestion){
        var str = `${this.getDisplayStr(suggestion)}`;
        if (this.templates.suggestion){
            var context = { query, suggestion };
            str = this.templates.suggestion.render(context);
        }
        return UtilsWeb.getDocumentFragment(str);
    }



    // public 
    // ------
    /*
    setDataArray(d){
        this.dataArray = d; 
    }
    */

    setDataProps(d){
        this.dataProps = d;
    }

    setName(n){
        this.name = n;
    }

    /*
    setLimit(l){  // should be done before setAsyncDataSource or setSyncDataSource
        if (l>0) this.limit = l;
    }
    */

    // @public
    setSyncDataSource(dataArray, queriableFields, suggestionEngine){
        this.sync = true;
        this.dataArray = dataArray; 
        this.setQueriableFields(queriableFields);
        this.setSuggestionEngine(suggestionEngine);
    }

    // @public
    setAsyncDataSource(suggestionEngine){
        this.async = true;
        this.setSuggestionEngine(suggestionEngine);
    }


    /**
     * 
     * @param suggestionEngine function that takes a dataArray and returns a function (query,callback);
     */
    // @private
    setSuggestionEngine(suggestionEngine){
        var suggestionEngine = suggestionEngine || Dataset.DefaultSuggestionEngine;
        var dataArray, getQueriableValues; 
        if (this.isSync()){
            var dataArray = this.getDataArray();
            var getQueriableValues = this.getQueriableValues;
            var limit = this.limit;
            this.source = suggestionEngine(dataArray, getQueriableValues, limit);
        } else if (this.isAsync()) this.source = suggestionEngine(limit);
    }

    /**
     * 
     * @param finder function that takes a dataArray and returns a function (suggestion, index) => boolean 
     */

    find(finder, callback){
        if (this.isSync()){
            var finder = (finder) ? finder : Dataset.findFirstSuggestion;
            if (util.isString(finder)){
                var query = finder;
                finder = Dataset.FindFromSuggestionEngine(query);
                finder(this.source,callback); 
                return;
            }
            finder(this.dataArray, this.getQueriableValues, this.source, callback);
            return;
        } else if (this.isAsync()){  // this.isAsync
            if (!finder) throw new Error('Dataset.find. Finder function or query is mandatory for async');
            if (util.isString(finder)){
                var query = finder;
                finder = Dataset.FindFromSuggestionEngine(query);
            }
            finder(this.source, callback);
            return;
        } else throw new Error('Dataset.find. Use set(A)SyncDataSource first')
    }

    // tests
    isAsync(){ return this.async }
    isSync(){ return this.sync }
    

    static findFirstSuggestion(dataArray, getQueriableValues, suggestionEngine, callback){
        callback(dataArray[0]);
    }

    static FindFromSuggestionEngine(query){
        return (suggestionEngine, callback ) => {
            var cb = (data) => {
                console.log(data);
                callback(data[0]);
            }
            suggestionEngine(query, cb)
        }
    }


    /* only for sync data */ 
    static DefaultSuggestionEngine(dataArray, getQueriableValues, limit){
        var search = (query, callback) => {
            var query = query.trim(); 
            //if (!query.length) { callback([]); return; }
            var matches = [];
            for (var i = 0; i < dataArray.length; i++) {  
                var queriableValues = getQueriableValues(dataArray[i]);
                var someFn = function(value){
                    return (value.substr(0, query.length).toUpperCase() == query.toUpperCase()) 
                }
                if (queriableValues.some(someFn)) matches.push(dataArray[i]);
            }
            if (limit) matches.splice(limit);
            callback(matches)
            //return matches;
        }
        return search; 
    }
    

    static isArrayOfString(dataArray){
        var everyFn = function(data){
            return util.isString(data)
        }
        return dataArray.every(everyFn);
    }

    static isArrayOfObjects(dataArray){
        if (!dataArray.length) return false;
        var everyFn = function(data){
            return util.isObject(data)
        }
        return dataArray.every(everyFn);
    }

    static isArrayOfFlatObjects(dataArray){
        if (!dataArray.length) return false;
        return dataArray.every(Dataset.isFlatObject);
    }

    static isFlatObject(object){
        var values = Object.values(object);
        var everyFn = (value)=> (util.isString(value) || util.isNumber(value));
        return values.every(everyFn);
    }

    static queriableValues(props){
        return (object) =>{
            if (!util.isObject(object)) return [object];
            var properties = props || Object.keys(object);
            var values = Object.entries(object).filter((keyval)=> properties.includes(keyval[0])).map(keyval=>keyval[1]);
            return values; 
        }
    }
    

}


export class Dropdown extends SelectorComponentInterface{
    constructor(id, datasets, placeholder, type = 'dropdown'){
        super();
        var typeMap = new Map([['autocomplete','text'],['dropdown','button']]);
        var _type, _inputType;
        if (typeMap.has(type)){
            _type = type;
            _inputType = typeMap.get(type);
        }
        var //_id = id,  // dropdownId 
            _DOMNode,
            _placeholder = placeholder,
            _datasets = [],
            _uniqueId = Math.round(Math.random() * 100000),
            _currentFocus = -1,
            _selectEventHandlersMap = new Map(),
            _emptyInputEventHandlersArray = [ this.hideClearButton.bind(this)],
            _id = id || _uniqueId
        ;
/*
        Object.defineProperty(this,'id',{
            get : function(){ return _id }
        });
*/

        Object.defineProperty(this,'id',{
            get : function(){ return _id },
        });

        Object.defineProperty(this,'type',{
            get : function(){ return _type }
        });

        Object.defineProperty(this,'inputType',{
            get : function(){ return _inputType }
        });

        Object.defineProperty(this,'placeholder',{
            get : function(){ return _placeholder }
        });


        Object.defineProperty(this,'DOMNode',{  
            set : function(d){ _DOMNode = d },
            get : function(){ return _DOMNode }
        })



        Object.defineProperty(this,'addDataset',{
            value : function(dataset){ 
                if (dataset.constructor === Dataset ) _datasets.push(dataset) },
        })

        Object.defineProperty(this,'datasets',{
            get : function(){ return _datasets },
        });

        Object.defineProperty(this,'currentFocus',{
            set : function(c){ _currentFocus = c},
            get : function(){ return _currentFocus },
        });

        Object.defineProperty(this,'selectEventHandlersMap',{
            get : function(){ return _selectEventHandlersMap }
        });

        Object.defineProperty(this,'selectEmptyInputEventHandlersArray',{
            get : function(){ return _emptyInputEventHandlersArray }
        })

        // runtime
        this.init(datasets);

    }
    // =======
    // getters 
    // -------

    getId(){ return this.id }

    getType(){ return this.type }

    getInputType(){ return this.inputType }

    getDatasets(){ return this.datasets; }

    getDataset(datasetName){
        var datasets = this.getDatasets();
        if (!datasetName) return datasets[0];
        var finder = function(dataset){
            return (dataset.getName() === datasetName )
        }
        return datasets.find(finder)
    }
    
    getInputId(){ return `dropdown-input-${this.uniqueId}`}  // TO REMOVE ?

    getSuggestionListId(){ return `dropdown-list-${this.uniqueId}` }  // TO REMOVE ?


    getInput(){ 
        var node = this.getDOMNode();
        return node.querySelector('input');
    }

    getClearButton(){
        var node = this.getDOMNode();
        return node.querySelector('.clear')
    }

    getPlaceholder(){ return this.placeholder }

    getSelectEventHandlersMap(){ return this.selectEventHandlersMap }

    getEmptyInputEventHandlersArray(){ return this.selectEmptyInputEventHandlersArray }

    /**
     * 
     * @param query 
     * @param callback returns dataset, suggestion 
     */
    getDatasetSuggestions(query,callback){
        var datasets = this.getDatasets();  
        datasets.forEach(dataset =>{
            /*
            var onSuggestions = (suggestions) => {
                callback(dataset, suggestions);
            }*/
            dataset.getSource(query, (suggestions)=>{
                callback(dataset, suggestions);
            });
        });
    }

    // ========================
    // interface implementation
    // -------------------------


    getDOMNode(){
        return this.DOMNode;
    }

    getCurrentSelection(){ 
        var input = this.getInput();
        var dataObject = this.getDataObject(input);
        var selection = (dataObject) ? dataObject.suggestion : {};
        return selection;
    }

    getCurrentDatasetName(){
        var input = this.getInput();
        var dataObject = this.getDataObject(input);
        var datasetName = (dataObject) ? dataObject.datasetName : '';
        return datasetName;
    }
	

    // TMP to relocate

    setDataObject(node, suggestion, datasetName){
        node.data = { suggestion, datasetName }   // TMP 
    }

    getDataObject(node){
        return node.data; // TMP 
    }


	getInputState(){
		var input = this.getInput();
		return input.state; 
	}

    // =========
    // modifiers
    // ---------


    setInputValue(suggestion, datasetName ){  // TO REVIEW suggestion only ? use display function 
        var input = this.getInput();
        var dataset = this.getDataset(datasetName)
        input.value = dataset.getDisplayStr(suggestion); 
        if (input.value.length) this.showClearButton();   
        this.setDataObject(input, suggestion, datasetName);
    }

	
	setInputState(){
		var input = this.getInput();
		input.state = (input.value) ? 1 : 0;
	}


    setDOMNode(d){ this.DOMNode = d }

    /**
     * returns 
     * @param datasetName 
     * @param finder optional function (check Dataset.find)
     */
	setDefaultValue(finder, datasetName){
        var dataset = this.getDataset(datasetName);
        var callback = (suggestion) => {
            this.setInputValue(suggestion, datasetName);
        }
        dataset.find(finder, callback);
    }


    // =================
    // private functions
    // ----------------- 

    init(datasets){
        if (datasets) datasets.forEach(dataset => { this.addDataset(dataset)})

        var dropdownDOMNode = this.createDropdown(this.getPlaceholder());
        this.setDOMNode(dropdownDOMNode);
        this.setDropdownListeners();  
    }


    createDropdown(placeholder=''){  
        var dropdown = document.createElement('div');
        dropdown.setAttribute('class', `dropdown ${this.getType()}`);
        dropdown.setAttribute('id',this.getId());
        var input = this.createInput(placeholder);
        dropdown.appendChild(input);
        var clearButton = this.createClearButton();
        dropdown.appendChild(clearButton);
        return dropdown;
    }

    createInput(placeholder){
        var input = document.createElement('input');
        input.type = this.getInputType();
        // input.id= this.getInputId();  // TO REMOVE ?
        // input.name = this.getInputId();
        input.placeholder = placeholder;
        return input;       
    }

    createClearButton(){
        var clearButton = document.createElement('button');
        clearButton.setAttribute('class','clear');
        //clearButton.setAttribute('style','display:none')
        var clearIconStr = `<svg viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>`;
        var clearIcon = UtilsWeb.getNodeList(clearIconStr)[0];
        clearButton.appendChild(clearIcon);
        return clearButton;
    }

    hideClearButton(){
        var clearButton = this.getClearButton();
        clearButton.style.display = "none";
    }

    showClearButton(){
        var clearButton = this.getClearButton();
        clearButton.style.display = "";       
    }

    createSuggestionItem(dataset, query, suggestion){  
        var div = document.createElement('div');
        div.classList.add('selectable')
        var fragment = dataset.getSuggestionFragment(query, suggestion);
        div.appendChild(fragment);
        return div;
    }

    createHeaderItem(dataset){   
        return dataset.getHeaderFragment();
    }

    createDatasetList(dataset){
        var div = document.createElement('div');
        div.classList.add(dataset.name)
        return div;      
    }

    createSuggestionItemsList(query){  
        var suggestionItemsList = document.createElement('div');
        suggestionItemsList.setAttribute('id', this.getSuggestionListId() );
        suggestionItemsList.setAttribute('class', 'dropdown-list');
        var that = this;
        var datasetList;
        
        this.getDatasetSuggestions(query,(dataset,suggestions)=>{
            if (!datasetList) {
                datasetList = this.createDatasetList(dataset);
                suggestionItemsList.append(datasetList)
            }
            //if (dataset.templates.hasHeader()){
                var header = this.createHeaderItem(dataset);
                datasetList.append(header);
            //}
            suggestions.forEach(suggestion =>{
                var suggestionItem = this.createSuggestionItem(dataset, query, suggestion);
                // var val = dataset.getDisplayStr(suggestion);
                this.setDataObject(suggestionItem, suggestion, dataset.name);
                datasetList.append(suggestionItem);
                // TODO add datasource footer
            });  
            /*
            var footer = this.createFooterItem(dataset);
            suggestionItemsList.append(footer);  
            */      
        });
        return suggestionItemsList;
    }


    closeAllLists(elmnt) {
        /*close all lists in the document,
        except the one passed as an argument:*/
        var suggestionItemsLists = document.querySelectorAll('.dropdown-list');
        var input = this.getInput();
        for (var i = 0; i < suggestionItemsLists.length; i++) {
          if (elmnt != suggestionItemsLists[i] && elmnt != input) {
            suggestionItemsLists[i].parentNode.removeChild(suggestionItemsLists[i]);
          }
        }
    }
    addActive(x) {
        /*a function to classify an item as "active":*/
        if (!x) return false;
        /*start by removing the "active" class on all items:*/
        this.removeActive(x);
        if (this.currentFocus >= x.length) this.currentFocus = 0;
        if (this.currentFocus < 0) this.currentFocus = (x.length - 1);
        /*add class "dropdown-active":*/
        x[this.currentFocus].classList.add("dropdown-active");
    }
    removeActive(x) {
        /*a function to remove the "active" class from all dropdown items:*/
        for (var i = 0; i < x.length; i++) {
          x[i].classList.remove("dropdown-active");
        }
    }
    
    // =========
    // listeners
    // --------- 

    setDropdownListeners(){ 
        var dropdown = this.getDOMNode();
        dropdown.addEventListener('input',this.onDropdownEventRouter.bind(this));
        dropdown.addEventListener('keydown',this.onDropdownEventRouter.bind(this));
        dropdown.addEventListener('click',this.onDropdownEventRouter.bind(this));
        dropdown.addEventListener('mouseover',this.onDropdownEventRouter.bind(this));
        dropdown.addEventListener('selectEvent',this.onDropdownEventRouter.bind(this));
        dropdown.addEventListener('emptyInputEvent',this.onDropdownEventRouter.bind(this));
    }

    setDocumentListener(){
        document.addEventListener('click',this.onDocumentEvent.bind(this));
    }
	

	

    // =====
    // tests
    // -----

    getClosestSuggestionItem(elmnt){
        return elmnt.closest('.selectable');
    }

    isSuggestionItem(elmnt){
        var node = this.getClosestSuggestionItem(elmnt);
        return (node && node.classList.contains('selectable'))
        // return (elmnt.classList.contains('selectable'))
    }

    getClosesClearButton(elmnt){
        return elmnt.closest('.clear');
    }

    isClearButton(elmnt){
        var node = this.getClosesClearButton(elmnt);
        return (node && node.classList.contains('clear'))
    }

    isInput(elmnt){
        return (elmnt.tagName === 'INPUT')
    }

    // ==============
    // event handlers
    // --------------

    onDropdownEventRouter(e){  // ## change name onEvent
        // console.log('handler doc input',e); window.test = e; console.log('e.target',e.target,'e.target.id',e.target.id,'uniqueId',)
        e.stopPropagation();
        switch(e.type){
			
            case 'input': 
                if (e.target && this.isInput(e.target)) this.onInputInput(e);
                break;
				
            case 'keydown':
                 if (e.target && this.isInput(e.target)) this.onInputKeyDown(e);
                //if (e.target && (e.target.matches(this.getInputId()))) this.onInputKeyDown(e);
                break;
                
            case 'click':
                if (e.target && this.isSuggestionItem(e.target)) this.onSuggestionItemClick(e);
                else if (e.target && this.isInput(e.target)) this.onInputClick(e);
                else if (e.target && this.isClearButton(e.target)) this.onClearButton(e);
                //else this.onDocumentClick(e);
                break;
            case 'mouseover':
                if (e.target && this.isSuggestionItem(e.target)) this.onMouseover(e);
                break;
            case 'selectEvent':
                if (e.target ) this.onSelectEvent(e); // to complete 
                break;
            case 'emptyInputEvent':
                if (e.target) this.onEmptyInputEvent(e);
                break;

        }
    }

    onDocumentEvent(e){
        if (e.type === 'click') this.onDocumentClick(e);   // TOREMOVE
    }

    onDocumentClick(e){    // TOREMOVE 
        console.log('closeAllLists')
        this.closeAllLists(e.target);
    }

    onSelectEvent(e){
        var handlersMap = this.getSelectEventHandlersMap();
        var handler;
        var { suggestion, datasetName } = e.detail;
        if (handlersMap.has(datasetName)) handler = handlersMap.get(datasetName);
        if (handler) handler(suggestion);   
    }

    onEmptyInputEvent(e){
        var input = this.getInput();
        var suggestionItemsList = this.createSuggestionItemsList("");  // TMP
        input.parentNode.appendChild(suggestionItemsList);  // TMP 

        var array = this.getEmptyInputEventHandlersArray();
        if (array.length)  array.forEach(handler => handler());
    }

    onSuggestionItemClick(e){
        console.log(e.target);
        var node = this.getClosestSuggestionItem(e.target);
        var dataObject = this.getDataObject(node);
        var {suggestion, datasetName } = dataObject;
        this.setInputValue(suggestion, datasetName);
        this.emitSelectEvent(suggestion, datasetName);
        this.closeAllLists();
    }

    onClearButton(e){
        var input = this.getInput();
        this.setInputValue(null, null);
        this.emitEmptyInputEvent();
    }

    onMouseover(e){
        /*
        if (e.target.classList.contains('selectable') ){
            this.removeActive(e.target);
            this.addActive(e.target)
        }
        */
    }

    onInputKeyDown(e){
        console.log('oninputkeydown',e);
		if ((e.keyCode === 8) || (e.keyCode === 46)){  // 8 (backspace) 46 (delete)
			var input = e.target;
			this.setInputState();
		}
        var suggestionItems;
        var suggestionItemsList = document.getElementById(`dropdown-list-${this.uniqueId}`);  //TMP
        if (suggestionItemsList){
            //suggestionItems = suggestionItemsList.getElementsByTagName('div.selectable'); 
            suggestionItems = suggestionItemsList.querySelectorAll('div.selectable'); 
            if (e.keyCode == 40) {     // arrow Down pressed 
                this.currentFocus++;
                this.addActive(suggestionItems); 
            } else if (e.keyCode == 38) { // arrow Up pressed 
                this.currentFocus--;
                this.addActive(suggestionItems);
            } else if (e.keyCode == 13) { // enter key pressed 
                  
                e.preventDefault();  // prevent bubbling
                if (suggestionItems && this.currentFocus > -1) suggestionItems[this.currentFocus].click(); // simulate a click on the active item 
            }
        } 
    }
	
	/*
	onInputClick(e){
        var input = this.getInput();
        var query = input.value;
		
        this.closeAllLists();  
        this.currentFocus = -1;
        //if (!query.length) { return false;}
        
        var suggestionItemsList = this.createSuggestionItemsList(query);
        input.parentNode.appendChild(suggestionItemsList); 
	}
	*/
	onInputClick(e){
        var input = this.getInput();
        this.closeAllLists();  
        this.currentFocus = -1;
        //if (!query.length) { return false;}
        var query;
        switch(this.getType()){
            case 'dropdown':
                query = '';
                break;
            case 'autocomplete':
                query = input.value;
                break;
        }

        
        var suggestionItemsList = this.createSuggestionItemsList(query);  // TMP createSuggestionItemsList(query)
        input.parentNode.appendChild(suggestionItemsList);   
	}

    onInputInput(e){
		var previousState = this.getInputState();
        var input = this.getInput();
        var query = input.value;
		this.setInputState();
		var currentState = this.getInputState();
		if (previousState === 1 && currentState ===0 ){
			this.emitEmptyInputEvent();
			return;
		} 
        if (query.length) this.showClearButton();
		
        this.closeAllLists();  
        this.currentFocus = -1;
        //if (!query.length) { return false;}
        
        var suggestionItemsList = this.createSuggestionItemsList(query);
        input.parentNode.appendChild(suggestionItemsList);         
    }

    // ======
    // events
    // ------
    emitSelectEvent(suggestion, datasetName){
        var event = new CustomEvent('selectEvent', { 'detail': {suggestion, datasetName }, bubbles: true });  // https://developer.mozilla.org/fr/docs/Web/API/Event/Event
        this.getDOMNode().dispatchEvent(event);
    } 
	
	emitEmptyInputEvent(){
		var event = new CustomEvent('emptyInputEvent', {'detail' : null, bubbles: true });
		this.getDOMNode().dispatchEvent(event);
	}

    getSelectEvent(event){
        return event.detail;
    }


    setItems(){}
    OnItemSelected(){}
    selectItem(){}
    getItem(){}


    // public functions 
    // ----------------
    addSelectEventHandler(datasetName,handler){
        var handlersMap = this.getSelectEventHandlersMap();
        handlersMap.set(datasetName, handler);
    }

    addEmptyInputEventHandler(handler){
        var array = this.getEmptyInputEventHandlersArray();
        array.push(handler)
    }

}
