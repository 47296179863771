import {UrbiplanApiRoute} from "@mecatran/urbiplan-services-lib";

export function widgetControllerProvider(multipleDataSource, mainView, urbiplanApiRouteDataSource){


    class WidgetController{
        constructor(){

            Object.defineProperty(this,'multipleDataSource',{
                get : function(){ return multipleDataSource }
            });

            Object.defineProperty(this,'selectorView',{
                get : function(){ return mainView.getSelectorView() }
            });

            Object.defineProperty(this,'alertListService',{
                get : function(){ return mainView.getAlertListView().getAlertListService() }
            })
            
        }

        updateAlertListService(alerts){
            this.alertListService.setAlerts(alerts);
        }

        addSelectorRoutes(routes){
            this.selectorView.addRoutes(routes);
        }

        getRoutesForAlerts(alerts){  // returns list of routes
            var routes = []; 
            var routeIds = new Set([]);
            alerts.forEach((alert)=>{
                alert.getRoutes().forEach((route)=>{
                    if (!routeIds.has(route.getId())){
                        routes.push(route);
                        routeIds.add(route.getId())
                    }
                });
            });
            return routes;           
        }

        configureRouteSelector(){
            var selectorView = this.selectorView;
            var serv = selectorView.onSelectEvent(); 
            serv.addHandler(selectorView.getRouteDatasetName(), this.onRouteSelect.bind(this));
            var serv = selectorView.onEmptyInputEvent(); 
            serv.addHandler(this.onRouteEmptyInput.bind(this));
        }

        onRouteSelect(selection){
            console.log('routeSelected', selection)
            var routeIds = [selection.getId()];
            var alertListService = mainView.getAlertListView().getAlertListService();
            alertListService.setRouteIds(routeIds);
            mainView.displayView('#view-mecatran-alerts .alerts',true)
        }

        onRouteEmptyInput(){
            console.log('empty input, should reload')
            var alertListService = mainView.getAlertListView().getAlertListService();
            alertListService.setRouteIds([]);
            mainView.displayView('#view-mecatran-alerts .alerts',true)
        }

        run(){

/*
            this.multipleDataSource.get().then((urbiplanApi)=>{
                var alerts = urbiplanApi.getAlerts();
                var routes = urbiplanApi.getRoutes();
                this.updateAlertListService(alerts);
                if (mainView.getOptions().getHasSelector()){
                    this.selectorView.displayView('#view-mecatran-alerts');
                    this.addSelectorRoutes(routes);
                    this.configureRouteSelector(); // TMP 
                }
                mainView.displayView('#view-mecatran-alerts');
            });
            */
            
           urbiplanApiRouteDataSource.get().then((routes)=>{
                if (mainView.getOptions().getHasSelector()){
                    this.selectorView.displayView('#view-mecatran-alerts');
                    this.addSelectorRoutes(routes);
                    this.configureRouteSelector(); // TMP 
                }
           });
            
            this.multipleDataSource.get().then((alerts)=>{
                this.updateAlertListService(alerts);
                return alerts;
            }).then((alerts)=>{
                /*
                if (mainView.getOptions().getHasSelector()){
                    this.selectorView.displayView('#view-mecatran-alerts');
                    this.addSelectorRoutes(alerts);
                    this.configureRouteSelector(); // TMP 
                }
                */
                mainView.displayView('#view-mecatran-alerts');
            });
            

        }
    }

    return new WidgetController();
}