






export class UrbiplanApiServerInfo{};

export class UrbiplanApiFeedInfo{};

export * from "./urbiplanapiagency.mjs";


export class UrbiplanApiPattern{};

export * from './urbiplanapiroute.mjs'; 

export class UrbiplanApiServedStop{};

export class UrbiplanApiDeparture{};

export * from './urbiplanapistop.mjs';

export class UrbiplanApiFare{};

export class UrbiplanApiRealtimeStop{};

export class UrbiplanApiRealtimeVehiculesStop{};

export * from './urbiplanapialert.mjs';

export class UrbiplanApiLayerKml{};

export * from './urbiplanapi.mjs';

