// import {loggerFactory} from "@mecatran/logger-factory";
import {serializr,
    indirectReference, simplePropSchema } from '@mecatran/serializr-extensions';

// var logger = loggerFactory.createLogger('URBAPI-SCHEMA','info',false);
// import serializr from 'serializr';

console.log('----\n urbiplan-services-lib');
console.log('indirectReference:', indirectReference)
console.log('serializr',serializr);

var {   createModelSchema,
    createSimpleSchema,
    primitive,
    reference,
    date, raw, list, object,
    optional, alias,
    identifier, map, mapAsArray ,
    custom,
    getDefaultModelSchema,
    setDefaultModelSchema,
    serialize,
    deserialize } = serializr;
console.log('raw',raw)

import * as urbiplanModel from "../model/index.mjs";

export {serializr};
export {urbiplanModel};

// console.log('urbiplanModel:',urbiplanModel);

function factory(clazz ){
    return function(){
        return new clazz();
    }
}




var  schemaUrbiplanApiServerInfo = {
    factory : factory(urbiplanModel.UrbiplanApiServerInfo),
    props : {
        apiVersion : raw(),
        version : raw()
    }
}


var schemaUrbiplanApiFeedInfo = {
    factory : factory(urbiplanModel.UrbiplanApiFeedInfo),
    props : {
        id : identifier(),
        timestamp : date()
    }
}

var schemaUrbiplanApiAgency = {
    factory : factory(urbiplanModel.UrbiplanApiAgency),
    props : {
        id : identifier(),
        lang : raw(),
        name : raw(),
        phone: raw(),
        timezone : raw(),
        url : raw(),
        routes : list(indirectReference(urbiplanModel.UrbiplanApiRoute))
    }
}


var schemaUrbiplanApiPattern = {
    factory : factory(urbiplanModel.UrbiplanApiPattern),
    props : {
        directionId : identifier(),
        tripsCount : raw(),
        tripDaysCount : raw(),
        stops : list(indirectReference(urbiplanModel.UrbiplanApiStop,{ identifier : 'stopId'}))
        //stops : list(object(urbiplanModel.UrbiplanApiStop))
    }
}

var schemaUrbiplanApiRoute = {
    factory : factory(urbiplanModel.UrbiplanApiRoute),
    props : {
        id : identifier(),
        agencyId : reference(urbiplanModel.UrbiplanApiAgency),
        shortName : raw(),
        longName : raw(),
        color : raw(),
        textColor : raw(),
        type : raw(),
        patterns : list(object(urbiplanModel.UrbiplanApiPattern)),
        direction0Name : raw(),
        direction1Name : raw(),
        fromDate : date(),
        toDate : date(),
        wheelchairAccessiblePerc : raw(),
        wheelchairUnaccessiblePerc : raw()
    }
}

var schemaUrbiplanApiStop = {
    factory : factory(urbiplanModel.UrbiplanApiStop),
    props : {
        id : identifier(),
        extId : raw(),
        parentId : reference(urbiplanModel.UrbiplanApiStop),
        latitude : raw(),
        longitude : raw(),
        name : raw(),
        wheelchairBoarding : raw(),
        type : raw(),
		cityName : raw(),

        routes : list(indirectReference(urbiplanModel.UrbiplanApiRoute, {identifier: 'routeId'}))
    }
}

var schemaUrbiplanApiFare = {
    factory : factory(urbiplanModel.UrbiplanApiFare),
    props : {
        id : identifier(),
        agencyId : reference(urbiplanModel.UrbiplanApiAgency),
        currencyCode : raw(),
        price : raw(),
        paymentMethod : raw(),
        transfers : raw(),
        transfersDurationSec : raw(),
        // rules : list   // #TODO
    }
}



var schemaUrbiplanApiActiveAlert = {
    factory : factory(urbiplanModel.UrbiplanApiActiveAlert),
    props : {
        id : identifier(),
        level : true,
        lang : true,
        lastUpdated : date(),
        description : true,
        title : true,
		url : true,
        description : true,
        activeFrom : date(),
        activeTo : date(),
        publishActiveRange : true,
        resourceMimeType : true,
        resourceUrl : true,
        splitActiveRange : true, // test activerange
        formattedActiveRange : true,
		agencies : list(indirectReference(urbiplanModel.UrbiplanApiAgency)),
        routes : list(indirectReference(urbiplanModel.UrbiplanApiRoute)),
        stops : optional(null ,list(indirectReference(urbiplanModel.UrbiplanApiStop))),
        cause : true,
        effect : true,
        apiPublication : simplePropSchema ({ dateTime : date() }),
        // channel1Publication
    }
}

var schemaUrbiplanApiServedStop = {
    factory : factory(urbiplanModel.UrbiplanApiServedStop),
    props : {
        arrivalTime : date(),
        scheduledArrivalTime : date(),
        dropOff : raw(),
        realtime : raw(),
        stopId : reference(urbiplanModel.UrbiplanApiStop)
    }
}

var schemaUrbiplanApiDeparture = {
    factory : factory(urbiplanModel.UrbiplanApiDeparture),
    props : {
        // alertIds : indirectReference(urbiplanModel.UrbiplanApiActiveAlert),
        departureTime : date(),
        arrivalTime : date(),
        scheduledArrivalTime : date(),
        scheduledDepartureTime : date(),
        arrivalDeviationSec : raw(),
        departureDeviationSec : raw(),
        directionId : raw(),
        headsign : raw(),
        routeId : reference(urbiplanModel.UrbiplanApiRoute),
        realtime : raw(),
        theoretical : raw(),
        tripId : raw(),
        stopId : reference(urbiplanModel.UrbiplanApiStop),
        pickup : raw(),
        dropoff : raw(),
        exactTime : raw(),
        servedStops : list(object(urbiplanModel.UrbiplanApiServedStop)),
        wheelchairAccessible : raw(),
        tripIndex : raw(),
        tripIndexMax : raw()
    }
}

var schemaUrbiplanApiRealtimeStop = {
    factory : factory(urbiplanModel.UrbiplanApiRealtimeStop),
    props : {
        feedTimestamp : date(),
        stop :  indirectReference(urbiplanModel.UrbiplanApiStop), // object(urbiplanModel.UrbiplanApiStop)
        alerts : list(indirectReference(urbiplanModel.UrbiplanApiActiveAlert)),
        departures : list(object(urbiplanModel.UrbiplanApiDeparture)),
        // routes : list(object(urbiplanModel.UrbiplanApiRoute)),
    }
}

var schemaUrbiplanApiRealtimeVehiculesStop = {
    factory : factory(urbiplanModel.UrbiplanApiRealtimeVehiculesStop),
    props : {
        feedTimestamp : date(),
        timestamp : date(),
        vehiculeId : identifier(),
        label: raw(),
        licensePlate: raw(),
        tripId : raw(),
        stopId : reference(urbiplanModel.UrbiplanApiStop),
        stopSeq : raw(),
        stopStatus : raw(),
        latitude : raw(),
        longitude : raw(),
        bearing : raw(),
        odometersMeters : raw(),
        speedMps : raw()
    }
}



var schemaUrbiplanApiLayerKml = {
    factory : factory(urbiplanModel.UrbiplanApiLayerKml),
    props : {
        '*' : true
    }
}

var schemaUrbiplanApi  = {
    factory : factory(urbiplanModel.UrbiplanApi),
    props : {
        agencies : list(object(urbiplanModel.UrbiplanApiAgency)),
        routes : list(object(urbiplanModel.UrbiplanApiRoute)),
        stops : list(object(urbiplanModel.UrbiplanApiStop)),
        alerts : list(object(urbiplanModel.UrbiplanApiActiveAlert)),
        realtime : object(urbiplanModel.UrbiplanApiRealtimeStop),
        layerKml : object(urbiplanModel.UrbiplanApiLayerKml)
    }
}


setDefaultModelSchema(urbiplanModel.UrbiplanApiServerInfo, schemaUrbiplanApiServerInfo);
setDefaultModelSchema(urbiplanModel.UrbiplanApiFeedInfo, schemaUrbiplanApiFeedInfo);
setDefaultModelSchema(urbiplanModel.UrbiplanApiAgency, schemaUrbiplanApiAgency);
setDefaultModelSchema(urbiplanModel.UrbiplanApiPattern, schemaUrbiplanApiPattern);
setDefaultModelSchema(urbiplanModel.UrbiplanApiRoute, schemaUrbiplanApiRoute);
setDefaultModelSchema(urbiplanModel.UrbiplanApiStop, schemaUrbiplanApiStop);
setDefaultModelSchema(urbiplanModel.UrbiplanApiFare, schemaUrbiplanApiFare);
setDefaultModelSchema(urbiplanModel.UrbiplanApiServedStop, schemaUrbiplanApiServedStop);
setDefaultModelSchema(urbiplanModel.UrbiplanApiActiveAlert, schemaUrbiplanApiActiveAlert);
setDefaultModelSchema(urbiplanModel.UrbiplanApiDeparture, schemaUrbiplanApiDeparture);
setDefaultModelSchema(urbiplanModel.UrbiplanApiRealtimeStop, schemaUrbiplanApiRealtimeStop);
// setDefaultModelSchema(urbiplanModel.UrbiplanApiRealtimeVehiculesStop, schemaUrbiplanApiRealtimeVehiculesStop);
setDefaultModelSchema(urbiplanModel.UrbiplanApiLayerKml, schemaUrbiplanApiLayerKml);
setDefaultModelSchema(urbiplanModel.UrbiplanApi, schemaUrbiplanApi);


