export class UtilsType{
    static isArray(v){
        return Array.isArray(v);
    }
    static isString(value){
        return (typeof value === 'string');
    }
    static isObject(value){
        return (value !== null && typeof value === 'object');
    }
    static isNumber(value){
        return (typeof value === 'number');
    }

    static isDef(value){
        return ((value !== undefined) && (value !== null))
    }

    static isUndef(value){
        return !(UtilsType.isDef(value));
    }

    static isObjectEmpty(value){
        return (UtilsType.isObject(value) && Object.keys(obj).length === 0 )
    }
}
