import {serializr,
    indirectReference, simplePropSchema } from '@mecatran/serializr-extensions';

import {configModel as staticConfigModel} from "../services/config/static/schema.mjs";

import {urbiplanApiDataSourceProvider} from "../services/dataprovider/urbiplanapidatasource.service.mjs";
// import {UrbiplanApiAlertDataSource} from "../services/dataprovider/urbiplanapialertdatasource.service.mjs"
// import {UrbiplanApiRouteDataSource} from "../services/dataprovider/urbiplanapiroutedatasource.service.mjs"
//import {alertListServiceProvider} from "../services/alerts/alertlist.service.mjs";
import {widgetControllerProvider} from "./widget.controller.mjs";
import {MultipleDataSource} from "../services/dataprovider/multipledatasource.service.mjs";
import {Router} from "@mecatran/router";
import * as viewConfigs from "../view-configs/";

console.log('viewConfigs',viewConfigs);

/* TEST */ 
import {SelectorView} from "../view/selector.view.mjs";

export class App{
    constructor(staticConfig){
        var _staticConfig; 
        var _staticConfigModel = staticConfigModel.Config; 
        var _urlHashConfig;

        Object.defineProperty(this,'staticConfig',{
            set : function(c){
               _staticConfig = serializr.deserialize(_staticConfigModel, c );
            },
            get : function(){ 
                return _staticConfig; 
            },
            enumerable : true 
        });

        Object.defineProperty(this,'urlConfig',{   // #TMP !! to replace by a configsource 
            get : function(){
                return window.location.hash.substring(1)
            }
        });

        Object.defineProperty(this,'htmlConfig',{
            get : function(){
                var agencyIds;
                var node = document.querySelector('#view-mecatran-alerts'); // TMP. To be replaced by a configSource
                if (Object.keys(node.dataset).length){
                    agencyIds = JSON.parse(node.dataset['agencyIds']);
                }
                return agencyIds;
            }
        })

        

        // runtime
        this.init(staticConfig);
    }

    init(config){
        this.staticConfig = config;
    }

    getStaticConfig(){ return this.staticConfig }

    getUrlConfig(){ return this.urlConfig }

    getHtmlConfig(){  return this.htmlConfig }

    run(){

        var agencyIds = this.getHtmlConfig();
        console.log('agencyIds',agencyIds)
        // agencyIds = ['OPTYMO_SUB'] // TEST
        //agencyIds = ['ALEOP_53','ALEOP_85']
        //agencyIds = []

        var alertId = this.getUrlConfig(); // TMP replace alertId by a typed object AlertFilterConfig or AlertUrlDynamicConfig or AlertUrlConfig
        console.log('alertId:',alertId); 

        var staticConfig = this.getStaticConfig();
        console.log('config', staticConfig);
        var urbiplanApiAlertConfig = staticConfig.getAlertProviderNamedConfig(); 
        console.log('alert-provider-config',urbiplanApiAlertConfig)

        // options
        var options = staticConfig.getOptions();
        console.log('options',options)


        // hHref 
        var hrefConfig = staticConfig.getHrefConfig();
        console.log('hrefconfig',hrefConfig)


        // Router
        // ------
        var routerConfigs = staticConfig.getRouterConfigs();
        var router = new Router();
        routerConfigs.forEach((routerConfig)=>{
            var getViewConfig = () => { 
                return viewConfigs[routerConfig.getViewConfigName()]; 
            }
            router.addConfig(routerConfig.getTest(),getViewConfig); 
        });
        var mainViewConfig = router.run(window.location); // TMP 
        console.log('mainViewConfig',mainViewConfig); // a reference to config in view-config
        console.log('router',router);
        

        // DataSources
        // -----------
        var urbiplanApiConfig = urbiplanApiAlertConfig.getUrbiplanApiConfig();  
        console.log('urbiplan-api-config', urbiplanApiConfig);
        var {UrbiplanApiRouteDataSource, UrbiplanApiAlertDataSource } = urbiplanApiDataSourceProvider(urbiplanApiConfig); // TMP agencyIds
        var urbiplanApiAlertDataSource = new UrbiplanApiAlertDataSource(agencyIds);
        var urbiplanApiRouteDataSource = new UrbiplanApiRouteDataSource(agencyIds);

        var multipleDataSource = new MultipleDataSource();
        multipleDataSource.add(urbiplanApiAlertDataSource);


        // testView
        // ---------
        var mainView = mainViewConfig( staticConfig, alertId, agencyIds );  // urlConfig, htmlConfig 

        // alertView
        // ---------
        var widgetController = widgetControllerProvider(multipleDataSource, mainView, urbiplanApiRouteDataSource);

        widgetController.run();

    }

}























