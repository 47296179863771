import {serializr } from '@mecatran/serializr-extensions';


export class DataSourceInterface{
    constructor(){
        var _schema;

        Object.defineProperty(this,'schema',{  // optional 
            set : function(s){ _schema = s },
            get : function(){ return _schema }
        });

        Object.defineProperty(this,'get',{
            value : function(){
                return this.getTypedObject().then(this.transform.bind(this));
            },
            writable : false  // Not overloadable 
        });

        Object.defineProperty(this,'transform',{
            value : function(data){ return this.transformer(data) },
            writable : false  // Not overloadable 
        });

    }
    // protected 
    // ----------
    getData(){  return this.fetchData(); } 


    fetchData(){}

    parseData(){
        return (data)=>JSON.parse(data);
    }

    transformer(data){
        return data;
    }

    setSchema(s){ this.schema = s } 
    
    getSchema(){ return this.schema }
    
    deserialize(callback){
        return (data)=>{
            var schema = this.getSchema();
            var res = serializr.deserialize(schema, data, callback );
            return res; 
        }
    }
    getTypedObject(){
        return this.getData().then(this.parseData()).then(this.deserialize());
    }
}