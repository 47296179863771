import {loggerFactory} from "./loggerfactory.mjs";
var logger = loggerFactory.createLogger('APISERV','info',true);

import {request} from "@mecatran/utils";

export default (function(){	
	return { request : apiRequest }
	
	function apiRequest(url, response){
		
		logger.info('requesting api:',url)
		var options = {
			url : url,
			headers: {
    			'Accept': 'application/json'
  			}
		}
		request(options, function(error, res, data){
            //var error = true;
            //console.log('error:',error)
			if (error) logger.error(`Request error: ${options.url.substr(0,60)}`,error); // Print the error if one occurred 
  			logger.debug('statusCode:', res && res.statusCode); // Print the response status code if a response was received 
  			//console.log('body:', data); // Print the HTML for the Google homepage. 
            logger.info('response:',(!data) ? "null": data.substr(0,30)+'...');
            response(error, data);
            /* TODELETE
            if (!data) response(null);
			else {
                response(JSON.parse(data));            
            }
            */
		});
		
	};
    
    /* --- */
    /* internals */
    
    
    

})();

/*
var apiServices = require('./api.services');
apiServices.request(url, function(data){
	console.log(data)
});
*/