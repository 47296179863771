


export var getIdentifierProp = function(modelSchema) {
    //invariant(isModelSchema(modelSchema))
    // optimization: cache this lookup
    while (modelSchema) {
        for (var propName in modelSchema.props)
            if (typeof modelSchema.props[propName] === "object" && modelSchema.props[propName].identifier === true)
                return propName
        modelSchema = modelSchema.extends
    }
    return null
}