

export class UrbiplanApiStop{
	constructor(){
		var  _id, _extId, _parentId, _latitude, _longitude, _name, _wheelchairBoarding, _type, _cityName, _routes;
		
		
		Object.defineProperty(this,'id',{
			set : function(v){
				_id = v;
			},
			get : function(){
				return _id;
			},
			enumerable : true
		});
		
		Object.defineProperty(this,'extId',{
			set : function(v){
				_extId = v;
			},
			get : function(){
				return _extId;
			},
			enumerable : true
		});
		
		Object.defineProperty(this,'parentId',{
			set : function(v){
				_parentId = v;
			},
			get : function(){
				return _parentId;
			},
			enumerable : true
		});
		
		Object.defineProperty(this,'latitude',{
			set : function(v){
				_latitude = v;
			},
			get : function(){
				return _latitude;
			},
			enumerable : true
		});
		
		Object.defineProperty(this,'longitude',{
			set : function(v){
				_longitude = v;
			},
			get : function(){
				return _longitude;
			},
			enumerable : true
		});
		
		Object.defineProperty(this,'name',{
			set : function(v){
				_name = v;
			},
			get : function(){
				return _name;
			},
			enumerable : true
		});
		
		Object.defineProperty(this,'wheelchairBoarding',{
			set : function(v){
				_wheelchairBoarding = v;
			},
			get : function(){
				return _wheelchairBoarding;
			},
			enumerable : true
		});
		
		Object.defineProperty(this,'type',{
			set : function(v){
				_type = v;
			},
			get : function(){
				return _type;
			},
			enumerable : true
		});
		
		Object.defineProperty(this,'cityName',{
			set : function(v){
				_cityName = v;
			},
			get : function(){
				return _cityName;
			},
			enumerable : true
		});
		
		Object.defineProperty(this,'routes',{
			set : function(v){
				_routes = v;
			},
			get : function(){
				return _routes;
			},
			enumerable : true
		});
		
	}
	
	getLatitude(){ return this.latitude }
	getLongitude(){ return this.longitude }
	getCityName(){ return this.cityName }
	getName(){ return this.name }
	
	
}



