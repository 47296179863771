import {View} from "./view.mjs";
import main1Template from "../templates/main1.njk";
import {alertListViewProvider} from "./alertlist2.view.mjs";
import {SelectorView} from "./selector.view.mjs";

export function main1ViewProvider(hrefConfig,options){


    class Main1View extends View{
        constructor(){
            super();
            var _options = options || {};
            var _alertListView = alertListViewProvider();
            var _selectorView = (_options.getHasSelector()) ? new SelectorView() : undefined ;
            var _id = 'main';   // should be a unique id ?
            var _blockHeaderMacroName = ''; // name of the macro
            var _height;
            var _hrefConfig = hrefConfig;
            var _hasBlockHeader = true;

            Object.defineProperty(this,'id',{
                set : function(id){ _id = id },
                get : function(){ return _id }
            })

            Object.defineProperty(this,'blockHeaderMacroName',{
                set : function(n){ _blockHeaderMacroName = n },
                get :function(){ return _blockHeaderMacroName }
            })

            Object.defineProperty(this,'alertListView',{
                get : function(){ return _alertListView }
            });

            Object.defineProperty(this,'selectorView',{
                get : function(){ return _selectorView }
            })

            Object.defineProperty(this,'height',{
                set : function(h){ _height = h },
                get : function(){ return _height }
            })

            Object.defineProperty(this,'hrefConfig',{
                set : function(h){ _hrefConfig = h },
                get : function(){ return _hrefConfig }
            })

            Object.defineProperty(this,'hasBlockHeader',{
                set : function(b){ _hasBlockHeader = b },
                get : function(){ return _hasBlockHeader }
            });

            Object.defineProperty(this,'options',{
                get : function(){ return _options }
            });
        }

        getContext(){
            var context = {
                id : this.id,
                blockHeaderMacroName : this.blockHeaderMacroName,
                blockHeaderTitleUrl: this.hrefConfig.getComputeTitleUrl()(window.location),
                title : 'infos trafic',  // TRANSLATE 
                height : this.getHeight(),
                hasBlockHeader : this.hasBlockHeader,
            }
            return context;
        }

        getTemplate(){
            return main1Template;
        }

        getOptions(){ return this.options }

        getAlertListView(){  return this.alertListView; }

        getSelectorView(){ return this.selectorView }

        getDomId(){
            return `#${this.id}`;
        }

        getHeight(){ return this.height }

        initView(){
            var selector = this.getDomId() + ' .block-content';
            var document = this.getDocument();
            this.alertListView.insertView(document, selector);
            var selectorView = this.getSelectorView();
            if (this.getOptions().getHasSelector()){
                this.selectorView.initView(); // TMP should be called automatically. Check widget-planner view
            }
        }

        setHeight(height){
           this.height = height; 
        }

        setBlockHeaderName(name){
            this.blockHeaderMacroName = name;
        }

        setNoBlockHeader(){
            this.hasBlockHeader = false; 
            
        }

        
    }

    return new Main1View();
}