/**
*/


export class Router{
	constructor(){
		var _map = new Map();
		
		Object.defineProperty(this,'map',{
			get : function(){ return _map }
		});
		
	}
	
	addConfig( test, handler ){
		this.map.set(test,handler);
	}
	run(object){
		for (var [test,handler] of this.map){
			if (test(object)) return handler();
		}
	}
}