import * as urbiplanApiServices from "@mecatran/urbiplan-services-mjs";
import {urbiplanApiConfigModel} from "@mecatran/urbiplan-services-lib-config";
import {loggerFactory} from "@mecatran/logger-factory";
import * as model from "./schema/index.mjs";

console.log('model:',model);
var {serializr, urbiplanModel} = model; 
// export {schema}; // TMP normally not needed

export {UrbiplanApiConfig, urbiplanModel as urbiplanApiModel}

var logger;


var UrbiplanApiConfig = urbiplanApiConfigModel.UrbiplanApiConfig;

var urbiplanApiConfig = new UrbiplanApiConfig();
// console.log('urbiplanApiConfig:', urbiplanApiConfig);

export var loggerManager = urbiplanApiServices.loggerManager; 

// urbiplanApiServices.loggerManager.turnOff();
logger = loggerFactory.createLogger('URBAPI','info',false);

//console.log('logs lib:',urbiplanApiServices.loggerManager.LIB.level());

export class UrbiplanApiLib{
    constructor(){
        var _urbiplanApiConfig = new UrbiplanApiConfig(),
            _client, // = urbiplanApiServices.createClient(_urbiplanApiConfig.client),
            _self = this
        ;


        // ========================
        // public getters / setters 
        // ------------------------
        Object.defineProperty(this,'setConfig',{
            value : function(urbiplanApiConfig){
                if (urbiplanApiConfig instanceof UrbiplanApiConfig){
                    if (!(_urbiplanApiConfig.client.compare(urbiplanApiConfig.client))){
                        logger.info('Creating a new client');
                        _client = urbiplanApiServices.createClient(urbiplanApiConfig.client);            
                    }
                    _urbiplanApiConfig = urbiplanApiConfig;
                }
            },
            enumerable : true 
        });

        Object.defineProperty(this,'getConfig',{
            value : function(){ return _urbiplanApiConfig },
            enumerable : true 
        });
        
        // var endpoints = new Set(['routes','stops','realtime','alerts','agencies']);
        var endpoints = new Set([ 'stops','routes']);
        for (var it = endpoints.values(), endpoint = null; endpoint =it.next().value; ) {
            createEndpoints(endpoint);
        }

        function createEndpoints(endpoint){
            var value = function(query){
                // console.log('query:',query,'endpoint:',endpoint);
                var query = query || _urbiplanApiConfig[ endpoint ]; 
                var param = { endpoint, query };
                var params = getAdditionalParams(param);
                // console.log('params:', params);
                return _client.all(params).then(deserialize);               
            }
            Object.defineProperty(_self,endpoint, { value, enumerable : true });
        }

        Object.defineProperty(this,'agencies',{
            value : function(queryAgencies){  
                var queryAgencies = queryAgencies || _urbiplanApiConfig.agencies;
                var param = {endpoint:'agencies', query: queryAgencies };
                var params = getAdditionalParams(param);
                return _client.all(params).then(deserialize)
            }
        });
/*
        Object.defineProperty(this,'routes',{
            value : function(queryRoutes){
                var queryRoutes = queryRoutes || _urbiplanApiConfig.routes;
                var param = {endpoint:'routes', query: queryRoutes };
                var params = getAdditionalParams(param);
                // console.log('params:',params)
                return _client.all(params).then(deserialize);
            }
        }); 


        Object.defineProperty(this,'stops',{
            value : function(queryStops){
                var queryStops = queryStops || _urbiplanApiConfig.stops;
                var param = {endpoint:'stops', query: queryStops };
                var params = getAdditionalParams(param);
                // console.log('params:',params)
                return _client.all(params).then(deserialize);
            }
        });  
*/


        Object.defineProperty(this,'alerts',{
            value : function(queryAlerts){
                var queryAlerts = queryAlerts || _urbiplanApiConfig.activeAlerts;
                var param = {endpoint:'alerts', query: queryAlerts };
                var params = getAdditionalParams(param);
                // console.log('params:',params)
                return _client.all(params).then(deserialize);
            }
        });  
        
        Object.defineProperty(this,'realtime',{
            value : function(queryRealtime){
                var queryRealtime = queryRealtime || _urbiplanApiConfig.realtimeStop;
                var param = { endpoint: 'realtime', query: queryRealtime };
                var params = getAdditionalParams(param);
                // logger.info('params:',params);
                return _client.all(params).then(deserialize);
            }
        });

        
        Object.defineProperty(this,'layerKml',{
            value : function(querylayerKml){
                var queryLayerKml = queryLayerKml || _urbiplanApiConfig.layerKml;
                var param = { endpoint: 'layerKml', query: queryLayerKml };
                var params = getAdditionalParams(param);
                // logger.info('params:',params);
                return _client.all(params).then(deserialize);
            }
        });
        


        this.check = getAdditionalParams;
        this.test = function(literal){
            return deserialize(literal);
        }

        // ========================
        // private
        // ------------------------
        function getAdditionalParams(param, explored){
            var params = [param];
            var explored = explored || [param.endpoint];
            var map = new Map();
        
            var queryRoutes = _urbiplanApiConfig.routes;
            var queryStops = _urbiplanApiConfig.stops;
            var queryAlerts = _urbiplanApiConfig.activeAlerts;
            var queryAgencies = _urbiplanApiConfig.agencies;
            var queryRealtime = _urbiplanApiConfig.realtimeStop;
        
            var mandatoryQueryRoutes, mandatoryQueryStops, mandatoryQueryAlerts, 
            mandatoryQueryAgencies, mandatoryQueryRealtime = {};

            // mandatoryQueryStops
            // mandatoryQueryAgencies
            mandatoryQueryRoutes = { includeAgencies : true };
            mandatoryQueryAlerts = { includeRoutes: true,
                                        includeStops: true };
            mandatoryQueryRealtime = { includeStops : true,
                                        includeRoutes : true };
            


            switch(param.query){
                case 'realtime':
                    Object.assign(param,mandatoryQueryRealtime);
                    break;
                case 'alerts':
                    Object.assign(param,mandatoryQueryAlerts);
                    break;
            }

            Object.assign(queryRoutes, mandatoryQueryRoutes);
            Object.assign(queryStops, mandatoryQueryStops);
            Object.assign(queryAlerts, mandatoryQueryAlerts);
            Object.assign(queryAgencies, mandatoryQueryAgencies);
            Object.assign(queryRealtime, mandatoryQueryRealtime);

            /* adding required includes (to solve references or indirectReferences) */ 
            map.set('includeRoutes', { endpoint: 'routes', query: queryRoutes });
            map.set('includeStops', { endpoint: 'stops', query: queryStops });
            map.set('includeStations', { endpoint: 'stops', query: queryStops });
            map.set('includePatterns', { endpoint: 'stops', query: queryStops });
            map.set('includeAlerts', { endpoint: 'alerts', query: queryAlerts });
            map.set('includeServedStops', { endpoint: 'stops', query: queryStops });
            map.set('includeAgencies',{ endpoint: 'agencies', query : queryAgencies });
        


            for (var [key,value] of Object.entries(param.query)){
                if (map.has(key) && value){
                    var endpoint = map.get(key).endpoint;
                    if (!(explored.includes(endpoint))){
                        explored = [...explored, endpoint];
                        params = [...params,...getAdditionalParams(map.get(key),explored)];
                    }
                }
            }

            return params;
        }

        var i = 0;
        function deserialize(data){
            // logger.info('data before deserialize:',data);
            var promise = new Promise((resolve,reject)=>{
                function callback(e,d){
                    // console.log('callback d:',i++,d);
                    if (e) { console.log(e)} // do not reject if error, the callback will be called again later (check if process completed)
                    else resolve(d);
                }
                serializr.deserialize(urbiplanModel.UrbiplanApi, data, callback);
            });
            return promise; 
        }


    }
}
