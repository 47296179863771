

/**
 * TODO: Separate file 
 */
var errorCodes = new Map();
errorCodes.set('1','Parameter should be of type UrbiplanApiKeyStore');


export class UrbiplanApiKeyStore{
	constructor(){
		var _keys = new Map();

		// =====================
		// Public getter/setters
		// ---------------------
		Object.defineProperty(this,'prod',{
			set:function(key){ _keys.set('prod',key) }, 
			get:function(){return _keys.get('prod') },
			enumerable: true
		});
		Object.defineProperty(this,'preprod',{
			set:function(key){ _keys.set('preprod',key)}, 
			get:function(){return _keys.get('preprod') },
			enumerable: true
		});	
		Object.defineProperty(this,'dev',{
			set:function(key){ _keys.set('dev',key)}, 
			get:function(){return _keys.get('dev') },
			enumerable: true
		});		
	}
}

export class UrbiplanApiClientConfig{
	constructor(keyStore){
		if (keyStore && !(keyStore instanceof UrbiplanApiKeyStore)) throw new Error(errorCodes.get('1'));
		var _instance,
			_env,
			_keyStore = keyStore || {}
		;
		// =====================
		// Public getter/setters
		// ---------------------
		Object.defineProperty(this,'instance',{
			set:function(instance){ _instance = instance }, 
			get:function(){return _instance },
			enumerable: true
		});
		Object.defineProperty(this,'env',{
			set:function(env){ _env = env }, 
			get:function(){return _env },
			enumerable: true
		});
		Object.defineProperty(this,'key',{
			get:function(){return _keyStore[this.env] },
			enumerable: true
		});		
	}

	compare(urbiplanApiClientConfig){
		return ((urbiplanApiClientConfig.env === this.env )&&( urbiplanApiClientConfig.instance === this.instance ));
	}
}

export class UrbiplanApiFeedInfo{}

export class UrbiplanApiAgencies{
	constructor(){
		var _includeRoutes = false; 
		// =====================
		// Public getter/setters
		// ---------------------
		Object.defineProperty(this,'includeRoutes',{
			set:function(bool){ _includeRoutes = bool }, 
			get:function(){return _includeRoutes},
			enumerable: true
		});		
	}
}

export class UrbiplanApiRoutes{
	constructor(){
		var _includePatterns,
			_routeLookAheadDays,
			_codeQuery,
			_nameQuery,
			_latitude,
			_longitude,
			_radiusMeter
		;
		// =====================
		// Public getter/setters
		// ---------------------
		Object.defineProperty(this,'includePatterns',{
			set:function(bool){ 
				if (!isBoolean(bool)) throw new Error('expecting boolean');
				_includePatterns = bool 
			}, 
			get:function(){return _includePatterns},
			enumerable: true
		});	
		Object.defineProperty(this,'routeLookAheadDays',{
			set:function(num){ 
				if (!isInteger(num)) throw new Error('expecting expecting Number');
				_routeLookAheadDays = num;
			}, 
			get:function(){return _routeLookAheadDays},
			enumerable: true
		});	
		Object.defineProperty(this,'codeQuery',{
			set:function(str){
				if (!isString(str)) throw new Error('expecting expecting String');
				_codeQuery = str; 
			}, 
			get:function(){return _codeQuery},
			enumerable: true
		});	
		Object.defineProperty(this,'nameQuery',{
			set:function(str){ 
				if (!isString(str)) throw new Error('expecting expecting String');
				_nameQuery = str;
			}, 
			get:function(){return _nameQuery},
			enumerable: true
		});	
		Object.defineProperty(this,'latitude',{
			set:function(val){
				if (!isNumber(val)) throw new Error('expecting a float');
				_latitude = val;			
			}, 
			get:function(){return _latitude },
			enumerable: true
		});	
		Object.defineProperty(this,'longitude',{
			set:function(val){ 
				if (!isNumber(val)) throw new Error('expecting a float');
				_longitude = val; 
			}, 
			get:function(){return _longitude},
			enumerable: true
		});	
		Object.defineProperty(this,'radiusMeter',{
			set:function(val){
				if (!isNumber(val)) throw new Error('expecting a float');
				_radiusMeter = val;
			}, 
			get:function(){return _radiusMeter },
			enumerable: true
		});			
	}
}

export class UrbiplanApiStops{
	constructor(){
		var _includeStops,
			_includeStations,
			_includeRoutes,
			_routeLookAheadDays,
			_codeQuery,
			_nameQuery,		
			_latitude,
			_longitude,
			_radiusMeter
		;
		// =====================
		// Public getter/setters
		// ---------------------
		Object.defineProperty(this,'includeStops',{
			set:function(bool){ 
				if (!isBoolean(bool)) throw new Error('expecting boolean');
				_includeStops = bool 
			}, 
			get:function(){return _includeStops},
			enumerable: true
		});	
		Object.defineProperty(this,'includeStations',{
			set:function(bool){ 
				if (!isBoolean(bool)) throw new Error('expecting boolean');
				_includeStations = bool 
			}, 
			get:function(){return _includeStations},
			enumerable: true
		});	
		Object.defineProperty(this,'includeRoutes',{
			set:function(bool){ 
				if (!isBoolean(bool)) throw new Error('expecting boolean');
				_includeRoutes = bool 
			}, 
			get:function(){return _includeRoutes },
			enumerable: true
		});	
		Object.defineProperty(this,'routeLookAheadDays',{
			set:function(num){ 
				if (!isNumber(num)) throw new Error('expecting expecting Number');
				_routeLookAheadDays = num;
			}, 
			get:function(){return _routeLookAheadDays},
			enumerable: true
		});	
		Object.defineProperty(this,'codeQuery',{
			set:function(str){
				if (!isString(str)) throw new Error('expecting expecting String');
				_codeQuery = str; 
			}, 
			get:function(){return _codeQuery},
			enumerable: true
		});	
		Object.defineProperty(this,'nameQuery',{
			set:function(str){ 
				if (!isString(str)) throw new Error('expecting expecting String');
				_nameQuery = str;
			}, 
			get:function(){return _nameQuery},
			enumerable: true
		});	
		Object.defineProperty(this,'latitude',{
			set:function(val){
				if (!isNumber(val)) throw new Error('expecting a float');
				_latitude = val;			
			}, 
			get:function(){return _latitude },
			enumerable: true
		});	
		Object.defineProperty(this,'longitude',{
			set:function(val){ 
				if (!isNumber(val)) throw new Error('expecting a float');
				_longitude = val; 
			}, 
			get:function(){return _longitude},
			enumerable: true
		});	
		Object.defineProperty(this,'radiusMeter',{
			set:function(val){
				if (!isNumber(val)) throw new Error('expecting a float');
				_radiusMeter = val;
			}, 
			get:function(){return _radiusMeter },
			enumerable: true
		});

	}
}

export class UrbiplanApiFares{}

export class UrbiplanApiRealtimeStop{
	constructor(){
		var _stopId,
			_viaStopId,
			_includeServedStops,
			_date,
			_includeAlerts,
			_lookAheadSec,
			_preferredLang
		;
		// =====================
		// Public getter/setters
		// ---------------------
		Object.defineProperty(this,'stopId',{
			set:function(str){ 
				if (!isString(str)) throw new Error('Expecting String');
				_stopId = str 
			}, 
			get:function(){return _stopId },
			enumerable: true
		});	
		Object.defineProperty(this,'viaStopId',{
			set:function(str){ 
				if (!isString(str)) throw new Error('Expecting String');
				_viaStopId = str 
			}, 
			get:function(){return _viaStopId },
			enumerable: true
		});	
		Object.defineProperty(this,'includeServedStops',{
			set:function(bool){ 
				if (!isBoolean(bool)) throw new Error('Expecting boolean');
				_includeServedStops = bool 
			}, 
			get:function(){return _includeServedStops},
			enumerable: true
		});	
		Object.defineProperty(this,'date',{
			set:function(d){ 
				if (!isInputDate(d)) throw new Error('Expecting Input for date');
				_date = new Date(d);
			}, 
			get:function(){return _date },
			enumerable: true
		});	
		Object.defineProperty(this,'includeAlerts',{
			set:function(bool){ 
				if (!isBoolean(bool)) throw new Error('Expecting boolean');
				_includeAlerts = bool 
			}, 
			get:function(){return _includeAlerts },
			enumerable: true
		});	
		Object.defineProperty(this,'lookAheadSec',{
			set:function(num){ 
				if (!isInteger(num)) throw new Error('expecting expecting Number');
				_lookAheadSec = num;
			}, 
			get:function(){return _lookAheadSec },
			enumerable: true
		});		
		Object.defineProperty(this,'preferredLang',{
			set:function(str){ 
				if (!isString(str)) throw new Error('Expecting String');
				_preferredLang = str 
			}, 
			get:function(){return _preferredLang },
			enumerable: true
		});	

	}
}

export class UrbiplanApiRealtimeVehicles{}

export class UrbiplanApiActiveAlerts{
	constructor(){
		var _preferredLang,
			_sortStops
		;
		// =====================
		// Public getter/setters
		// ---------------------
		Object.defineProperty(this,'preferredLang',{
			set:function(str){ 
				if (!isString(str)) throw new Error('Expecting String');
				_preferredLang = str 
			}, 
			get:function(){return _preferredLang },
			enumerable: true
		});	
		Object.defineProperty(this,'sortStops',{
			set:function(bool){ 
				if (!isBoolean(bool)) throw new Error('Expecting boolean');
				_sortStops = bool 
			}, 
			get:function(){return _sortStops },
			enumerable: true
		});	
	}
	
	setPreferredLang(lg){ this.preferredLang = lg; }
}

export class UrbiplanApiLayerKml{
	constructor(){
		var _includeStops,
			_includeRoutes,
			_routeId,
			_aggregateByStation,
			_ignoreUndefinedGeometry,
			_pruneRedundant,
			_usagePruneThreshold,
			_from,
			_to,
			_includeUsage
		;
		// =====================
		// Public getter/setters
		// ---------------------
		Object.defineProperty(this,'includeStops',{
			set:function(bool){ 
				if (!isBoolean(bool)) throw new Error('expecting boolean');
				_includeStops = bool 
			}, 
			get:function(){return _includeStops},
			enumerable: true
		});	
		Object.defineProperty(this,'includeRoutes',{
			set:function(bool){ 
				if (!isBoolean(bool)) throw new Error('expecting boolean');
				_includeRoutes = bool 
			}, 
			get:function(){return _includeRoutes },
			enumerable: true
		});	
		Object.defineProperty(this,'routeId',{
			set:function(str){ 
				if (!isString(str)) throw new Error('Expecting String');
				_routeId = str 
			}, 
			get:function(){return _routeId },
			enumerable: true
		});	
		Object.defineProperty(this,'aggregateByStation',{
			set:function(bool){ 
				if (!isBoolean(bool)) throw new Error('expecting boolean');
				_aggregateByStation = bool 
			}, 
			get:function(){return _aggregateByStation },
			enumerable: true
		});	
		Object.defineProperty(this,'ignoreUndefinedGeometry',{
			set:function(bool){ 
				if (!isBoolean(bool)) throw new Error('expecting boolean');
				_ignoreUndefinedGeometry = bool 
			}, 
			get:function(){return _ignoreUndefinedGeometry},
			enumerable: true
		});	
		Object.defineProperty(this,'pruneRedundant',{
			set:function(bool){ 
				if (!isBoolean(bool)) throw new Error('expecting boolean');
				_pruneRedundant = bool 
			}, 
			get:function(){return _pruneRedundant},
			enumerable: true
		});
		Object.defineProperty(this,'usagePruneThreshold',{
			set:function(num){ 
				if (!isNumber(num)) throw new Error('expecting expecting Number');
				_usagePruneThreshold = num;
			}, 
			get:function(){return _usagePruneThreshold },
			enumerable: true
		});	
		Object.defineProperty(this,'from',{
			set:function(d){ 
				if (!isInputDate(d)) throw new Error('Expecting date');
				_from = new Date(d);
			}, 
			get:function(){return _from },
			enumerable: true
		});
		Object.defineProperty(this,'to',{
			set:function(d){ 
				if (!isInputDate(d)) throw new Error('Expecting date');
				_to = new Date(d);
			}, 
			get:function(){return _to },
			enumerable: true
		});
		Object.defineProperty(this,'includeUsage',{
			set:function(n){ 
				_includeUsage = n 
			}, 
			get:function(){return _includeUsage },
			enumerable: true
		});
	}
}

export class UrbiplanApiConfig{
    constructor(){
		// var _instance, _env;
		var _urbiplanApiKeyStore = new UrbiplanApiKeyStore(),
			_urbiplanApiClientConfig = new UrbiplanApiClientConfig(_urbiplanApiKeyStore),
			_urbiplanApiAgencies = new UrbiplanApiAgencies(),
			_urbiplanApiRoutes = new UrbiplanApiRoutes(),
			_urbiplanApiStops = new UrbiplanApiStops() ,
			_urbiplanApiFares = new UrbiplanApiFares(),
			_urbiplanApiRealtimeStop = new UrbiplanApiRealtimeStop(),
			_urbiplanApiActiveAlerts = new UrbiplanApiActiveAlerts(),
			_urbiplanApiLayerKml = new UrbiplanApiLayerKml()
        ;

		// =====================
		// Public getter/setters
		// ---------------------
		/*
		Object.defineProperty(this,'instance',{
			set:function(instance){ _instance = instance}, 
			get:function(){return _instance },
			enumerable: true
		});
		Object.defineProperty(this,'env',{
			set:function(env){ _env = env}, 
			get:function(){return _env},
			enumerable: true
		});
		*/
		Object.defineProperty(this,'client',{
			get:function(){return _urbiplanApiClientConfig},
			enumerable: true
		});
		Object.defineProperty(this,'keys',{
			get:function(){return _urbiplanApiKeyStore},
			enumerable: true
		});	
		Object.defineProperty(this,'agencies',{
			get:function(){return _urbiplanApiAgencies},
			enumerable: true
		});
		Object.defineProperty(this,'routes',{
			get:function(){return _urbiplanApiRoutes},
			enumerable: true
		});
		Object.defineProperty(this,'stops',{
			get:function(){return _urbiplanApiStops},
			enumerable: true
		});
		Object.defineProperty(this,'fares',{
			get:function(){return _urbiplanApiFares},
			enumerable: true
		});
		Object.defineProperty(this,'realtimeStop',{
			get:function(){return _urbiplanApiRealtimeStop},
			enumerable: true
		});
		Object.defineProperty(this,'activeAlerts',{
			get:function(){return _urbiplanApiActiveAlerts},
			enumerable: true
		});
		Object.defineProperty(this,'layerKml',{
			get:function(){return _urbiplanApiLayerKml},
			enumerable: true
		});

	}
	
	getKeyStore(){ return this.keys }
	
	getClient(){ return this.client }
	
	getKey(env){
		if (!env) throw new Error('getKey requires to specify the env');
		return this.keys[env];
	}
	
	getActiveAlertsConfig(){ return this.activeAlerts; }
	
	getStopsConfig(){ return this.stops; }
	
	getClientConfig(){
		return { env: this.env, 
				 instance: this.instance,
				 key : this.getKey() 
			} 
	}
	/*
	toLiteral(){
		var ret = {};
		Object.assign(ret,this);
		return ret;
	}
	*/
	
	setInstance( instance ) { this.client.instance = instance }
	setEnv(env){ this.client.env = env } 
	setKey(env, key ){ this.keys[env] = key }
}


function isNumber(x){
	return !isNaN(x);
}

function isInteger(x){
	return Number.isInteger(x);
}

function isBoolean(b){
	return (typeof b === 'boolean')
}

function isString(s){
	return (typeof s === 'string');
}

function isFloat(n){
    return Number(n) === n && n % 1 !== 0;
}

function isDate(d){
	return (d instanceof Date);
}

function isInputDate(d){
	return (new Date(d) != 'Invalid Date')
}


