function computeTitleUrl(location, alert){
    return `${location.pathname}infos_trafic/`;
}

function computeAlertUrl(location,alert){
    return `#${alert.getId()}`;
}


function defaultTestPage(){ 
    var hash = location.hash; var strHash = hash.substring(1);
    return !(strHash)   
}

function testHasHash(location){
    var hash = location.hash; var strHash = hash.substring(1);
    return (strHash);
}


/* config */ 

export var config = {
    alertProviders : [
         {
             provider : 'urbiplan',
             name : 'urbiplan-api-prod',
             config : {
                 instance : 'herault',
                 env : 'prod',
                 key : '6816694923624c7162755d624e6b394837676c79',
                 preferredLang : 'fr'
             }
         },
         {
             provider : 'urbiplan',
             name : 'urbiplan-api-preprod',
             config : {
                   instance: 'herault',
                   env : 'preprod',
                   key : '4a4c43684772455b105c8062596d152878591b53',
                   preferredLang : 'fr'
             }
         }
     ],

     defaultAlertProviderConfigName : 'urbiplan-api-prod',
     //defaultAlertProviderConfigName : 'urbiplan-api-preprod',

     
     routerConfigs :[ {
            test : defaultTestPage.toString(),  // replace by default routerConfig 
            viewConfigName : 'main1View4Config'
        }, {
            test : testHasHash.toString(),
            viewConfigName : 'main1View5Config'
        }
    ],

    href : {
        computeTitleUrl :  computeTitleUrl.toString(),
        computeAlertTitleUrl : computeAlertUrl.toString() // 'infos_trafic/#{id}"  or '#{id}' depending where it is 
        },

    options : {
        hasSelector : true,
        alertSorterType : 'publicationdate'  // [level, publicationdate]
    }
    

}




