/**
api/realtime
NOTE:
nocache : true
*/

export default {
    id : 'realtime',
	endPoint : 'realtime/stop',
    endPointComplement: function(query){
        return query.stopId;
    },
	nocache:true,  // process.env.cache ou --cache ou --nocache
}