
import {Utils,UtilsType, UtilsPrint} from "../utils/index.mjs";



/*
Object.defineProperty(this,'',{
    get: function(){},
    enumerable : true 
});


Object.defineProperty(this,'',{
    get: function(){ return v },
    set: function(v){ _v = v },
    enumerable : true 
});
*/



export class Cell{
    constructor(value, className, id){
        var _contents = [],
            _id = id || '',
            _classes = new Set(),
            _style = {},
            _span = 1,
            _orientation 
        ;

        Object.defineProperty(this,'clearContent',{
            value : function(){ _contents = []; },
            enumerable : false
        })

        Object.defineProperty(this,'contents',{
            get : function(){ return _contents },
            enumerable : false 
        });

        Object.defineProperty(this,'addContent',{
            value : function(content, className){ 
                if (content || className){ 
                    var obj = { content, className };
                    _contents.push(obj);
                }
            },
            enumerable : false 
        });


        Object.defineProperty(this,'id',{
            get: function(){ return _id },
            set : function(id){ _id = id },
            enumerable : false  
        });


        Object.defineProperty(this,'addClass',{
            value : function(c){ if (UtilsType.isString(c)){ _classes.add(c)} },
            enumerable : false
        });

        Object.defineProperty(this,'classes',{
            get : function(){ return [..._classes.values()] },
            enumerable : false
        });

        Object.defineProperty(this,'addStyle',{
            value : function(prop, value){
                // check if prop is in set
                var s = {};
                s[prop] = value; 
                Object.assign(_style,s); 
            }, 
            enumerable : false 
        });

        Object.defineProperty(this,'increaseSpan',{
            value : function(n=1){ _span = _span + n },
            enumerable : false 
        });

        Object.defineProperty(this,'span',{
            get : function(){ return _span },
            enumerable : false 
        });

        Object.defineProperty(this,'style',{
            get : function(){ 
                //return _style;
                var str = '';
                Object.entries(_style).forEach(([key,value])=>{ str += `${key}:${value}` });
                return str;
             },
            enumerable : false
        });

        Object.defineProperty(this,'customFunction',{  
            set : function(f){ 
                var proto = Object.getPrototypeOf(this);
                proto[f.name] = f;  
            },
            enumerable : false
        });

        Object.defineProperty(this,'orientation',{
            set : function(o){ _orientation = o },
            get : function(){ return _orientation },
            enumerable : false
        })

        // runtime 
        this.init();
        this.addContent(value,className);

    }
    init(){
        this.customCompare = this.compareByValue;
    }
    getValue(){   
        return this.getContent('asString');
    };
    setValue(v){  
        this.clearContent();
        this.addContent(v);
    } 
    getContent(type = 'asString',...params){
        var type = type || 'asString';
        switch(type){
            case 'asString':
                return this.getContentAsString(...params);
                break;
            case 'asHtml':
                return this.getContentAsHtml(...params);
                break;
        }
    }
    
    getContentAsString(joinChar= ' '){
        var contents = this.contents;
        var str = '';
        contents.forEach(entry => {
            if (!str.length) str = entry.content;
            else str = [str, entry.content].join(joinChar);
        } );
        return str; 
    }
    getContentAsHtml(){
        var contents = this.contents;
        var html = '';
        contents.forEach(entry =>{
            var {content, className} = entry;
           // if (content){
                if (className) html +=  `<span class="${className}">${content}</span>`;
                else html += `<span>${content}</span>`;
            //} else html += '';
        });
        return html;
    }
    getId(){ return this.id }
    setId(i){ this.id = i }
    getClassesArr(){ return this.classes }
    getClasses(){ return this.getClassesArr().join(' ')}
    hasClass( className ){ 
        var s = new Set(this.classes);
        return s.has(className);
      }
    getStyle(){  // #COMMENT report this function into the accessor
        return this.style;
     }
    getSpan(){ return this.span }
    getOrientation(){ return this.orientation }

    getSpanTag(){
        var spanTag;
        switch(this.getOrientation()){
            case Cell.orientation.row:
                spanTag = 'colspan';
                break;
            case Cell.orientation.column:
               spanTag = 'rowspan';
                break;
        }
        return spanTag; 
    }

    // setters 
    setOrientation(o){ this.orientation = o }

    hasContent(){ 
        var content = this.getContent('asString');
        return !(content === "");
    }

    log(){  //#COMMENT use getValue, getStyle instead 
        var ret = {};
        ret.value = this.value;
        ret.id = this.id; 
        ret.classes = this.classes;
        ret.style = this.style;   
        ret.span = this.span;
        return ret;
    }
    addCustomFunction(customFunction){ this.customFunction = customFunction }

    static addCustomFunction(customFunction){ (new Cell()).addCustomFunction(customFunction) }

    // compare
    // -------
    compareByValue(cell){  return (this.getValue() === cell.getValue() )}
    compareByClass(cell, className){ return (this.hasClass(className ) && cell.hasClass(className)) }

    compare(cell, method, ...params){
        if (UtilsType.isUndef(cell)) return false;
        if (cell.constructor === Cell){
            switch(method){
                case Cell.compareMethod.compareByValue:
                    return this['compareByValue'].call(this,cell,params);
                    break;
                case Cell.compareMethod.compareByClass:
                    return this['compareByClass'].call(this,cell,params);
                    break; 
                default:
                    if (UtilsType.isString(method)) return this[method].call(this,cell,params);
                    else return method.bind(this)(cell,params);
            }
        } 
    }


    // Merge 
    // -----
    

/**
 * 
 * @param cell 
 * @param method 
 *      - override 
 *      - concat (value, classes)
 * @param options 
 *     
 */
    merge(cell, method, joinchar = '/' ){  
        if (UtilsType.isUndef(cell)) return;
        if (cell.constructor === Cell){
            if (method === Cell.mergeMethod.override ){}
            if (method === Cell.mergeMethod.concat ){
                var value = [this.getValue(),cell.getValue()].join(joinchar)
                this.setValue(value);
                cell.getClassesArr().forEach((clazz)=>{ this.addClass(clazz)  })
            }
            this.increaseSpan();
        }
    }

    static merge(cell0,cell1, method, ...params){
        return cell0.merge(cell1, method, ...params);
    }

    /* static mergeMethod = { override: 0, concat :1 }*/

    /* static compareMethod = { compareByValue : 10, compareByClass: 11  } */

}
Cell.mergeMethod = { override: 0, concat :1 }
Cell.compareMethod = { compareByValue : 10, compareByClass: 11  }
Cell.orientation = { row : 0, column : 1}

/**
    @data 
    Data can provided by row or by column 
    Data can be:
        -  an array of array of cells, optional header (as array of cells, or array of values) 
            ex: [[c1,c2,c3],[c4,c5,c6]]
        - an array of objects,  optionnaly a header (as array of values corresponding to the keys of the object)
            ex: [{ p1:1, p2:2, p3:3}, {p1:11, p2: 22, p3:33}]
        - an array of array of values, optional header 
            ex: [ [1,2,3], [4,5,6] ]
    Note: 
    - internally the header is processed within the data  
    - the header is included in the data 
    - the orientation keeps track of the internal orientation of the data (vertical or horizontal, or byRow / byColumn )

    @header
    Header can be:
        - an array of cells 
            ex: [c1,c2,c3]
        - an array of values
            ex: [1,2,3] 
        - deduced from the data when the data is an array of objects 
            ex: [p1,p2, p3]

    @options
    - display: 'byCol' or 'byRow'
    - displayHtmlHeader 
 */

export class Table{
    constructor(options = { iterator : Table.byRow }){  
        var _hasHeader = false,
            _orientation = Table.orientation.horizontal,
            _data = [],    // data should be an array of array of cells 
            _options = options 
        ;

        Object.defineProperty(this,'hasHeader',{
            get : function(){ return _hasHeader }, 
            set : function(b){ _hasHeader = b  },
            enumerable : true 
        });

        Object.defineProperty(this,'swapOrientation',{
            value : function(){
                switch(_orientation){
                    case Table.orientation.horizontal:
                        _orientation = Table.orientation.vertical;
                        break;
                    case Table.orientation.vertical:
                        _orientation = Table.orientation.horizontal; 
                        break; 
                }
            },
            enumerable: true 
        });

        Object.defineProperty(this,'getOrientation',{
            value : function(){
                return _orientation;
            },
            enumerable: true 
        })


        Object.defineProperty(this,'data',{
            get : function(){ return _data.slice() },
            set : function(d){ _data =d || [] }, 
            enumerable : true 
        });

        Object.defineProperty(this,'options',{
            get : function(){ return _options },
            enumerable : true 
        })

        // runtime
        this.init();
    }
    // =======
    // private
    // -------
    init(){
        this.runOptions();
     }

    log(options){
        var ret = {};
        // ret.data = this.data.map(arr => arr.map(cell => cell.log()))
        var retRow = {}
        //this.getData(options).forEach((row,rowIndex) => { retRow[`row${rowIndex}`] = row.map(cell=> cell.log())      })
        var data = this.data;
        var logCell = (cell )=>cell.log();
        var logRow = (row,index)=>{ retRow[`row${index}`] = Table.map1(row,logCell)}
        Table.map1(data, logRow );
        ret.data = retRow;
        return ret;
    }

    logTable(options){
        var maxString = (options) ? options.maxString : undefined ; 
        var output = this.getData(options);
        if (maxString)  {
            var limitString = (val)=>{ 
                if (UtilsType.isString(val)){ return val.substring(0, maxString) }
                else return val;
            }
            output = Table.map2(output,limitString);
        }
        return output;
    }

    // helpers / utils
    // ---------------

    getConstructor(){ return Table }
/**
 * 
 * @param data could be a value, a cell or an object 
 * @return {isValue, isCell, isObject}
 */
    static getDatatype(data){
        var isValue = ( UtilsType.isString(data) || UtilsType.isNumber(data ));
        var isCell = ((data.constructor) && (data.constructor === Cell));
        var isObject = ((!isCell) && (UtilsType.isObject(data)));
        return {isValue, isCell, isObject }
    }

/**
 * @param data array or array of array  
 */
    static getDatatypes(data){
        var data = data.flat();
        var dataTypeFirstElement = Table.getDatatype(data[0]); 
        var firstElementDatatype = Object.keys(dataTypeFirstElement).filter(i => dataTypeFirstElement[i] )[0];  // 'isValue', 'isCell','isObject'
        var hasIdenticalDatatype = (item)=> Table.getDatatype(item)[firstElementDatatype]
        if (data.every(hasIdenticalDatatype)) return dataTypeFirstElement;
    }

    static valueToCell(value){ return new Cell(value) }
    static cellToValue(cell){ if (cell) return cell.getValue() }

    static objectToCell(object) { return new Cell(Object.values(object) )}


    static map1(data, callback){ return data.map(callback) }
    static map2(data,callback){ return data.map(array => array.map(callback))}
    static iterate1(data, callback){ data.forEach(callback)}
    static iterate2(data0,data1, callback){ 
        data0.forEach((cell0,index0)=>{  
            data1.forEach((cell1, index1)=>{ callback(cell0,index0,cell1,index1) })
        })
    }
    static filter(data, callback){ data.filter((cell,index)=>callback(cell,index))}
/**
 * iterate over consecutive rows or columns 
 * @param rows 
 * @param callback 
 * [ row0, row1, row2, row3 ] --> cb(row0, row1, 0), cb(row1,row2,1 )  cb(row2, row3,2)
 */
    static iterateRowCouples(rows, callback){
        var refRow; 
        var nRows = rows.length; 
        for (var i = 0; i < nRows; i++ ){
            if (UtilsType.isDef(refRow)){
                callback(refRow,rows[i], i-1);
            } 
            refRow = rows[i];
        } 
    }

/**
 * 
 * @param row0 
 * @param row1 
 * @param index 
 * @param callback 
 *  row0 = [ c00, c01, c02, c03 ]   row1 = [ c10, c11, c12, c13 ]  --> cb(c00,c10,0)   cb(c01,c11,1)
 *  cb(c02,c12,2)  cb(c03,c13,3)
 */

    static iterateCellsRowCouple(row0, row1, callback ){
        row0.forEach((cell0,index )=>{
            var cell1 = row1[index];
            callback(cell0,cell1,index);
        });
    }


/**
 * 
 * @param data array (or array of) dataObjects 
 */
    static getProperties(data){  
        if (UtilsType.isArray(data)) {
            var object; 
            var data = data.flat();
            if (Table.getDatatypes(data).isObject){
                object = {};
                data.forEach(obj => Object.assign(object,obj))
            }
        } else if (UtilsType.isObject(data)){
            return Object.keys(data);
        }

        return Table.getProperties(object);
    }

    static objectToCells(object){
        var values = Object.values(object);
        return Table.map1(values,Table.valueToCell)
    }

    static cellsToObject(header){
        return (row)=>{
            var values = Table.map1(row,Table.cellToValue);
            var keys = Table.map1(header, Table.cellToValue);
            var obj = {};
            for (var i = 0; i < values.length; i++){
                var key = keys[i], value = values[i];
                obj[key] = value
            }
            return obj; 
        }
    }

    static computeDimension(data){
        var sizes = data.map(row => row.length );
        var nCol = Math.max(...sizes);
        var nRow = data.length;
        return [nCol,nRow];  
    }

    static normalize(data, char = '#'){
        var normData = [];
        var [nCol,nRow] = Table.computeDimension(data);
        for (var row = 0; row < nRow; row++ ){
            var normRow = [];
            for (var col =0 ; col < nCol; col++ ){
                var value = data[row][col] || char;
                normRow.push(value)
            }
            normData.push(normRow)
        }
    }

    static selectorAll(x){ return x}

    static splitDataHeader(data, orientation, hasHeader){
        var data = data;
        var header = [];
    
        if (hasHeader){
            if (orientation === Table.orientation.vertical){
                data = Utils.transpose(data);
                header = data.splice(0,1);
                data = Utils.transpose(data);
                header = Utils.transpose(header)               
            } else {
                header = data.splice(0,1);
            }
            return [data,header]
        }
        else return [data];
    }

    static setAutomaticId(data){
        data.forEach((row, rowIndex) =>{
            row.forEach((cell,colIndex)=>{cell.setId(`${colIndex}${rowIndex}`)});
        });
    }

    static setCellOrientation(data, displayBy){
        data.forEach((row,rowIndex)=>{
            row.forEach((cell,colIndex)=>{
                if (cell === undefined){
                    console.log('colindex',colIndex,'rowIndex:',rowIndex)
                    console.log(data);
                    console.log('cell undefined. Please check.')
                    // throw new Error('cell undefined !')
                } else {
                    if (displayBy === Table.byRow){ cell.setOrientation(Cell.orientation.row )}
                    if (displayBy === Table.byColumn){ cell.setOrientation(Cell.orientation.column )}
                }
            });
        });
    }


    // options 
    // -------
    runOptions(){
        Object.entries(this.options).forEach(([option,value]) =>{ this.runOption(option, value)})
    }

    runOption(option,value){
        switch(option){
            case 'iterator':
                this.setIterator(value)
                break;
        }
    }



    // iterators 
    // ---------
    setIterator(iteratorType){
        if (iteratorType === Table.byColumn ) this[Symbol.iterator] = this.cellGeneratorByColumn();  
        else this[Symbol.iterator] = this.cellGeneratorByRow; 
    }

    *rowGenerator(){ yield* this.data }

    *ColumnGenerator(){ 
        var data = Utils.transpose(this.data);
        yield* data;
    }

    *cellGeneratorByRow(n){  
        if (UtilsType.isDef(n)) yield* this.data[n];
        else {
            var data = this.data.flat();
            yield* data; 
        }
    }

    *cellGeneratorByColumn(n){            
        var data = Utils.transpose(this.data); 
        if (UtilsType.isDef(n)) yield* data[n];
        else {
            data = data.flat();
            yield* data;
        }
    }

    static *cellGenerator(cells){ return yield* cells }


    // compare
    // -------

    static compareEveryCell(row0, row1, compareCellMethod, ...params){
        var compareCell = function(cell0,index){
            var cell1 = row1[index];
            if (UtilsType.isUndef(cell0) && UtilsType.isUndef(cell1)) return true; 
            return cell0.compare(cell1, compareCellMethod, params);
        }
        return row0.every(compareCell);
    }

    static compareRows(row0,row1, method, ...params){
        switch(method){
            case Table.compareMethod.compareEveryCell:
                return Table.compareEveryCell(row0, row1, ...params);
                break;
            default:
                return method(row0, row1, ...params); 
        }
    }


    static compareColumns(col1, col2, method, ...params){
        Table.compareRow(col1, col2, method, ...params);
    }

    // find  utils 
    // ----------
    static findCellIndexes(data, findCriteria){   // deprecate ?
        var indexes = [];
        data.forEach((cell,cellIndex)=>{  if (findCriteria(cell)) indexes.push(cellIndex) });
        return indexes;
    }

    findRows(findCriteria){   // not used. Deprecate ?
        var rows = this.getRow();
        return rows.filter(findCriteria);
    }


    // getters
    // -------
    getRow(n){ 
        if (UtilsType.isDef(n)) return this.data[n];
        else return this.data;
    }
    getColumn(n){
        var data = Utils.transpose(this.data);
        if (UtilsType.isDef(n)) return data[n];
        else return data;
    }
    getRows(){ return this.getRow(); }
    getColumns(){ return this.getColumn() }

    getTagSpan(displayBy){
        if (displayBy === Table.byRow){ return 'colspan'}
        else { return 'rowspan'}
    }

    // modifiers
    // ---------
    /**
     * 
     * @param selector   select which row to transform 
     * @param transform 
     */
    rowModifier(selector, transform){
        var rows = this.getRows();
        var selectRowToTransform = (row)=>{ 
            if (selector(row)) return transform(row);
            else return row;    
        }
        this.data = rows.map(selectRowToTransform);
    }

    columnModifier(selector, transform){
        var columns = this.getColumns();
        this.rowModifier(selector, transform)
    }
    
    /**
     * Potentially remove rows or columns 
     * @param transform 
     * @param orientation 
     */
    tableModifier(transform, byRow = Table.byRow ){   // ##comment should use orientation instead ?
        var rows;
        if (!byRow){
            rows = Utils.transpose(this.data);
        } else rows = this.data; 

        var rows = transform(rows);
        if (!byRow){ rows = Utils.transpose(rows); }
        this.data = rows;
    }

    // merger  (modifier)
    // ------
    merge(table){
        if (table.constructor === Table){
            var orientation;
            if ((orientation = this.getOrientation()) === table.getOrientation()){
                if (orientation === Table.orientation.horizontal){
                    var [data1,header0] = table.getData({dataAs: Table.datatype.cell, split:true});
                    this.data = [...this.data, ...data1]; 
                } else {  // vertical
                    var data0 = Utils.transpose(this.data); 
                    var [data1] = table.getData({dataAs: Table.datatype.cell, split:true});
                    var data1 = Utils.transpose(data1);
                    var data = [...data0, ...data1];
                    this.data = Utils.transpose(data);
                }
            } else {
                console.log('Merging table requires same direction ')
            }
        }
    }


    // ======
    // public
    // ------
    setOption(option,value){
        this.options[option] = value;
        this.runOption(option,value);
    }

    setHasHeader(){ this.hasHeader = true }

    setDataByRow(rows, header){
        var data = rows; 
        var header = header || [];
        var dataType = Table.getDatatypes(data);
        if (dataType.isObject){ 
            header = (header.length) ? header : Table.getProperties(data); 
            data = Table.map1(data, Table.objectToCells);
        } 
        else if (dataType.isValue) data = Table.map2(data, Table.valueToCell);
        
        if (header.length){
            this.hasHeader = true; 
            var headerType = Table.getDatatypes(header);
            if (headerType.isObject) header = Table.map1(header, Table.objectToCell); // should not happen
            if (headerType.isValue) header = Table.map1(header, Table.valueToCell);
            data = [header, ...data];
        }

        if (this.getOrientation()===Table.orientation.vertical){
            data = Utils.transpose(data);
        }

        this.data = data;
    }
    setDataByColumn(columns,header){
        this.swapOrientation();
        this.setDataByRow(columns, header);
    }
/**
 * getData
 * @options 
 * - displayBy byRow, byColumn 
 * - dataAs : cells or values or objects 
 */
    getData(options = { displayBy: Table.byRow, dataAs: Table.datatype.value, split : false, autoId: true }){
        var data = this.data;
        var header = (this.hasHeader) ? data[0] : undefined; 
        var dataAs = options.dataAs || Table.datatype.value; 
        var autoId = options.autoId || true;
        var displayBy = options.displayBy || Table.byRow;
        if (displayBy === Table.byColumn){
            data = Utils.transpose(data);
        }

        Table.setCellOrientation(data, displayBy );
        if (autoId) Table.setAutomaticId(data);
        
        
        switch(dataAs){
            case Table.datatype.value:
                data = Table.map2(data, Table.cellToValue )
                break;
            case Table.datatype.cell:
                break;
            case Table.datatype.object:
                var data = Table.map1(data, Table.cellsToObject(header))
                break;
        }

        if (options.split) return Table.splitDataHeader(data, this.getOrientation(), this.hasHeader);
        return data; 
    }

}
    // Constants  // Use babel with webpack to load 
    // ---------
    Table.byRow = true;
    Table.byColumn = false; 
    Table.datatype = { value: 0, object: 1, cell :2 }
    Table.orientation = { horizontal : 10, vertical : 20 }
    Table.compareMethod = { compareEveryCell : 0 }

