var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../customers/herault/widget-alerts/node_modules/@mecatran-projects/widget-alerts/src/nunjucks-extensions/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["./macros.njk"] = require( "./macros.njk" );




var shim = require("A:/dev/workspace-node/bitbucket_repository/projects/customers/herault/widget-alerts/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["../../../widget-alerts/src/templates/main1.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += " \r\n\r\n";
env.getTemplate("./macros.njk", false, "../../../widget-alerts/src/templates/main1.njk", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\r\n\r\n\r\n\r\n<div id=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "id"), env.opts.autoescape);
output += "\" >\r\n    ";
if(runtime.contextOrFrameLookup(context, frame, "hasBlockHeader")) {
output += "\r\n        ";
output += runtime.suppressValue((lineno = 8, colno = 39, runtime.callWrap(runtime.memberLookup((t_1),runtime.contextOrFrameLookup(context, frame, "blockHeaderMacroName")), "macros[\"blockHeaderMacroName\"]", context, [runtime.contextOrFrameLookup(context, frame, "blockHeaderTitleUrl"),runtime.contextOrFrameLookup(context, frame, "title")])), env.opts.autoescape);
output += "\r\n    ";
;
}
output += "\r\n    <div class=\"block-content\" style=\"height:";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "height"), env.opts.autoescape);
output += "\"></div>\r\n</div>\r\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["../../../widget-alerts/src/templates/main1.njk"] , dependencies)