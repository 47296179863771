
import {loggerFactory} from "./internals/loggerfactory.mjs";
var logger = loggerFactory.createLogger('LIB','info',true);

import feedinfo from "./apis/feedinfo.mjs";
import agencies from "./apis/agencies.mjs";
import stops from "./apis/stops.mjs";
import routes from "./apis/routes.mjs";
import realtime from "./apis/realtime.mjs";
import alerts from "./apis/alerts.mjs";


import "./internals/cache.mjs";
import dataUpdateMonitor from "./internals/dataupdatemonitoring.service.mjs";
import requestManager from "./internals/requestmanager.mjs";
import {getUrlBuilder} from "./internals/urlbuilder.service.mjs";
import CacheServices from "./internals/cache.service.mjs";

export var createClient = function(options){
	options = options || {};
    var _monitor = ((options.monitor != undefined ) ? options.monitor : true),
        _dataUpdateMonitor,
		_ret
        ;
    
    if (_monitor){
        logger.info('creating monitor')
        _dataUpdateMonitor = dataUpdateMonitor;
        _dataUpdateMonitor.config.setAutoUpdate(10000);
	}
	
	function cleanUpQuery(query){
		/* remove undefined */
		var ret = {}
		var query = query || {};
		for (let [key, value] of Object.entries(query)) {
			if (value !== undefined) ret[key] = value;
		}
		return ret;
	}


    
	function makeApiMethod(config){
        var _cacheServices = null, 
            _noCache = ( config.nocache )|| (config.cache && config.cache.size === 0) || false,
            _urlBuilder = getUrlBuilder(options);
        
		_urlBuilder.config.setEndPoint(config.endPoint);
        
        
        if (!_noCache) {
            _cacheServices = CacheServices();
            _cacheServices.config.cache(config.cache);

            /* launch the dataupdatemonitoring service */ 
            /* erase cache if there is more uptodate data */
            if (_monitor) _dataUpdateMonitor.config.onDataUpdated(_cacheServices.api.clear);
        }
        
		requestManager.config.setCacheServices(_cacheServices);
		
		return function(query,callback,customParams){
            logger.debug('query',(!query ? "null" : query));
			// TODO: verify the 'query' 
			var query = cleanUpQuery(query);
			_urlBuilder.config.setParams(query);
            if (config.endPointComplement) _urlBuilder.config.setEndPointComplement(config.endPointComplement(query));
			requestManager.request(_urlBuilder.getUrl(), callback )
		}
	}
	
	
	/*
	all( params, callback );
	params = [  { endpoint: 'agency', query : {} },
				{ endpoint :'stops': query : {} },  ..] 
 	all({agency: params, stops: params, routes: params,  }, callback)  
	callback({agency:data, })
	usage: utils.all.call(client,params,callback)
	*/	
	
	function all( params, callback ){
		var promises = [];
		
		params.forEach(function(param){
			var { endpoint, query } = param;
			var promise = new Promise(function(resolve,reject){
				_ret[endpoint](query,function(error,data){   
					if (error) reject(error);
					resolve(data);
				});
			});
			promises.push(promise);		
		});

	   return Promise.all( 
		  promises.map( promise => Promise.resolve( promise ).catch( error => console.log('error',error) ) )
		).then ( function ( data ) {
			var ret = {};
			var error = null;
			function format(d, index ){ 
				var endpoint = params[index].endpoint;
				
				try {
					ret[endpoint] = JSON.parse(d);
				} catch(error){
					console.log(`All Error: ${error}`);
					ret[endpoint] = {};
				}
				
			};
			data.forEach(format);
			if (callback) callback(error,ret);
			return ret;
		} ).catch(function(error){
			  //throw new Error('all:''Data not available ')
			  console.log(`ALL error2: ${error}`);
		});
	}
	
	
	/* ---- */
    _ret = {
        feedinfo : makeApiMethod(feedinfo),
        agencies : makeApiMethod(agencies),
        stops : makeApiMethod(stops),
        routes : makeApiMethod(routes),
        realtime : makeApiMethod(realtime),
        alerts: makeApiMethod(alerts),
		all : all
    }
    

    //_dataMonitor.config.onDataUpdated(callback1);
    //dataMonitor.config.onDataUpdated(callback2);
    if (_monitor){
        _dataUpdateMonitor.config.inject(_ret.feedinfo);
        _dataUpdateMonitor.start();
    }

    
	return _ret;
}

export * from "./internals/cli.mjs";
export var loggerManager = loggerFactory.loggerManager;