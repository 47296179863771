import {main1ViewProvider} from "../view/main1.view.mjs";  // TEST 


export function main1View4Config(staticConfig, alertId, agencyIds ){
    
    var hrefConfig = staticConfig.getHrefConfig();
    var options = staticConfig.getOptions();
    var alertSorterType = options.getAlertSorterType();
    
    var mainView = main1ViewProvider(hrefConfig, options);
    mainView.setNoBlockHeader();
    
    var alertListService = mainView.getAlertListView().getAlertListService();
    alertListService.setAlertMacroName('displayAlertStyle3');
    alertListService.setRouteMacroName('displayRoutes2');
    alertListService.setHrefConfig(hrefConfig);
    //alertListService.setAgencyIds(agencyIds);
    alertListService.setAlertSorterType(alertSorterType);
    // alertListService.setRouteIds(['70'])

    return mainView;
}