/**  
api.config 
*/

export default {
	protocol : 'https:/',
	prod : {
		domain : 'app.mecatran.com',
		path : 'utw/ws',
	},
	preprod : {
		domain : 'app.mecatran.com',
		path : 'utw-test/ws',
	},
	dev : {
		domain : 'localhost:8888',
		path : 'utw-dev/ws'
	}
}