var nunjucks = require("nunjucks/browser/nunjucks-slim.js");

function formatString(str){
    var html = str.replaceAll('\r\n','<br>');
    return html;
	
}

function printString(str){
    return new nunjucks.runtime.SafeString(str);
}

function filterObjectsByKV(objects,key,value){
    function filter(object){
        if (!value) return true;
        return (object[key] === value)
    }
    return objects.filter(filter);
}

function replaceUrl(text) {
    var urlRegex = /(https?:\/\/[^\s]+)[^\.]/g;
    return text.replace(urlRegex, function(url) {
      return '<a href="' + url + '">' + url + '</a>';
    })
}

module.exports = { formatString, printString, filterObjectsByKV, replaceUrl }