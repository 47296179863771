import {urbiplanApiModel} from "@mecatran/urbiplan-services-lib";
import {filterObjectsByKV} from "../utils";

console.log('urbiplanApiModel',urbiplanApiModel)


export function alertListServiceProvider(){
    class AlertListService{
        constructor(){
            var _alerts = [];
            var _alertListFilterProperties = new FilterParam();
            var _alertMacroName = 'displayAlertStyle2';
            var _routeMacroName = 'displayRoutes1';
            var _hrefConfig;
            var _agencyIds = [];
            var _routeIds = [];
            var _alertSorter = urbiplanApiModel.UrbiplanApiActiveAlert.sortByLastPublicationDate;
            var _alertSorterType;

            Object.defineProperty(this,'alerts',{
                set : function(alerts){ _alerts = alerts },
                get : function(){ return _alerts }
            });

            Object.defineProperty(this,'setFilterProperties',{
                value : function(key,value){
                    _alertListFilterProperties.setKey(key);
                    _alertListFilterProperties.setValue(value);
                }
            });

            Object.defineProperty(this,'getFilterProperties',{
                value : function(){ return _alertListFilterProperties }
            });

            Object.defineProperty(this,'alertMacroName',{
                set : function(name){ _alertMacroName = name },
                get : function(){ return _alertMacroName }
            });

            Object.defineProperty(this,'routeMacroName',{
                set : function(name){ _routeMacroName = name },
                get : function(){ return _routeMacroName }
            })


            Object.defineProperty(this,'hrefConfig',{
                set : function(h){ _hrefConfig = h },
                get : function(){ return _hrefConfig }
            });

/*
            Object.defineProperty(this,'agencyIds',{
                set : function(ids){ _agencyIds = ids },
                get : function(){ return _agencyIds }
            });
*/

            Object.defineProperty(this,'routeIds',{
                set : function(ids){ _routeIds = ids },
                get : function(){ return _routeIds }
            });

            Object.defineProperty(this,'alertSorter',{
                get : function(){ return _alertSorter}
            })

            Object.defineProperty(this,'alertSorterType',{
                set : function(alertSorterType){ 
                    _alertSorter = alertSorterType.getClass();
                }
            })
        

        }

        setAlertSorterType(a){ this.alertSorterType = a }

        getAlertSorter(s){ return this.alertSorter }

        getAlertTitleUrl(alert){ 
            return this.hrefConfig.computeAlertTitleUrl(location, alert) 
        }

        getAlerts(){
            var alerts = this.alerts;

            // filterAlertsByKV 
            var filterParams = this.getFilterProperties();
            alerts = filterObjectsByKV(alerts, filterParams.getKey(), filterParams.getValue());
            

            // filterAlertsByAgencyIds 
            /*
            var agencyIds = this.getAgencyIds();
            var filterAlertsByAgencyIds = urbiplanApiModel.UrbiplanApiActiveAlert.filterByAgencyIds;
            alerts = filterAlertsByAgencyIds(alerts, agencyIds);
            */

            // filterAlertsByRouteIds (only keep alerts that have a route in routeIds)
            var routeIds = this.getRouteIds();
            var filterAlertsByRouteIds = urbiplanApiModel.UrbiplanApiActiveAlert.filterByRouteIds;
            alerts = filterAlertsByRouteIds(alerts, routeIds);

            // sortAlerts
            var alertSorter = this.getAlertSorter();
            alerts = alertSorter(alerts, false);

            // sortAlerts by PublicationDate 
            //var sortAlertsByLastPublicationDate = urbiplanApiModel.UrbiplanApiActiveAlert.sortByLastPublicationDate;
            //alerts = sortAlertsByLastPublicationDate(alerts, false);

            console.log('filters',filterParams, routeIds);

            return alerts; 
        }

        
        getRoutesForAlert(alert){
            var routes = alert.getRoutes();

            // filterRoutesByAgencyIds
            /*
            var agencyIds = this.getAgencyIds();
            var filterRoutesByAgencyIds = urbiplanApiModel.UrbiplanApiRoute.filterByAgencyIds;
            routes = filterRoutesByAgencyIds(routes, agencyIds);
            */

            return routes;
        }
        

        /* set */
        setAlerts(alerts){ 
            this.alerts = alerts;
        }

        getAlertMacroName(){ return this.alertMacroName; }

        setAlertMacroName(name){
            this.alertMacroName = name; 
        }

        getRouteMacroName(){ return this.routeMacroName }

        setRouteMacroName(name){
            this.routeMacroName = name;
        }

        getHrefConfig(){ return this.hrefConfig }

        setHrefConfig(h){ this.hrefConfig = h  }
/*
        setAgencyIds(ids){ this.agencyIds = ids }

        getAgencyIds(){ return this.agencyIds }
*/

        setRouteIds(ids){ this.routeIds = ids }

        getRouteIds(){ return this.routeIds }

        getMessageNoAlert(){ return "Aucune alerte en cours ou prévue sur le réseau" }  // TMP translate

        getMessageLink(){ return "Pour plus d'informations, cliquez ici"} // TMP Translate

        getLevelIconName(level){
            switch(level){
                case 1:
                    return 'info';
                    break;
                case 2:
                    //return ;
                     return 'warning';
                    break;
                case 3:
                    return 'critical';
                    break;
            }
        }
        
        
    }

    return new AlertListService();

}



class FilterParam{
    constructor(key, value){
        var _key = key;
        var _value = value;

        Object.defineProperty(this,'key',{
            set : function(k){ _key = k },
            get : function(){ return _key }
        }) 

        Object.defineProperty(this,'value',{
            set : function(v){ _value = v },
            get : function(){ return _value }
        })
    }
    getKey(){ return this.key }
    getValue(){ return this.value }

    setKey(k){ this.key = k }
    setValue(v){ this.value = v }
}